<div class="globalcontainer">
    <div class="sky-gradient sky-gradient-{{backgroundColor}}">
        <div class='stars' [ngStyle]="{'opacity': !isBackgroundColorInRange() ? '0.5' : '1'}"></div>
    </div>
    <div class="sky-gradient-over"></div>

    <section class="vh-30"> <!--#C1CFEA;  0D0C1D  noche -->
        <!-- Cabecera con la imagen -->
        <div class="row">
            <div class="col-xl-12">
                <div class="container py-5 text-center">
                    <img src="assets/logo-muni10.png" alt="Logo" style="width: 70%; height: auto;">
                </div>
            </div>
        </div>

        <!-- Resto de tu contenido -->
        <div class="row">
            <!-- ...tu contenido actual... -->
        </div>
    </section>
    <section class="vh-70">
        <div class="row">
            <div *ngIf="cmm" class="col-xl-4"> <!-- -->
                <div class="container py-5 h-100">

                    <div class="row d-flex justify-content-center align-items-center h-100" style="color: #282828;">
                        <div class="col-xl-11 bgc fullh" *ngIf="cmmLoading" style="min-height: 55vh;">
                            <mat-progress-bar mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                        <div class="col-xl-11 bgc fullh fullh" *ngIf="!cmmLoading">

                            <div class="card mb-4 gradient-custom" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo1" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <h2 class="display-2"><strong>{{cmmName}}</strong></h2>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">calendar_today</mat-icon>
                                                                {{getDia(cmmLastData.formattedDateTime)}}</strong></span>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">access_time</mat-icon>
                                                                {{getHora(cmmLastData.formattedDateTime)}}</strong></span>
                                                        <br>
                                                        <button mat-button (click)="isOpenCmmData(0)" class="mt-5 pt-1">
                                                            <i class="fa-solid fa-clock-rotate-left fa-2x text-muted historial-i"
                                                                style="color: #ddd;"></i></button>
                                                    </div>
                                                    <div>
                                                        <div class="ml-4">
                                                            <div class="mat-elevation-z2" style="border-radius: 20px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="120"
                                                                    height="120"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    version="1.1" x="0px" y="0px" viewBox="0 0 512 512"
                                                                    enable-background="new 0 0 512 512"
                                                                    xml:space="preserve">
                                                                    <g id="Ebene_3" display="none">
                                                                        <rect x="-2020" y="-2565" display="inline"
                                                                            fill="#4D4D4E" width="5364" height="3336" />
                                                                    </g>
                                                                    <g id="Grid" display="none">
                                                                        <g display="inline">
                                                                            <g>
                                                                                <path fill="#B3B3B3"
                                                                                    d="M511,1v510H1V1H511 M512,0H0v512h512V0L512,0z" />
                                                                            </g>
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="6" y1="6"
                                                                                x2="506" y2="506" />
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="506" y1="6"
                                                                                x2="6" y2="506" />
                                                                            <circle fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" cx="256" cy="256"
                                                                                r="250" />
                                                                        </g>
                                                                    </g>
                                                                    <g id="Ebene_2">
                                                                        <circle fill="#84abc1" cx="353.935" cy="183.958"
                                                                            r="21.065" />
                                                                        <circle fill="#84abc1" cx="428.935" cy="183.958"
                                                                            r="21.065" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="87.37" x2="255.997" y2="471.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="256.003"
                                                                            y1="331.37" x2="316.874" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="316.874"
                                                                            y1="236.37" x2="390.997" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="390.997"
                                                                            y1="236.37" x2="390.997" y2="198.037" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="361.371" x2="165.871" y2="400.372" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="165.871"
                                                                            y1="400.372" x2="165.871" y2="354.371" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="206.871" y2="324.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="206.871"
                                                                            y1="324.368" x2="206.871" y2="298.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="124.871" y2="298.368" />

                                                                        <polygon fill="none" stroke="#7fed6f"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="   184.997,165.621 154.997,182.364 126.692,165.621 126.692,86.624 184.997,86.624  " />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="202.302"
                                                                            y1="118.364" x2="255.997" y2="172.059" />
                                                                    </g>
                                                                    <g id="txt">
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <br>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card mb-4" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo2" class="">
                                        <!-- Indicators -->
                                        <div>
                                            <div>
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmmLastData.outTemp}}°C</strong></p>
                                                        <svg style="margin-right: 20px" height="60px"
                                                            viewBox="-42 0 512 512" width="60px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m84.144531 38.53125c4.394531 19.445312 27.988281 27.109375 42.96875 13.960938 2.53125-2.222657 6.324219.53125 4.992188 3.625-7.878907 18.3125 6.703125 38.382812 26.554687 36.550781 3.355469-.308594 4.804688 4.144531 1.90625 5.867187-17.136718 10.1875-17.136718 34.996094 0 45.179688 2.898438 1.722656 1.449219 6.175781-1.90625 5.867187-19.851562-1.832031-34.433594 18.238281-26.554687 36.550781 1.332031 3.097657-2.460938 5.847657-4.992188 3.625-14.980469-13.148437-38.578125-5.480468-42.96875 13.960938-.742187 3.289062-5.425781 3.289062-6.167969 0-4.390624-19.445312-27.984374-27.109375-42.96875-13.960938-2.53125 2.222657-6.324218-.527343-4.992187-3.625 7.878906-18.3125-6.703125-38.382812-26.554687-36.550781-3.355469.308594-4.804688-4.144531-1.90625-5.867187 17.136718-10.183594 17.136718-34.992188 0-45.179688-2.894532-1.722656-1.449219-6.175781 1.90625-5.867187 19.851562 1.832031 34.433593-18.238281 26.554687-36.550781-1.332031-3.09375 2.460937-5.847657 4.992187-3.625 14.984376 13.148437 38.578126 5.484374 42.96875-13.960938.742188-3.289062 5.425782-3.289062 6.167969 0zm0 0"
                                                                fill="#fd8f31"></path>
                                                            <path
                                                                d="m160.566406 143.714844c-17.136718-10.183594-17.136718-34.992188 0-45.179688 2.898438-1.71875 1.449219-6.175781-1.90625-5.863281-19.847656 1.832031-34.429687-18.242187-26.554687-36.554687 1.332031-3.09375-2.457031-5.847657-4.992188-3.625-14.980469 13.152343-38.574219 5.484374-42.96875-13.960938-.742187-3.289062-5.425781-3.289062-6.167969 0-1.625 7.191406-5.875 12.761719-11.34375 16.277344 19.398438 15.589844 31.824219 39.496094 31.824219 66.320312 0 26.820313-12.425781 50.730469-31.824219 66.320313 5.46875 3.511719 9.71875 9.085937 11.34375 16.273437.742188 3.289063 5.429688 3.289063 6.167969 0 4.394531-19.445312 27.988281-27.109375 42.96875-13.960937 2.535157 2.222656 6.324219-.527344 4.992188-3.625-7.875-18.3125 6.707031-38.382813 26.554687-36.550781 3.355469.308593 4.804688-4.148438 1.90625-5.871094zm0 0"
                                                                fill="#ff7f1f"></path>
                                                            <path
                                                                d="m115.933594 121.125c0 19.261719-15.613282 34.871094-34.875 34.871094-19.257813 0-34.871094-15.609375-34.871094-34.871094 0-19.257812 15.613281-34.871094 34.871094-34.871094 19.261718 0 34.875 15.613282 34.875 34.871094zm0 0"
                                                                fill="#fccf3f"></path>
                                                            <path
                                                                d="m334.011719 315.355469v-253.195313c0-34.332031-27.832031-62.160156-62.160157-62.160156-34.332031 0-62.160156 27.828125-62.160156 62.160156v253.195313c-27.8125 19.566406-45.984375 51.910156-45.984375 88.5 0 59.726562 48.417969 108.144531 108.144531 108.144531 59.726563 0 108.144532-48.417969 108.144532-108.144531 0-36.589844-18.175782-68.933594-45.984375-88.5zm0 0"
                                                                fill="#d8d8d8"></path>
                                                            <path
                                                                d="m271.851562 476.582031c-41.183593 0-74.6875-33.507812-74.6875-74.6875 0-31.273437 19.433594-59.117187 48.59375-69.980469v-269.753906c0-14.390625 11.703126-26.09375 26.09375-26.09375 14.386719 0 26.09375 11.703125 26.09375 26.09375v269.753906c29.15625 10.859376 48.59375 38.703126 48.59375 69.976563 0 41.183594-33.503906 74.691406-74.6875 74.691406zm0 0"
                                                                fill="#f3e8d7"></path>
                                                            <path
                                                                d="m245.757812 109.871094v222.042968c-29.160156 10.859376-48.59375 38.703126-48.59375 69.976563 0 41.183594 33.503907 74.6875 74.6875 74.6875 41.183594 0 74.6875-33.503906 74.6875-74.6875 0-31.273437-19.4375-59.117187-48.59375-69.976563v-222.042968zm0 0"
                                                                fill="#fc4e51"></path>
                                                            <g fill="#0d6e9a">
                                                                <path
                                                                    d="m420.105469 180.21875h-44.824219c-4.269531 0-7.726562-3.460938-7.726562-7.726562 0-4.265626 3.457031-7.726563 7.726562-7.726563h44.824219c4.265625 0 7.726562 3.460937 7.726562 7.726563 0 4.265624-3.460937 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 117.597656h-20.351562c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726563 7.726562-7.726563h20.351562c4.265626 0 7.726563 3.460938 7.726563 7.726563s-3.460937 7.726562-7.726563 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 54.976562h-27.5625c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726562 7.726562-7.726562h27.5625c4.269531 0 7.726562 3.460937 7.726562 7.726562s-3.457031 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 305.460938h-27.5625c-4.269531 0-7.726562-3.460938-7.726562-7.726563 0-4.269531 3.457031-7.726563 7.726562-7.726563h27.5625c4.269531 0 7.726562 3.457032 7.726562 7.726563 0 4.265625-3.457031 7.726563-7.726562 7.726563zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 242.839844h-20.351562c-4.269531 0-7.726562-3.460938-7.726562-7.726563s3.457031-7.726562 7.726562-7.726562h20.351562c4.265626 0 7.726563 3.460937 7.726563 7.726562s-3.460937 7.726563-7.726563 7.726563zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m297.945312 331.914062v-222.042968h-52.1875v222.042968s36.074219 16.738282 36.074219 69.976563c0 29.601563-17.308593 55.234375-42.335937 67.3125 9.792968 4.726563 20.769531 7.375 32.351562 7.375 41.183594 0 74.691406-33.503906 74.691406-74.6875 0-31.273437-19.4375-59.113281-48.59375-69.976563zm0 0"
                                                                fill="#e5394b"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Temperatura</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmmLastData.outHumidity}}%</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-38 0 420 420.192" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m344.425781 175.695312c.210938 31.570313-16.507812 60.835938-43.816406 76.679688-27.304687 15.847656-61.007813 15.847656-88.3125 0-27.304687-15.84375-44.027344-45.109375-43.8125-76.679688 0-48.582031 87.972656-175.6874995 87.972656-175.6874995s87.96875 127.0976565 87.96875 175.6874995zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m256.453125 0s-5.3125 7.695312-13.3125 19.960938c24.585937 37.734374 74.660156 119.066406 74.660156 155.734374-.011719 43.441407-31.722656 80.375-74.660156 86.960938 25.355469 3.859375 51.136719-3.527344 70.597656-20.238281 19.460938-16.707031 30.664063-41.074219 30.6875-66.722657 0-48.589843-87.972656-175.695312-87.972656-175.695312zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m214.261719 66.601562c-22.570313 38.511719-45.761719 84.136719-45.761719 109.085938.003906 32.640625 18.074219 62.59375 46.949219 77.8125s63.800781 13.199219 90.726562-5.246094c-15.175781-52.742187-58.601562-128.222656-91.914062-181.652344zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m279.007812 280.734375c0 77.019531-62.4375 139.457031-139.457031 139.457031s-139.4531248-62.4375-139.4531248-139.457031 139.4531248-278.511719 139.4531248-278.511719 139.457031 201.472656 139.457031 278.511719zm0 0"
                                                                fill="#26a3b9"></path>
                                                            <g fill="#168aa5">
                                                                <path
                                                                    d="m227.484375 187.15625 19.304687 19.308594-156.308593 156.308594-19.308594-19.308594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m126.574219 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m229.730469 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m139.550781 2.222656s-7.769531 11.230469-19.636719 29.390625c38.402344 58.796875 119.820313 190.277344 119.820313 249.121094-.007813 69.425781-51.082031 128.273437-119.8125 138.054687 40.011719 5.71875 80.535156-6.214843 111.0625-32.703124 30.527344-26.492188 48.050781-64.929688 48.023437-105.351563 0-77.039063-139.457031-278.511719-139.457031-278.511719zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m208.058594 187.15625 19.304687 19.304688-156.308593 156.3125-19.308594-19.308594zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m107.148438 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m210.304688 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Humedad</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmmLastData.pressure}}hPa</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 0 512 512" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m256 512c-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.636719-74.980469-181.019531s26.628906-132.667969 74.980469-181.019531c48.351562-48.351563 112.640625-74.980469 181.019531-74.980469s132.667969 26.628906 181.019531 74.980469c48.351563 48.351562 74.980469 112.636719 74.980469 181.019531s-26.628906 132.667969-74.980469 181.019531c-48.351562 48.351563-112.640625 74.980469-181.019531 74.980469zm0-478.609375c-122.746094 0-222.609375 99.863281-222.609375 222.609375s99.863281 222.609375 222.609375 222.609375 222.609375-99.863281 222.609375-222.609375-99.863281-222.609375-222.609375-222.609375zm0 0"
                                                                fill="#736056"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-36.414063-36.414063-84.097656-54.839844-131.929687-55.308594-.613282-.070312-1.234376-.113281-1.867188-.113281s-1.253906.042969-1.867188.113281c-47.828124.46875-95.507812 18.894531-131.921874 55.300781-.003907.003907-.003907.003907-.007813.007813s-.003906.003906-.007813.007813c-36.410156 36.414062-54.832031 84.09375-55.300781 131.921874-.070312.613282-.113281 1.234376-.113281 1.867188s.042969 1.253906.113281 1.867188c.46875 47.832031 18.894531 95.515624 55.308594 131.929687 3.261719 3.261719 7.53125 4.890625 11.804687 4.890625 4.273438 0 8.546876-1.628906 11.804688-4.890625l23.613281-23.609375c6.519531-6.519531 6.519531-17.089844 0-23.613281-6.523437-6.519531-17.09375-6.519531-23.613281 0l-11.175781 11.175781c-19.242188-23.847656-30.410157-52.097656-33.503907-81.054688h15.738282c9.21875 0 16.695312-7.476562 16.695312-16.695312s-7.476562-16.695312-16.695312-16.695312h-15.726563c3.09375-28.953126 14.261719-57.199219 33.5-81.050782l11.171875 11.171875c3.257813 3.257813 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.546875-1.632812 11.804687-4.890625 6.519531-6.519531 6.519531-17.09375 0-23.613281l-11.171875-11.171875c23.851563-19.238281 52.097656-30.402344 81.050782-33.492187v15.722656c0 9.21875 7.476562 16.695312 16.695312 16.695312 9.222656 0 16.695312-7.476562 16.695312-16.695312v-15.722656c34.171876 3.648437 67.355469 18.53125 93.492188 44.664062 6.519531 6.519531 17.089844 6.519531 23.609375 0s6.519531-17.089844 0-23.609375zm0 0"
                                                                fill="#88cc2a"></path>
                                                            <path
                                                                d="m445.21875 256c0-.632812-.042969-1.253906-.113281-1.867188-.46875-47.832031-18.894531-95.515624-55.308594-131.929687-6.519531-6.519531-17.089844-6.519531-23.609375 0-6.523438 6.519531-6.523438 17.089844 0 23.609375 26.132812 26.136719 41.015625 59.324219 44.667969 93.492188h-15.726563c-9.21875 0-16.695312 7.476562-16.695312 16.695312s7.476562 16.695312 16.695312 16.695312h15.734375c-3.09375 28.957032-14.257812 57.207032-33.5 81.054688l-11.175781-11.175781c-6.519531-6.519531-17.09375-6.519531-23.613281 0s-6.519531 17.089843 0 23.613281l23.613281 23.609375c3.257812 3.261719 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.542968-1.628906 11.804687-4.890625 36.414063-36.414063 54.839844-84.097656 55.308594-131.929687.070312-.613282.113281-1.234376.113281-1.867188zm0 0"
                                                                fill="#ff9f19"></path>
                                                            <path
                                                                d="m272.695312 445.21875h-33.390624c-27.617188 0-50.085938-22.46875-50.085938-50.089844 0-27.617187 22.46875-50.085937 50.085938-50.085937h33.390624c27.617188 0 50.085938 22.46875 50.085938 50.085937 0 27.621094-22.46875 50.089844-50.085938 50.089844zm-33.390624-66.785156c-9.207032 0-16.695313 7.492187-16.695313 16.695312 0 9.207032 7.488281 16.695313 16.695313 16.695313h33.390624c9.207032 0 16.695313-7.488281 16.695313-16.695313 0-9.203125-7.488281-16.695312-16.695313-16.695312zm0 0"
                                                                fill="#bddeff"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-6.519531-6.519531-17.089844-6.519531-23.609375 0l-84.46875 84.46875c-7.699219-4.03125-16.445312-6.324219-25.71875-6.324219-30.6875 0-55.652344 24.964844-55.652344 55.652344s24.964844 55.652344 55.652344 55.652344 55.652344-24.964844 55.652344-55.652344c0-9.273438-2.292969-18.019531-6.324219-25.71875l84.46875-84.46875c6.519531-6.519531 6.519531-17.089844 0-23.609375zm-133.796875 156.058594c-12.273438 0-22.261719-9.988281-22.261719-22.261719s9.988281-22.261719 22.261719-22.261719 22.261719 9.988281 22.261719 22.261719-9.988281 22.261719-22.261719 22.261719zm0 0"
                                                                fill="#e6563a"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Presión</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmmLastData.rain}}mm</strong></p>
                                                        <svg height="45.541px" viewBox="0 -7 512 512" width="47.542px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m21.316406 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.0117188 1.125.03125 1.683594 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578124-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m21.316406 457.257812c-10.378906 0-20.753906-6.703124-21.2734372-20.097656-.0234376-.5625-.0429688-1.125-.0429688-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.570312 7.371094-4.292968 21.140625-4.292968 36.914062 0 .558594 0 1.121094.007812 1.683594.105469 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 438.421875c-12.742187 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574218-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 329.023438c-12.742187 7.371093-21.316406 21.136718-21.316406 36.914062 0 .5625.011719 1.125.03125 1.679688 1.039062 26.800781 41.527344 26.800781 42.566406 0 .023438-.554688.03125-1.117188.03125-1.679688.003906-15.777344-8.574218-29.542969-21.3125-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 387.71875c-10.378906 0-20.757812-6.707031-21.277344-20.101562-.019531-.558594-.039062-1.121094-.039062-1.683594 0-15.773438 8.578125-29.539063 21.316406-36.914063-2.570312 7.375-4.296875 21.140625-4.296875 36.914063 0 .5625 0 1.125.011719 1.683594.105469 13.394531 2.195312 20.101562 4.285156 20.101562zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m208.1875 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m208.1875 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m301.625 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.007812 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574219-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m301.625 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105468 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m489.035156 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.035156-1.117187.035156-1.683593 0-15.777344-8.578124-29.542969-21.316406-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m489.035156 497.117188c-10.378906 0-20.753906-6.703126-21.273437-20.097657-.023438-.5625-.042969-1.125-.042969-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m395.058594 398.5625c-12.738282 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527343 26.796875 42.566406 0 .023437-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m395.058594 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578124-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m408.25 181.570312c0 37.824219-30.664062 68.492188-68.503906 68.492188h-247.886719c-50.296875 0-91.082031-40.773438-91.082031-91.082031 0-50.296875 40.78125-91.082031 91.082031-91.082031 4.511719 0 8.945313.347656 13.292969.976562 20.570312-40.84375 62.871094-68.875 111.742187-68.875 59.128907 0 108.667969 41.054688 121.679688 96.210938 1.300781 5.503906 2.238281 11.144531 2.777343 16.894531v.011719c37.082032.851562 66.898438 31.167968 66.898438 68.453124zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m260.351562 7.769531c-13.539062-5.027343-28.183593-7.76562475-43.457031-7.76562475-48.871093 0-91.160156 28.03124975-111.742187 68.86328175v.011718c-4.347656-.628906-8.78125-.976562-13.292969-.976562-50.3125 0-91.082031 40.785156-91.082031 91.082031 0 50.3125 40.769531 91.082031 91.082031 91.082031h44.019531c-45.332031 0-82.066406-36.746094-82.066406-82.070312 0-40.214844 28.929688-73.683594 67.128906-80.703125 3.777344-.707031 7.648438-1.144531 11.597656-1.300781 1.105469-.035157 2.222657-.0625 3.339844-.0625 14.734375 0 28.570313 3.882812 40.515625 10.699218-1.660156-11.5625-13.613281-22.394531-31.238281-31.125 9.679688-32.726562 42.613281-63.078125 115.195312-57.734375zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m264.6875 245.550781c.496094.089844.789062.089844.835938.089844h-3.792969c.992187 0 1.972656-.03125 2.957031-.089844zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m512 313.621094c0 30.589844-24.800781 55.390625-55.402344 55.390625h-200.476562c-40.675782 0-73.660156-32.972657-73.660156-73.660157 0-40.679687 32.984374-73.660156 73.660156-73.660156 3.648437 0 7.238281.28125 10.75.789063 16.640625-33.035157 50.847656-55.703125 90.371094-55.703125 47.824218 0 87.886718 33.203125 98.410156 77.808594 1.050781 4.453124 1.808594 9.015624 2.246094 13.664062v.011719c29.988281.683593 54.101562 25.203125 54.101562 55.359375zm0 0"
                                                                fill="#d3ddea"></path>
                                                            <path
                                                                d="m392.386719 173.0625c-10.949219-4.066406-22.792969-6.28125-35.144531-6.28125-39.523438 0-73.722657 22.667969-90.371094 55.691406v.011719c-3.515625-.511719-7.101563-.789063-10.75-.789063-40.6875 0-73.660156 32.980469-73.660156 73.660157 0 40.6875 32.972656 73.660156 73.660156 73.660156h35.601562c-36.664062 0-66.371094-29.71875-66.371094-66.371094 0-32.527343 23.394532-59.59375 54.289063-65.269531 3.058594-.574219 6.1875-.925781 9.378906-1.050781.894531-.03125 1.800781-.050781 2.703125-.050781 11.917969 0 23.105469 3.140624 32.765625 8.648437-1.339843-9.351563-11.007812-18.109375-25.261719-25.167969 7.824219-26.46875 34.460938-51.015625 93.160157-46.691406zm0 0"
                                                                fill="#84abc1"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Lluvia</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo3" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmmLastData.dewpoint}}°C</strong>
                                                        </p>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                                            id="Layer_1" x="0px" y="0px" viewBox="0 0 497 497"
                                                            style="enable-background:new 0 0 497 497;"
                                                            xml:space="preserve" width="55px" height="55px">
                                                            <g>
                                                                <path style="fill:#59C2E8;"
                                                                    d="M183.391,58.325c-6.66-6.477-17.246-6.477-23.907,0C-78.787,290.047-14.684,497,171.438,497   S421.663,290.047,183.391,58.325z">
                                                                </path>
                                                                <path style="fill:#0097D1;"
                                                                    d="M201.438,477C19.441,477-45.878,279.117,174.095,53.691c-5.15-0.806-10.593,0.727-14.611,4.634   C-78.787,290.047-14.684,497,171.438,497c48.559,0,88.805-14.094,117.55-39.032C264.453,470.302,234.92,477,201.438,477z">
                                                                </path>
                                                                <g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M99.951,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5s7.5,3.358,7.5,7.5     v12.938C107.451,283.894,104.094,287.251,99.951,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M242.925,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5     s7.5,3.358,7.5,7.5v12.938C250.425,283.894,247.067,287.251,242.925,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M171.438,294.157c-9.041,0-17.53-3.78-23.291-10.371c-2.727-3.119-2.408-7.857,0.711-10.583     c3.117-2.726,7.856-2.408,10.582,0.711c2.912,3.332,7.285,5.242,11.998,5.242c4.712,0,9.085-1.911,11.997-5.242     c2.726-3.119,7.464-3.437,10.583-0.711c3.118,2.726,3.437,7.464,0.71,10.583C188.968,290.378,180.478,294.157,171.438,294.157z">
                                                                        </path>
                                                                    </g>
                                                                </g>
                                                                <path style="fill:#D5EBF6;"
                                                                    d="M420.006,262.727V50.657C420.006,22.68,397.326,0,369.35,0h0c-25.428,0-46.475,18.737-50.1,43.157   l4.08,7.5l-4.637,7.5V79.17l3.97,7.5l-3.97,7.5v21.014l4.637,7.5l-4.637,7.5v21.014l3.637,7.5l-3.637,7.5v21.013l3.637,7.5   l-3.637,7.5v60.516c-42.433,19.279-71.942,62.03-71.942,111.674C246.751,442.111,301.64,497,369.35,497   s122.599-54.889,122.599-122.599C491.948,324.757,462.439,282.006,420.006,262.727z">
                                                                </path>
                                                                <path style="fill:#FF6E80;"
                                                                    d="M369.349,462.349c-48.494,0-87.947-39.453-87.947-87.947c0-34.427,20.264-65.879,51.625-80.127   l20.318-9.23V50.656c0-8.825,7.18-16.005,16.005-16.005c8.825,0,16.006,7.18,16.006,16.005v234.388l20.318,9.231   c31.361,14.248,51.625,45.7,51.625,80.127C457.297,422.896,417.844,462.349,369.349,462.349z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,187.211v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H318.693   L318.693,187.211z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,151.198v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,151.198z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,115.184v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,115.184z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,50.657v7.5h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-34.095   C318.886,45.604,318.693,48.107,318.693,50.657z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,79.17v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,79.17z">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Punto de<br> rocío</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmmLastData.heatindex}}°C</strong>
                                                        </p>
                                                        <svg style="margin-right: 20px" height="70px"
                                                            viewBox="-78 0 512 512" width="75px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-13.621094-15.976562-21.824218-36.6875-21.824218-59.308593 0-50.550782 40.976562-91.527344 91.527343-91.527344 46.082031 0 84.203125 34.046875 90.585938 78.363281.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fab03c"></path>
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-.621094-4.300781-.9375-8.691406-.9375-13.160156 0-50.554687 40.984375-91.527344 91.527344-91.527344 27.925781 0 52.925781 12.503907 69.699219 32.214844.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <g fill="#f8e98e">
                                                                <path
                                                                    d="m150.769531 34.9375c-4.207031 0-7.613281-3.410156-7.613281-7.613281v-19.714844c0-4.203125 3.410156-7.609375 7.613281-7.609375s7.609375 3.40625 7.609375 7.609375v19.714844c0 4.203125-3.40625 7.613281-7.609375 7.613281zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m89.054688 51.476562c-2.632813 0-5.191407-1.367187-6.601563-3.808593l-9.855469-17.070313c-2.101562-3.640625-.855468-8.296875 2.785156-10.398437 3.640626-2.101563 8.296876-.855469 10.398438 2.785156l9.855469 17.074219c2.101562 3.636718.855469 8.292968-2.785157 10.394531-1.199218.691406-2.507812 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m43.855469 96.660156c-1.289063 0-2.597657-.332031-3.796875-1.023437l-17.074219-9.855469c-3.640625-2.101562-4.886719-6.757812-2.785156-10.398438 2.101562-3.640624 6.753906-4.886718 10.394531-2.785156l17.074219 9.855469c3.640625 2.105469 4.886719 6.757813 2.785156 10.398437-1.410156 2.445313-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m27.324219 158.378906h-19.714844c-4.203125 0-7.609375-3.40625-7.609375-7.609375s3.40625-7.613281 7.609375-7.613281h19.714844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m257.679688 96.660156c-2.628907 0-5.1875-1.367187-6.597657-3.808594-2.101562-3.640624-.855469-8.292968 2.785157-10.398437l17.074218-9.855469c3.636719-2.101562 8.292969-.855468 10.394532 2.785156 2.101562 3.640626.855468 8.296876-2.785157 10.398438l-17.074219 9.855469c-1.195312.691406-2.503906 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m26.796875 229.960938c-2.628906 0-5.1875-1.367188-6.597656-3.808594-2.101563-3.640625-.855469-8.296875 2.785156-10.394532l17.074219-9.859374c3.636718-2.097657 8.292968-.851563 10.394531 2.789062s.855469 8.292969-2.785156 10.394531l-17.074219 9.855469c-1.195312.695312-2.503906 1.023438-3.796875 1.023438zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m212.484375 51.476562c-1.292969 0-2.601563-.332031-3.800781-1.023437-3.640625-2.101563-4.886719-6.757813-2.785156-10.394531l9.855468-17.074219c2.101563-3.640625 6.757813-4.886719 10.398438-2.785156 3.640625 2.101562 4.886718 6.757812 2.785156 10.398437l-9.855469 17.070313c-1.410156 2.441406-3.96875 3.808593-6.597656 3.808593zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m79.183594 282.359375c-1.292969 0-2.601563-.328125-3.800782-1.019531-3.640624-2.105469-4.886718-6.757813-2.785156-10.398438l9.855469-17.074218c2.101563-3.640626 6.757813-4.886719 10.398437-2.785157 3.640626 2.101563 4.886719 6.757813 2.785157 10.398438l-9.855469 17.070312c-1.410156 2.441407-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m329.234375 240.59375c0 10.503906-8.519531 19.019531-19.023437 19.019531-10.503907 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.519531-19.023438 19.023438-19.023438 10.503906 0 19.023437 8.515626 19.023437 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m177.15625 240.59375c0 10.503906-8.515625 19.019531-19.019531 19.019531-10.507813 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.515625-19.023438 19.023438-19.023438 10.503906 0 19.019531 8.515626 19.019531 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m353.683594 439.070312v62.78125c0 5.605469-4.542969 10.148438-10.148438 10.148438h-218.722656c-5.601562 0-10.148438-4.542969-10.148438-10.148438v-62.78125c0-43.292968 23.027344-81.207031 57.492188-102.175781 18.074219-10.988281 39.308594-17.332031 62.019531-17.332031 22.5 0 43.535157 6.21875 61.5 17.03125 8.390625 5.042969 16.125 11.089844 23.007813 17.972656 21.625 21.636719 35 51.511719 35 84.503906zm0 0"
                                                                fill="#f0f5f7"></path>
                                                            <path
                                                                d="m172.503906 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.609375-7.609375 4.203125 0 7.613281 3.398437 7.613281 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m311.171875 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.613281-7.609375 4.199219 0 7.609375 3.398437 7.609375 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m343.539062 511.992188h-47.589843v-67.089844h57.738281v56.945312c0 5.601563-4.542969 10.144532-10.148438 10.144532zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m295.949219 444.90625h15.222656v67.082031h-15.222656zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m172.402344 444.90625v67.082031h-47.589844c-5.601562 0-10.148438-4.542969-10.148438-10.148437v-56.933594zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m157.28125 444.90625h15.121094v67.082031h-15.121094zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m295.679688 336.59375c-13.59375 19.570312-36.234376 32.367188-61.863282 32.367188-25.496094 0-48.042968-12.671876-61.65625-32.066407 18.070313-10.992187 39.304688-17.328125 62.015625-17.328125 22.5 0 43.535157 6.210938 61.503907 17.027344zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m164.230469 174.1875v85.160156c0 38.625 31.316406 69.941406 69.941406 69.941406 38.628906 0 69.941406-31.316406 69.941406-69.941406v-85.160156zm0 0"
                                                                fill="#fbdbb7"></path>
                                                            <path
                                                                d="m304.113281 174.1875v85.15625c0 19.3125-7.828125 36.808594-20.488281 49.460938-12.652344 12.652343-30.140625 20.480468-49.453125 20.480468-3.757813 0-7.445313-.292968-11.050781-.875 14.886718-2.355468 28.207031-9.421875 38.402344-19.605468 12.652343-12.652344 20.480468-30.148438 20.480468-49.460938v-85.15625zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m234.175781 119.171875c-44.792969 0-81.101562 36.308594-81.101562 81.101563v17.472656c0 6.152344 5.417969 10.886718 11.515625 10.054687 20.5-2.789062 62.964844-10.910156 82.292968-31.78125 7.195313 11.8125 23.039063 26.890625 57.433594 29.546875 5.90625.457032 10.957032-4.199218 10.957032-10.121094v-15.171874c0-44.792969-36.308594-81.101563-81.097657-81.101563zm0 0"
                                                                fill="#82807f"></path>
                                                            <path
                                                                d="m315.273438 200.277344v15.164062c0 5.929688-5.066407 10.578125-10.980469 10.121094-4.617188-.359375-8.902344-.941406-12.878907-1.710938v-23.574218c0-40.738282-30.03125-74.460938-69.175781-80.238282 3.898438-.578124 7.875-.871093 11.9375-.871093 44.785157 0 81.097657 36.3125 81.097657 81.109375zm0 0"
                                                                fill="#6d6d6d"></path>
                                                            <path
                                                                d="m201.347656 248.203125c-4.207031 0-7.660156-3.40625-7.660156-7.609375s3.363281-7.613281 7.570312-7.613281h.089844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m267.089844 248.203125c-4.203125 0-7.65625-3.40625-7.65625-7.609375s3.363281-7.613281 7.566406-7.613281h.089844c4.207031 0 7.613281 3.410156 7.613281 7.613281s-3.410156 7.609375-7.613281 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m241.503906 292.371094h-14.65625c-4.207031 0-7.613281-3.40625-7.613281-7.609375v-44.167969c0-4.203125 3.410156-7.613281 7.613281-7.613281s7.609375 3.410156 7.609375 7.613281v36.554688h7.046875c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.410156 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <path
                                                                d="m323.347656 172.90625c0 10.621094-8.609375 19.230469-19.230468 19.230469-10.621094 0-19.230469-8.609375-19.230469-19.230469 0-6.96875 8.28125-20.652344 13.972656-29.230469 2.496094-3.761719 8.015625-3.761719 10.511719 0 5.695312 8.578125 13.976562 22.261719 13.976562 29.230469zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m323.34375 172.90625c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.660156 0-5.199219-.542969-7.507812-1.519531 6.898437-2.921876 11.730469-9.753907 11.730469-17.710938 0-6.09375-6.328126-17.316406-11.722657-25.777344.773438-1.214844 1.527344-2.367187 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.519532-.011719 5.691406 8.582031 13.972656 22.261719 13.972656 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m356.394531 368.648438c0 10.621093-8.613281 19.230468-19.234375 19.230468s-19.230468-8.609375-19.230468-19.230468c0-6.972657 8.285156-20.652344 13.976562-29.234376 2.496094-3.761718 8.015625-3.761718 10.511719 0 5.691406 8.582032 13.976562 22.261719 13.976562 29.234376zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m356.390625 368.648438c0 10.617187-8.605469 19.230468-19.230469 19.230468-2.664062 0-5.199218-.542968-7.507812-1.519531 6.894531-2.921875 11.730468-9.753906 11.730468-17.710937 0-6.09375-6.328124-17.320313-11.722656-25.78125.769532-1.210938 1.523438-2.367188 2.238282-3.441407 2.496093-3.765625 8.019531-3.777343 10.515624-.011719 5.695313 8.582032 13.976563 22.261719 13.976563 29.234376zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.621094-8.609375 19.230469-19.230469 19.230469s-19.230469-8.609375-19.230469-19.230469c0-6.96875 8.28125-20.652344 13.976563-29.234375 2.496093-3.757813 8.015625-3.757813 10.511719 0 5.691406 8.582031 13.972656 22.265625 13.972656 29.234375zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.664063 0-5.199219-.542969-7.507813-1.519531 6.894532-2.921876 11.726563-9.753907 11.726563-17.710938 0-6.09375-6.328125-17.316406-11.71875-25.777344.769531-1.214844 1.523437-2.371094 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.515625-.011719 5.695313 8.582031 13.976563 22.261719 13.976563 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Sensación<br> térmica</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmmLastData.windSpeed}}Km/h</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 -23 511.99037 511" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m238.945312 182.152344h-213.332031c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h213.332031c20.527344.90625 39.605469-10.542969 48.460938-29.082032 9.6875-19.246094 8.308594-42.207031-3.609375-60.160156-9.566406-12.449219-24.515625-17.066406-43.175781-13.320312-14.421875 1.917968-24.910156 14.648437-24.03125 29.167968 1.066406 10.726563 9.738281 22.1875 30.890625 22.1875 2.355469 0 4.265625-1.910156 4.265625-4.269531 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535157-3.820312-8.535157-8.53125 0-4.714844 3.820313-8.535156 8.535157-8.535156 11.78125 0 21.332031 9.550781 21.332031 21.332031 0 11.785156-9.550781 21.335937-21.332031 21.335937-30.5 0-46.019531-18.875-47.871094-37.546874-1.890625-23.285157 14.539063-44.070313 37.632813-47.609376 22.140624-6.042968 45.753906 1.675782 60.054687 19.628907 15.925781 23.097656 17.992187 53.039062 5.386719 78.105469-11.75 24.332031-36.726563 39.472656-63.738282 38.628906zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m269.742188 430.933594c-5.183594-.019532-10.355469-.554688-15.433594-1.597656-23.097656-3.535157-39.527344-24.324219-37.632813-47.613282 1.851563-18.671875 17.375-37.546875 47.871094-37.546875 11.78125 0 21.332031 9.550781 21.332031 21.332031s-9.550781 21.335938-21.332031 21.335938c-4.710937 0-8.53125-3.824219-8.53125-8.535156 0-4.714844 3.820313-8.535156 8.53125-8.535156 2.355469 0 4.265625-1.910157 4.265625-4.265626 0-2.355468-1.910156-4.265624-4.265625-4.265624-21.152344 0-29.867187 11.46875-30.890625 22.1875-.878906 14.535156 9.628906 27.273437 24.0625 29.183593 15.765625 4.65625 32.785156-.597656 43.179688-13.328125 11.917968-17.953125 13.296874-40.914062 3.609374-60.160156-8.859374-18.550781-27.957031-30.003906-48.492187-29.082031h-213.335937c-4.710938 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820312-8.53125 8.53125-8.53125h213.335937c27.015625-.859374 52.003906 14.265626 63.769531 38.601563 12.605469 25.066406 10.539063 55.011719-5.386718 78.109375-10.601563 13.847656-27.21875 21.753906-44.65625 21.246094zm0 0"
                                                                fill="#bdc3c7"></path>
                                                            <path
                                                                d="m375.480469 224.820312h-213.332031c-4.714844 0-8.535157-3.820312-8.535157-8.535156 0-4.710937 3.820313-8.53125 8.535157-8.53125h213.332031c20.523437.90625 39.605469-10.542968 48.460937-29.082031 9.683594-19.246094 8.308594-42.210937-3.609375-60.160156-9.566406-12.460938-24.535156-17.066407-43.179687-13.320313-14.417969 1.914063-24.90625 14.644532-24.03125 29.167969 1.070312 10.726563 9.738281 22.183594 30.894531 22.183594 2.355469 0 4.265625-1.910157 4.265625-4.265625 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535156-3.820313-8.535156-8.535157 0-4.710937 3.820312-8.53125 8.535156-8.53125 11.78125 0 21.332031 9.550782 21.332031 21.332032s-9.550781 21.332031-21.332031 21.332031c-30.5 0-46.023437-18.875-47.875-37.546875-1.890625-23.285156 14.539063-44.070312 37.632813-47.605469 22.144531-6.046875 45.757812 1.671875 60.058593 19.625 15.925781 23.097657 17.988281 53.042969 5.382813 78.105469-11.75 24.335938-36.722656 39.472656-63.734375 38.632812zm0 0"
                                                                fill="#7f8c8d"></path>
                                                            <path
                                                                d="m426.875 453.785156c-7.640625-.035156-15.261719-.824218-22.75-2.355468-33.242188-4.996094-56.972656-34.835938-54.355469-68.351563 2.457031-24.796875 22.050781-53.757813 69.1875-53.757813 15.414063.265626 27.773438 12.839844 27.773438 28.257813s-12.359375 27.988281-27.773438 28.257813c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125 6.0625-.167969 10.886719-5.128906 10.886719-11.191406s-4.824219-11.027344-10.886719-11.191406c-35.6875 0-50.347656 19.820312-52.199219 38.398437-1.625 24.75 16.152344 46.546875 40.722657 49.9375 38.664062 7.722656 60.015625-7.824218 71.097656-22.246094 19.414063-25.257812 21.898437-65.382812 6.042969-97.570312-14.375-30.1875-45.410156-48.851562-78.8125-47.394531h-397.261719c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125h397.261719c39.863281-1.292968 76.75 21.015626 94.121094 56.917969 18.628906 37.792969 15.410156 85.332031-7.816407 115.515625-15.507812 20.191406-39.785156 31.695313-65.238281 30.90625zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m40.34375 434.203125c-3.925781-.128906.757812-21.335937 1.234375-23.628906 2.132813-12.183594 8.472656-23.234375 17.921875-31.214844 26.070312-21.597656 62.0625-20.363281 93.867188-18.484375 2.175781.128906 16.492187 1.28125 17.3125-.621094-14.652344 33.890625-31.324219 75.226563-61.933594 97.890625-8.882813 6.957031-20.480469 9.414063-31.421875 6.65625-7.925781-2.652343-14.917969-7.535156-20.136719-14.0625-4.667969-5.144531-8.765625-16.28125-16.84375-16.535156zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m8.546875 465.988281c-3.59375.003907-6.808594-2.242187-8.035156-5.621093-1.226563-3.378907-.203125-7.164063 2.558593-9.464844 2.164063-1.765625 53.382813-44.160156 97.636719-53.019532 4.585938-.847656 9.007813 2.144532 9.921875 6.71875.914063 4.574219-2.015625 9.035157-6.578125 10.015626-40.035156 8.066406-89.53125 49.023437-90.035156 49.390624-1.535156 1.28125-3.46875 1.980469-5.46875 1.980469zm0 0"
                                                                fill="#805333"></path>
                                                            <path
                                                                d="m132.648438 105.386719c.953124 3.796875-20.753907 5.121093-23.042969 5.324219-12.296875 1.316406-24.664063-1.730469-34.941407-8.609376-27.964843-19.105468-36.710937-54.03125-43.683593-85.128906-.476563-2.132812-3.308594-16.214844-5.367188-16.476562 36.625 4.734375 80.949219 9.386718 111.179688 32.535156 9.136719 6.617188 14.699219 17.085938 15.0625 28.359375-.355469 8.359375-3.113281 16.441406-7.945313 23.277344-3.675781 5.863281-13.242187 12.894531-11.261718 20.71875zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m163.75 138.0625c-1.703125.003906-3.371094-.511719-4.777344-1.476562-2.328125-1.578126-57.171875-39.125-77.890625-79.242188-2.164062-4.1875-.523437-9.335938 3.664063-11.5 4.1875-2.160156 9.335937-.519531 11.496094 3.667969 18.773437 36.25 71.785156 72.601562 72.320312 72.957031 3.101562 2.109375 4.464844 5.992188 3.359375 9.574219s-4.421875 6.023437-8.171875 6.019531zm0 0"
                                                                fill="#805333"></path>
                                                            <g fill="#cb8252">
                                                                <path
                                                                    d="m366.945312 28.441406c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714843 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820313-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m486.414062 11.375c0 4.714844-3.820312 8.535156-8.535156 8.535156-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125 4.714844 0 8.535156 3.820312 8.535156 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m460.8125 207.640625c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m366.945312 310.042969c0 4.710937-3.820312 8.53125-8.53125 8.53125-4.714843 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820313-8.535157 8.535156-8.535157 4.710938 0 8.53125 3.820313 8.53125 8.535157zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m324.28125 455.109375c0 4.710937-3.820312 8.53125-8.535156 8.53125-4.710938 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820312-8.535156 8.53125-8.535156 4.714844 0 8.535156 3.820312 8.535156 8.535156zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m187.746094 412.441406c0 4.714844-3.820313 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710937 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m93.878906 224.707031h-85.332031c-4.710937 0-8.53125-3.820312-8.53125-8.53125 0-4.714843 3.820313-8.535156 8.53125-8.535156h85.332031c4.714844 0 8.535156 3.820313 8.535156 8.535156 0 4.710938-3.820312 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#bdc3c7"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Velocidad del<br> viento</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{obtenerDireccionViento(cmmLastData.windDir)}}</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-4 0 511 511.99978" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m51.703125 51.097656h17.066406v51.203125h-17.066406zm0 0"
                                                                fill="#5d9aa4"></path>
                                                            <path
                                                                d="m43.167969 272.976562h34.136719v230.414063h-34.136719zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m145.574219 42.5625h-136.539063c-4.714844 0-8.535156-3.820312-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h136.539063c4.714843 0 8.535156 3.820312 8.535156 8.535156 0 4.710938-3.820313 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m128.507812 68.164062c-4.714843 0-8.535156-3.820312-8.53125-8.535156 0-2.261718.898438-4.429687 2.496094-6.03125l19.570313-19.566406-19.570313-19.570312c-3.273437-3.386719-3.179687-8.792969.210938-12.066407 3.308594-3.191406 8.550781-3.191406 11.855468 0l25.601563 25.601563c3.332031 3.332031 3.332031 8.734375 0 12.066406l-25.601563 25.601562c-1.597656 1.601563-3.769531 2.5-6.03125 2.5zm0 0"
                                                                fill="#b9b9b9"></path>
                                                            <path
                                                                d="m34.636719 76.699219h51.203125v213.347656h-51.203125zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m34.636719 162.039062c-4.714844 0-8.535157-3.820312-8.535157-8.535156v-42.667968c0-4.714844 3.820313-8.535157 8.535157-8.535157h128.007812c4.710938.011719 8.523438 3.839844 8.511719 8.554688-.007812 3.660156-2.347656 6.90625-5.816406 8.070312l-128.007813 42.667969c-.867187.296875-1.78125.445312-2.695312.445312zm8.53125-42.671874v22.292968l66.890625-22.292968zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m162.644531 272.976562h-128.007812c-4.714844 0-8.535157-3.820312-8.535157-8.53125v-42.671874c.003907-2.742188 1.324219-5.316407 3.550782-6.917969 2.214844-1.621094 5.082031-2.058594 7.679687-1.171875l128.007813 42.671875c4.46875 1.5 6.875 6.335937 5.375 10.804687-1.164063 3.46875-4.414063 5.808594-8.070313 5.816406zm-119.476562-17.066406h66.890625l-66.890625-22.28125zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m85.839844 34.03125c0 14.140625-11.464844 25.601562-25.601563 25.601562-14.140625 0-25.601562-11.460937-25.601562-25.601562s11.460937-25.601562 25.601562-25.601562c14.136719 0 25.601563 11.460937 25.601563 25.601562zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m290.652344 298.578125c-14.464844.015625-27.355469 9.128906-32.191406 22.761719-1.570313 4.445312.761718 9.320312 5.207031 10.890625 4.441406 1.566406 9.316406-.761719 10.886719-5.207031 3.144531-8.886719 12.898437-13.542969 21.785156-10.398438 8.882812 3.144531 13.539062 12.894531 10.398437 21.78125-2.414062 6.816406-8.859375 11.375-16.085937 11.375h-42.671875c-4.710938 0-8.53125 3.824219-8.53125 8.535156 0 4.714844 3.820312 8.535156 8.53125 8.535156h42.671875c18.851562 0 34.132812-15.285156 34.132812-34.136718 0-18.851563-15.28125-34.136719-34.132812-34.136719zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m444.257812 400.984375h-93.871093c-4.710938 0-8.535157-3.820313-8.535157-8.53125 0-4.714844 3.824219-8.535156 8.535157-8.535156h93.871093c4.714844 0 8.535157 3.820312 8.535157 8.535156 0 4.710937-3.820313 8.53125-8.535157 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m316.253906 400.984375h-25.601562c-4.714844 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h25.601562c4.710938 0 8.53125 3.820312 8.53125 8.535156 0 4.710937-3.820312 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m256.515625 400.984375h-85.339844c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h85.339844c4.710937 0 8.53125 3.820312 8.53125 8.535156.003906 4.710937-3.820313 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m205.3125 435.121094h-34.136719c-4.710937 0-8.53125-3.820313-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125h34.136719c4.710938 0 8.53125 3.820312 8.53125 8.53125.003906 4.714843-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m213.847656 366.851562h-42.671875c-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710937 3.820313-8.535156 8.53125-8.535156h42.671875c4.710938 0 8.53125 3.824219 8.53125 8.535156 0 4.714844-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m273.582031 435.121094h-34.132812c-4.714844 0-8.535157-3.820313-8.535157-8.535156 0-4.710938 3.820313-8.53125 8.535157-8.53125h34.132812c4.714844 0 8.535157 3.820312 8.535157 8.53125 0 4.714843-3.820313 8.535156-8.535157 8.535156zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m407.042969 418.113281c-.101563 0-.195313-.058593-.296875-.058593h-90.492188c-4.714844 0-8.535156 3.820312-8.535156 8.53125 0 4.714843 3.820312 8.535156 8.535156 8.535156h88.222656.167969 1.210938c16.496093.003906 29.867187 13.378906 29.863281 29.875s-13.378906 29.867187-29.875 29.863281c-15.207031-.003906-27.980469-11.429687-29.675781-26.542969-.519531-4.683594-4.738281-8.0625-9.421875-7.542968-4.683594.519531-8.0625 4.738281-7.542969 9.421874 2.835937 25.765626 26.023437 44.355469 51.789063 41.519532 25.765624-2.835938 44.355468-26.023438 41.519531-51.789063-2.570313-23.34375-21.992188-41.195312-45.46875-41.789062zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m503.996094 230.308594-256.015625 39.828125-85.335938 2.839843v-170.675781l85.335938 2.84375 256.015625 39.824219zm0 0"
                                                                fill="#ea6b65"></path>
                                                            <g fill="#d9d9d9">
                                                                <path
                                                                    d="m418.65625 243.585938 85.339844-13.277344v-85.339844l-85.339844-13.277344zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m333.320312 256.859375v-138.4375l-85.339843-13.277344v164.992188zm0 0">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Dirección del<br> viento</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="cmm1" class="col-xl-4"> <!-- -->
                <div class="container py-5 h-100">

                    <div class="row d-flex justify-content-center align-items-center h-100" style="color: #282828;">
                        <div class="col-xl-11 bgc fullh" *ngIf="cmm1Loading" style="min-height: 55vh;">
                            <mat-progress-bar mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                        <div class="col-xl-11 bgc fullh" *ngIf="!cmm1Loading">

                            <div class="card mb-4 gradient-custom" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo1" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <h2 class="display-2"><strong>{{cmm1Name}}</strong></h2>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">calendar_today</mat-icon>
                                                                {{getDia(cmm1LastData.formattedDateTime)}}</strong></span>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">access_time</mat-icon>
                                                                {{getHora(cmm1LastData.formattedDateTime)}}</strong></span>
                                                        <br>
                                                        <button mat-button (click)="isOpenCmmData(1)" class="mt-5 pt-1">
                                                            <i class="fa-solid fa-clock-rotate-left fa-2x text-muted historial-i"
                                                                style="color: #ddd;"></i></button>
                                                    </div>
                                                    <div>
                                                        <div class="ml-4">
                                                            <div class="mat-elevation-z2" style="border-radius: 20px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="120"
                                                                    height="120"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    version="1.1" x="0px" y="0px" viewBox="0 0 512 512"
                                                                    enable-background="new 0 0 512 512"
                                                                    xml:space="preserve">
                                                                    <g id="Ebene_3" display="none">
                                                                        <rect x="-2020" y="-2565" display="inline"
                                                                            fill="#4D4D4E" width="5364" height="3336" />
                                                                    </g>
                                                                    <g id="Grid" display="none">
                                                                        <g display="inline">
                                                                            <g>
                                                                                <path fill="#B3B3B3"
                                                                                    d="M511,1v510H1V1H511 M512,0H0v512h512V0L512,0z" />
                                                                            </g>
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="6" y1="6"
                                                                                x2="506" y2="506" />
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="506" y1="6"
                                                                                x2="6" y2="506" />
                                                                            <circle fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" cx="256" cy="256"
                                                                                r="250" />
                                                                        </g>
                                                                    </g>
                                                                    <g id="Ebene_2">
                                                                        <circle fill="#84abc1" cx="353.935" cy="183.958"
                                                                            r="21.065" />
                                                                        <circle fill="#84abc1" cx="428.935" cy="183.958"
                                                                            r="21.065" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="87.37" x2="255.997" y2="471.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="256.003"
                                                                            y1="331.37" x2="316.874" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="316.874"
                                                                            y1="236.37" x2="390.997" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="390.997"
                                                                            y1="236.37" x2="390.997" y2="198.037" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="361.371" x2="165.871" y2="400.372" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="165.871"
                                                                            y1="400.372" x2="165.871" y2="354.371" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="206.871" y2="324.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="206.871"
                                                                            y1="324.368" x2="206.871" y2="298.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="124.871" y2="298.368" />

                                                                        <polygon fill="none" stroke="#7fed6f"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="   184.997,165.621 154.997,182.364 126.692,165.621 126.692,86.624 184.997,86.624  " />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="202.302"
                                                                            y1="118.364" x2="255.997" y2="172.059" />
                                                                    </g>
                                                                    <g id="txt">
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <br>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card mb-4" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo2" class="">
                                        <!-- Indicators -->
                                        <div>
                                            <div>
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm1LastData.outTemp}}°C</strong>
                                                        </p>
                                                        <svg style="margin-right: 20px" height="60px"
                                                            viewBox="-42 0 512 512" width="60px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m84.144531 38.53125c4.394531 19.445312 27.988281 27.109375 42.96875 13.960938 2.53125-2.222657 6.324219.53125 4.992188 3.625-7.878907 18.3125 6.703125 38.382812 26.554687 36.550781 3.355469-.308594 4.804688 4.144531 1.90625 5.867187-17.136718 10.1875-17.136718 34.996094 0 45.179688 2.898438 1.722656 1.449219 6.175781-1.90625 5.867187-19.851562-1.832031-34.433594 18.238281-26.554687 36.550781 1.332031 3.097657-2.460938 5.847657-4.992188 3.625-14.980469-13.148437-38.578125-5.480468-42.96875 13.960938-.742187 3.289062-5.425781 3.289062-6.167969 0-4.390624-19.445312-27.984374-27.109375-42.96875-13.960938-2.53125 2.222657-6.324218-.527343-4.992187-3.625 7.878906-18.3125-6.703125-38.382812-26.554687-36.550781-3.355469.308594-4.804688-4.144531-1.90625-5.867187 17.136718-10.183594 17.136718-34.992188 0-45.179688-2.894532-1.722656-1.449219-6.175781 1.90625-5.867187 19.851562 1.832031 34.433593-18.238281 26.554687-36.550781-1.332031-3.09375 2.460937-5.847657 4.992187-3.625 14.984376 13.148437 38.578126 5.484374 42.96875-13.960938.742188-3.289062 5.425782-3.289062 6.167969 0zm0 0"
                                                                fill="#fd8f31"></path>
                                                            <path
                                                                d="m160.566406 143.714844c-17.136718-10.183594-17.136718-34.992188 0-45.179688 2.898438-1.71875 1.449219-6.175781-1.90625-5.863281-19.847656 1.832031-34.429687-18.242187-26.554687-36.554687 1.332031-3.09375-2.457031-5.847657-4.992188-3.625-14.980469 13.152343-38.574219 5.484374-42.96875-13.960938-.742187-3.289062-5.425781-3.289062-6.167969 0-1.625 7.191406-5.875 12.761719-11.34375 16.277344 19.398438 15.589844 31.824219 39.496094 31.824219 66.320312 0 26.820313-12.425781 50.730469-31.824219 66.320313 5.46875 3.511719 9.71875 9.085937 11.34375 16.273437.742188 3.289063 5.429688 3.289063 6.167969 0 4.394531-19.445312 27.988281-27.109375 42.96875-13.960937 2.535157 2.222656 6.324219-.527344 4.992188-3.625-7.875-18.3125 6.707031-38.382813 26.554687-36.550781 3.355469.308593 4.804688-4.148438 1.90625-5.871094zm0 0"
                                                                fill="#ff7f1f"></path>
                                                            <path
                                                                d="m115.933594 121.125c0 19.261719-15.613282 34.871094-34.875 34.871094-19.257813 0-34.871094-15.609375-34.871094-34.871094 0-19.257812 15.613281-34.871094 34.871094-34.871094 19.261718 0 34.875 15.613282 34.875 34.871094zm0 0"
                                                                fill="#fccf3f"></path>
                                                            <path
                                                                d="m334.011719 315.355469v-253.195313c0-34.332031-27.832031-62.160156-62.160157-62.160156-34.332031 0-62.160156 27.828125-62.160156 62.160156v253.195313c-27.8125 19.566406-45.984375 51.910156-45.984375 88.5 0 59.726562 48.417969 108.144531 108.144531 108.144531 59.726563 0 108.144532-48.417969 108.144532-108.144531 0-36.589844-18.175782-68.933594-45.984375-88.5zm0 0"
                                                                fill="#d8d8d8"></path>
                                                            <path
                                                                d="m271.851562 476.582031c-41.183593 0-74.6875-33.507812-74.6875-74.6875 0-31.273437 19.433594-59.117187 48.59375-69.980469v-269.753906c0-14.390625 11.703126-26.09375 26.09375-26.09375 14.386719 0 26.09375 11.703125 26.09375 26.09375v269.753906c29.15625 10.859376 48.59375 38.703126 48.59375 69.976563 0 41.183594-33.503906 74.691406-74.6875 74.691406zm0 0"
                                                                fill="#f3e8d7"></path>
                                                            <path
                                                                d="m245.757812 109.871094v222.042968c-29.160156 10.859376-48.59375 38.703126-48.59375 69.976563 0 41.183594 33.503907 74.6875 74.6875 74.6875 41.183594 0 74.6875-33.503906 74.6875-74.6875 0-31.273437-19.4375-59.117187-48.59375-69.976563v-222.042968zm0 0"
                                                                fill="#fc4e51"></path>
                                                            <g fill="#0d6e9a">
                                                                <path
                                                                    d="m420.105469 180.21875h-44.824219c-4.269531 0-7.726562-3.460938-7.726562-7.726562 0-4.265626 3.457031-7.726563 7.726562-7.726563h44.824219c4.265625 0 7.726562 3.460937 7.726562 7.726563 0 4.265624-3.460937 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 117.597656h-20.351562c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726563 7.726562-7.726563h20.351562c4.265626 0 7.726563 3.460938 7.726563 7.726563s-3.460937 7.726562-7.726563 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 54.976562h-27.5625c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726562 7.726562-7.726562h27.5625c4.269531 0 7.726562 3.460937 7.726562 7.726562s-3.457031 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 305.460938h-27.5625c-4.269531 0-7.726562-3.460938-7.726562-7.726563 0-4.269531 3.457031-7.726563 7.726562-7.726563h27.5625c4.269531 0 7.726562 3.457032 7.726562 7.726563 0 4.265625-3.457031 7.726563-7.726562 7.726563zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 242.839844h-20.351562c-4.269531 0-7.726562-3.460938-7.726562-7.726563s3.457031-7.726562 7.726562-7.726562h20.351562c4.265626 0 7.726563 3.460937 7.726563 7.726562s-3.460937 7.726563-7.726563 7.726563zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m297.945312 331.914062v-222.042968h-52.1875v222.042968s36.074219 16.738282 36.074219 69.976563c0 29.601563-17.308593 55.234375-42.335937 67.3125 9.792968 4.726563 20.769531 7.375 32.351562 7.375 41.183594 0 74.691406-33.503906 74.691406-74.6875 0-31.273437-19.4375-59.113281-48.59375-69.976563zm0 0"
                                                                fill="#e5394b"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Temperatura</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm1LastData.outHumidity}}%</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-38 0 420 420.192" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m344.425781 175.695312c.210938 31.570313-16.507812 60.835938-43.816406 76.679688-27.304687 15.847656-61.007813 15.847656-88.3125 0-27.304687-15.84375-44.027344-45.109375-43.8125-76.679688 0-48.582031 87.972656-175.6874995 87.972656-175.6874995s87.96875 127.0976565 87.96875 175.6874995zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m256.453125 0s-5.3125 7.695312-13.3125 19.960938c24.585937 37.734374 74.660156 119.066406 74.660156 155.734374-.011719 43.441407-31.722656 80.375-74.660156 86.960938 25.355469 3.859375 51.136719-3.527344 70.597656-20.238281 19.460938-16.707031 30.664063-41.074219 30.6875-66.722657 0-48.589843-87.972656-175.695312-87.972656-175.695312zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m214.261719 66.601562c-22.570313 38.511719-45.761719 84.136719-45.761719 109.085938.003906 32.640625 18.074219 62.59375 46.949219 77.8125s63.800781 13.199219 90.726562-5.246094c-15.175781-52.742187-58.601562-128.222656-91.914062-181.652344zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m279.007812 280.734375c0 77.019531-62.4375 139.457031-139.457031 139.457031s-139.4531248-62.4375-139.4531248-139.457031 139.4531248-278.511719 139.4531248-278.511719 139.457031 201.472656 139.457031 278.511719zm0 0"
                                                                fill="#26a3b9"></path>
                                                            <g fill="#168aa5">
                                                                <path
                                                                    d="m227.484375 187.15625 19.304687 19.308594-156.308593 156.308594-19.308594-19.308594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m126.574219 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m229.730469 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m139.550781 2.222656s-7.769531 11.230469-19.636719 29.390625c38.402344 58.796875 119.820313 190.277344 119.820313 249.121094-.007813 69.425781-51.082031 128.273437-119.8125 138.054687 40.011719 5.71875 80.535156-6.214843 111.0625-32.703124 30.527344-26.492188 48.050781-64.929688 48.023437-105.351563 0-77.039063-139.457031-278.511719-139.457031-278.511719zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m208.058594 187.15625 19.304687 19.304688-156.308593 156.3125-19.308594-19.308594zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m107.148438 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m210.304688 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Humedad</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm1LastData.pressure}}hPa</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 0 512 512" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m256 512c-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.636719-74.980469-181.019531s26.628906-132.667969 74.980469-181.019531c48.351562-48.351563 112.640625-74.980469 181.019531-74.980469s132.667969 26.628906 181.019531 74.980469c48.351563 48.351562 74.980469 112.636719 74.980469 181.019531s-26.628906 132.667969-74.980469 181.019531c-48.351562 48.351563-112.640625 74.980469-181.019531 74.980469zm0-478.609375c-122.746094 0-222.609375 99.863281-222.609375 222.609375s99.863281 222.609375 222.609375 222.609375 222.609375-99.863281 222.609375-222.609375-99.863281-222.609375-222.609375-222.609375zm0 0"
                                                                fill="#736056"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-36.414063-36.414063-84.097656-54.839844-131.929687-55.308594-.613282-.070312-1.234376-.113281-1.867188-.113281s-1.253906.042969-1.867188.113281c-47.828124.46875-95.507812 18.894531-131.921874 55.300781-.003907.003907-.003907.003907-.007813.007813s-.003906.003906-.007813.007813c-36.410156 36.414062-54.832031 84.09375-55.300781 131.921874-.070312.613282-.113281 1.234376-.113281 1.867188s.042969 1.253906.113281 1.867188c.46875 47.832031 18.894531 95.515624 55.308594 131.929687 3.261719 3.261719 7.53125 4.890625 11.804687 4.890625 4.273438 0 8.546876-1.628906 11.804688-4.890625l23.613281-23.609375c6.519531-6.519531 6.519531-17.089844 0-23.613281-6.523437-6.519531-17.09375-6.519531-23.613281 0l-11.175781 11.175781c-19.242188-23.847656-30.410157-52.097656-33.503907-81.054688h15.738282c9.21875 0 16.695312-7.476562 16.695312-16.695312s-7.476562-16.695312-16.695312-16.695312h-15.726563c3.09375-28.953126 14.261719-57.199219 33.5-81.050782l11.171875 11.171875c3.257813 3.257813 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.546875-1.632812 11.804687-4.890625 6.519531-6.519531 6.519531-17.09375 0-23.613281l-11.171875-11.171875c23.851563-19.238281 52.097656-30.402344 81.050782-33.492187v15.722656c0 9.21875 7.476562 16.695312 16.695312 16.695312 9.222656 0 16.695312-7.476562 16.695312-16.695312v-15.722656c34.171876 3.648437 67.355469 18.53125 93.492188 44.664062 6.519531 6.519531 17.089844 6.519531 23.609375 0s6.519531-17.089844 0-23.609375zm0 0"
                                                                fill="#88cc2a"></path>
                                                            <path
                                                                d="m445.21875 256c0-.632812-.042969-1.253906-.113281-1.867188-.46875-47.832031-18.894531-95.515624-55.308594-131.929687-6.519531-6.519531-17.089844-6.519531-23.609375 0-6.523438 6.519531-6.523438 17.089844 0 23.609375 26.132812 26.136719 41.015625 59.324219 44.667969 93.492188h-15.726563c-9.21875 0-16.695312 7.476562-16.695312 16.695312s7.476562 16.695312 16.695312 16.695312h15.734375c-3.09375 28.957032-14.257812 57.207032-33.5 81.054688l-11.175781-11.175781c-6.519531-6.519531-17.09375-6.519531-23.613281 0s-6.519531 17.089843 0 23.613281l23.613281 23.609375c3.257812 3.261719 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.542968-1.628906 11.804687-4.890625 36.414063-36.414063 54.839844-84.097656 55.308594-131.929687.070312-.613282.113281-1.234376.113281-1.867188zm0 0"
                                                                fill="#ff9f19"></path>
                                                            <path
                                                                d="m272.695312 445.21875h-33.390624c-27.617188 0-50.085938-22.46875-50.085938-50.089844 0-27.617187 22.46875-50.085937 50.085938-50.085937h33.390624c27.617188 0 50.085938 22.46875 50.085938 50.085937 0 27.621094-22.46875 50.089844-50.085938 50.089844zm-33.390624-66.785156c-9.207032 0-16.695313 7.492187-16.695313 16.695312 0 9.207032 7.488281 16.695313 16.695313 16.695313h33.390624c9.207032 0 16.695313-7.488281 16.695313-16.695313 0-9.203125-7.488281-16.695312-16.695313-16.695312zm0 0"
                                                                fill="#bddeff"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-6.519531-6.519531-17.089844-6.519531-23.609375 0l-84.46875 84.46875c-7.699219-4.03125-16.445312-6.324219-25.71875-6.324219-30.6875 0-55.652344 24.964844-55.652344 55.652344s24.964844 55.652344 55.652344 55.652344 55.652344-24.964844 55.652344-55.652344c0-9.273438-2.292969-18.019531-6.324219-25.71875l84.46875-84.46875c6.519531-6.519531 6.519531-17.089844 0-23.609375zm-133.796875 156.058594c-12.273438 0-22.261719-9.988281-22.261719-22.261719s9.988281-22.261719 22.261719-22.261719 22.261719 9.988281 22.261719 22.261719-9.988281 22.261719-22.261719 22.261719zm0 0"
                                                                fill="#e6563a"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Presión</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm1LastData.rain}}mm</strong></p>
                                                        <svg height="45.541px" viewBox="0 -7 512 512" width="47.542px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m21.316406 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.0117188 1.125.03125 1.683594 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578124-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m21.316406 457.257812c-10.378906 0-20.753906-6.703124-21.2734372-20.097656-.0234376-.5625-.0429688-1.125-.0429688-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.570312 7.371094-4.292968 21.140625-4.292968 36.914062 0 .558594 0 1.121094.007812 1.683594.105469 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 438.421875c-12.742187 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574218-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 329.023438c-12.742187 7.371093-21.316406 21.136718-21.316406 36.914062 0 .5625.011719 1.125.03125 1.679688 1.039062 26.800781 41.527344 26.800781 42.566406 0 .023438-.554688.03125-1.117188.03125-1.679688.003906-15.777344-8.574218-29.542969-21.3125-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 387.71875c-10.378906 0-20.757812-6.707031-21.277344-20.101562-.019531-.558594-.039062-1.121094-.039062-1.683594 0-15.773438 8.578125-29.539063 21.316406-36.914063-2.570312 7.375-4.296875 21.140625-4.296875 36.914063 0 .5625 0 1.125.011719 1.683594.105469 13.394531 2.195312 20.101562 4.285156 20.101562zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m208.1875 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m208.1875 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m301.625 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.007812 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574219-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m301.625 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105468 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m489.035156 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.035156-1.117187.035156-1.683593 0-15.777344-8.578124-29.542969-21.316406-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m489.035156 497.117188c-10.378906 0-20.753906-6.703126-21.273437-20.097657-.023438-.5625-.042969-1.125-.042969-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m395.058594 398.5625c-12.738282 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527343 26.796875 42.566406 0 .023437-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m395.058594 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578124-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m408.25 181.570312c0 37.824219-30.664062 68.492188-68.503906 68.492188h-247.886719c-50.296875 0-91.082031-40.773438-91.082031-91.082031 0-50.296875 40.78125-91.082031 91.082031-91.082031 4.511719 0 8.945313.347656 13.292969.976562 20.570312-40.84375 62.871094-68.875 111.742187-68.875 59.128907 0 108.667969 41.054688 121.679688 96.210938 1.300781 5.503906 2.238281 11.144531 2.777343 16.894531v.011719c37.082032.851562 66.898438 31.167968 66.898438 68.453124zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m260.351562 7.769531c-13.539062-5.027343-28.183593-7.76562475-43.457031-7.76562475-48.871093 0-91.160156 28.03124975-111.742187 68.86328175v.011718c-4.347656-.628906-8.78125-.976562-13.292969-.976562-50.3125 0-91.082031 40.785156-91.082031 91.082031 0 50.3125 40.769531 91.082031 91.082031 91.082031h44.019531c-45.332031 0-82.066406-36.746094-82.066406-82.070312 0-40.214844 28.929688-73.683594 67.128906-80.703125 3.777344-.707031 7.648438-1.144531 11.597656-1.300781 1.105469-.035157 2.222657-.0625 3.339844-.0625 14.734375 0 28.570313 3.882812 40.515625 10.699218-1.660156-11.5625-13.613281-22.394531-31.238281-31.125 9.679688-32.726562 42.613281-63.078125 115.195312-57.734375zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m264.6875 245.550781c.496094.089844.789062.089844.835938.089844h-3.792969c.992187 0 1.972656-.03125 2.957031-.089844zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m512 313.621094c0 30.589844-24.800781 55.390625-55.402344 55.390625h-200.476562c-40.675782 0-73.660156-32.972657-73.660156-73.660157 0-40.679687 32.984374-73.660156 73.660156-73.660156 3.648437 0 7.238281.28125 10.75.789063 16.640625-33.035157 50.847656-55.703125 90.371094-55.703125 47.824218 0 87.886718 33.203125 98.410156 77.808594 1.050781 4.453124 1.808594 9.015624 2.246094 13.664062v.011719c29.988281.683593 54.101562 25.203125 54.101562 55.359375zm0 0"
                                                                fill="#d3ddea"></path>
                                                            <path
                                                                d="m392.386719 173.0625c-10.949219-4.066406-22.792969-6.28125-35.144531-6.28125-39.523438 0-73.722657 22.667969-90.371094 55.691406v.011719c-3.515625-.511719-7.101563-.789063-10.75-.789063-40.6875 0-73.660156 32.980469-73.660156 73.660157 0 40.6875 32.972656 73.660156 73.660156 73.660156h35.601562c-36.664062 0-66.371094-29.71875-66.371094-66.371094 0-32.527343 23.394532-59.59375 54.289063-65.269531 3.058594-.574219 6.1875-.925781 9.378906-1.050781.894531-.03125 1.800781-.050781 2.703125-.050781 11.917969 0 23.105469 3.140624 32.765625 8.648437-1.339843-9.351563-11.007812-18.109375-25.261719-25.167969 7.824219-26.46875 34.460938-51.015625 93.160157-46.691406zm0 0"
                                                                fill="#84abc1"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Lluvia</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo3" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm1LastData.dewpoint}}°C</strong>
                                                        </p>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                                            id="Layer_1" x="0px" y="0px" viewBox="0 0 497 497"
                                                            style="enable-background:new 0 0 497 497;"
                                                            xml:space="preserve" width="55px" height="55px">
                                                            <g>
                                                                <path style="fill:#59C2E8;"
                                                                    d="M183.391,58.325c-6.66-6.477-17.246-6.477-23.907,0C-78.787,290.047-14.684,497,171.438,497   S421.663,290.047,183.391,58.325z">
                                                                </path>
                                                                <path style="fill:#0097D1;"
                                                                    d="M201.438,477C19.441,477-45.878,279.117,174.095,53.691c-5.15-0.806-10.593,0.727-14.611,4.634   C-78.787,290.047-14.684,497,171.438,497c48.559,0,88.805-14.094,117.55-39.032C264.453,470.302,234.92,477,201.438,477z">
                                                                </path>
                                                                <g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M99.951,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5s7.5,3.358,7.5,7.5     v12.938C107.451,283.894,104.094,287.251,99.951,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M242.925,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5     s7.5,3.358,7.5,7.5v12.938C250.425,283.894,247.067,287.251,242.925,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M171.438,294.157c-9.041,0-17.53-3.78-23.291-10.371c-2.727-3.119-2.408-7.857,0.711-10.583     c3.117-2.726,7.856-2.408,10.582,0.711c2.912,3.332,7.285,5.242,11.998,5.242c4.712,0,9.085-1.911,11.997-5.242     c2.726-3.119,7.464-3.437,10.583-0.711c3.118,2.726,3.437,7.464,0.71,10.583C188.968,290.378,180.478,294.157,171.438,294.157z">
                                                                        </path>
                                                                    </g>
                                                                </g>
                                                                <path style="fill:#D5EBF6;"
                                                                    d="M420.006,262.727V50.657C420.006,22.68,397.326,0,369.35,0h0c-25.428,0-46.475,18.737-50.1,43.157   l4.08,7.5l-4.637,7.5V79.17l3.97,7.5l-3.97,7.5v21.014l4.637,7.5l-4.637,7.5v21.014l3.637,7.5l-3.637,7.5v21.013l3.637,7.5   l-3.637,7.5v60.516c-42.433,19.279-71.942,62.03-71.942,111.674C246.751,442.111,301.64,497,369.35,497   s122.599-54.889,122.599-122.599C491.948,324.757,462.439,282.006,420.006,262.727z">
                                                                </path>
                                                                <path style="fill:#FF6E80;"
                                                                    d="M369.349,462.349c-48.494,0-87.947-39.453-87.947-87.947c0-34.427,20.264-65.879,51.625-80.127   l20.318-9.23V50.656c0-8.825,7.18-16.005,16.005-16.005c8.825,0,16.006,7.18,16.006,16.005v234.388l20.318,9.231   c31.361,14.248,51.625,45.7,51.625,80.127C457.297,422.896,417.844,462.349,369.349,462.349z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,187.211v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H318.693   L318.693,187.211z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,151.198v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,151.198z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,115.184v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,115.184z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,50.657v7.5h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-34.095   C318.886,45.604,318.693,48.107,318.693,50.657z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,79.17v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,79.17z">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Punto de<br> rocío</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm1LastData.heatindex}}°C</strong>
                                                        </p>
                                                        <svg style="margin-right: 20px" height="70px"
                                                            viewBox="-78 0 512 512" width="75px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-13.621094-15.976562-21.824218-36.6875-21.824218-59.308593 0-50.550782 40.976562-91.527344 91.527343-91.527344 46.082031 0 84.203125 34.046875 90.585938 78.363281.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fab03c"></path>
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-.621094-4.300781-.9375-8.691406-.9375-13.160156 0-50.554687 40.984375-91.527344 91.527344-91.527344 27.925781 0 52.925781 12.503907 69.699219 32.214844.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <g fill="#f8e98e">
                                                                <path
                                                                    d="m150.769531 34.9375c-4.207031 0-7.613281-3.410156-7.613281-7.613281v-19.714844c0-4.203125 3.410156-7.609375 7.613281-7.609375s7.609375 3.40625 7.609375 7.609375v19.714844c0 4.203125-3.40625 7.613281-7.609375 7.613281zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m89.054688 51.476562c-2.632813 0-5.191407-1.367187-6.601563-3.808593l-9.855469-17.070313c-2.101562-3.640625-.855468-8.296875 2.785156-10.398437 3.640626-2.101563 8.296876-.855469 10.398438 2.785156l9.855469 17.074219c2.101562 3.636718.855469 8.292968-2.785157 10.394531-1.199218.691406-2.507812 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m43.855469 96.660156c-1.289063 0-2.597657-.332031-3.796875-1.023437l-17.074219-9.855469c-3.640625-2.101562-4.886719-6.757812-2.785156-10.398438 2.101562-3.640624 6.753906-4.886718 10.394531-2.785156l17.074219 9.855469c3.640625 2.105469 4.886719 6.757813 2.785156 10.398437-1.410156 2.445313-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m27.324219 158.378906h-19.714844c-4.203125 0-7.609375-3.40625-7.609375-7.609375s3.40625-7.613281 7.609375-7.613281h19.714844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m257.679688 96.660156c-2.628907 0-5.1875-1.367187-6.597657-3.808594-2.101562-3.640624-.855469-8.292968 2.785157-10.398437l17.074218-9.855469c3.636719-2.101562 8.292969-.855468 10.394532 2.785156 2.101562 3.640626.855468 8.296876-2.785157 10.398438l-17.074219 9.855469c-1.195312.691406-2.503906 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m26.796875 229.960938c-2.628906 0-5.1875-1.367188-6.597656-3.808594-2.101563-3.640625-.855469-8.296875 2.785156-10.394532l17.074219-9.859374c3.636718-2.097657 8.292968-.851563 10.394531 2.789062s.855469 8.292969-2.785156 10.394531l-17.074219 9.855469c-1.195312.695312-2.503906 1.023438-3.796875 1.023438zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m212.484375 51.476562c-1.292969 0-2.601563-.332031-3.800781-1.023437-3.640625-2.101563-4.886719-6.757813-2.785156-10.394531l9.855468-17.074219c2.101563-3.640625 6.757813-4.886719 10.398438-2.785156 3.640625 2.101562 4.886718 6.757812 2.785156 10.398437l-9.855469 17.070313c-1.410156 2.441406-3.96875 3.808593-6.597656 3.808593zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m79.183594 282.359375c-1.292969 0-2.601563-.328125-3.800782-1.019531-3.640624-2.105469-4.886718-6.757813-2.785156-10.398438l9.855469-17.074218c2.101563-3.640626 6.757813-4.886719 10.398437-2.785157 3.640626 2.101563 4.886719 6.757813 2.785157 10.398438l-9.855469 17.070312c-1.410156 2.441407-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m329.234375 240.59375c0 10.503906-8.519531 19.019531-19.023437 19.019531-10.503907 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.519531-19.023438 19.023438-19.023438 10.503906 0 19.023437 8.515626 19.023437 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m177.15625 240.59375c0 10.503906-8.515625 19.019531-19.019531 19.019531-10.507813 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.515625-19.023438 19.023438-19.023438 10.503906 0 19.019531 8.515626 19.019531 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m353.683594 439.070312v62.78125c0 5.605469-4.542969 10.148438-10.148438 10.148438h-218.722656c-5.601562 0-10.148438-4.542969-10.148438-10.148438v-62.78125c0-43.292968 23.027344-81.207031 57.492188-102.175781 18.074219-10.988281 39.308594-17.332031 62.019531-17.332031 22.5 0 43.535157 6.21875 61.5 17.03125 8.390625 5.042969 16.125 11.089844 23.007813 17.972656 21.625 21.636719 35 51.511719 35 84.503906zm0 0"
                                                                fill="#f0f5f7"></path>
                                                            <path
                                                                d="m172.503906 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.609375-7.609375 4.203125 0 7.613281 3.398437 7.613281 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m311.171875 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.613281-7.609375 4.199219 0 7.609375 3.398437 7.609375 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m343.539062 511.992188h-47.589843v-67.089844h57.738281v56.945312c0 5.601563-4.542969 10.144532-10.148438 10.144532zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m295.949219 444.90625h15.222656v67.082031h-15.222656zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m172.402344 444.90625v67.082031h-47.589844c-5.601562 0-10.148438-4.542969-10.148438-10.148437v-56.933594zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m157.28125 444.90625h15.121094v67.082031h-15.121094zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m295.679688 336.59375c-13.59375 19.570312-36.234376 32.367188-61.863282 32.367188-25.496094 0-48.042968-12.671876-61.65625-32.066407 18.070313-10.992187 39.304688-17.328125 62.015625-17.328125 22.5 0 43.535157 6.210938 61.503907 17.027344zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m164.230469 174.1875v85.160156c0 38.625 31.316406 69.941406 69.941406 69.941406 38.628906 0 69.941406-31.316406 69.941406-69.941406v-85.160156zm0 0"
                                                                fill="#fbdbb7"></path>
                                                            <path
                                                                d="m304.113281 174.1875v85.15625c0 19.3125-7.828125 36.808594-20.488281 49.460938-12.652344 12.652343-30.140625 20.480468-49.453125 20.480468-3.757813 0-7.445313-.292968-11.050781-.875 14.886718-2.355468 28.207031-9.421875 38.402344-19.605468 12.652343-12.652344 20.480468-30.148438 20.480468-49.460938v-85.15625zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m234.175781 119.171875c-44.792969 0-81.101562 36.308594-81.101562 81.101563v17.472656c0 6.152344 5.417969 10.886718 11.515625 10.054687 20.5-2.789062 62.964844-10.910156 82.292968-31.78125 7.195313 11.8125 23.039063 26.890625 57.433594 29.546875 5.90625.457032 10.957032-4.199218 10.957032-10.121094v-15.171874c0-44.792969-36.308594-81.101563-81.097657-81.101563zm0 0"
                                                                fill="#82807f"></path>
                                                            <path
                                                                d="m315.273438 200.277344v15.164062c0 5.929688-5.066407 10.578125-10.980469 10.121094-4.617188-.359375-8.902344-.941406-12.878907-1.710938v-23.574218c0-40.738282-30.03125-74.460938-69.175781-80.238282 3.898438-.578124 7.875-.871093 11.9375-.871093 44.785157 0 81.097657 36.3125 81.097657 81.109375zm0 0"
                                                                fill="#6d6d6d"></path>
                                                            <path
                                                                d="m201.347656 248.203125c-4.207031 0-7.660156-3.40625-7.660156-7.609375s3.363281-7.613281 7.570312-7.613281h.089844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m267.089844 248.203125c-4.203125 0-7.65625-3.40625-7.65625-7.609375s3.363281-7.613281 7.566406-7.613281h.089844c4.207031 0 7.613281 3.410156 7.613281 7.613281s-3.410156 7.609375-7.613281 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m241.503906 292.371094h-14.65625c-4.207031 0-7.613281-3.40625-7.613281-7.609375v-44.167969c0-4.203125 3.410156-7.613281 7.613281-7.613281s7.609375 3.410156 7.609375 7.613281v36.554688h7.046875c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.410156 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <path
                                                                d="m323.347656 172.90625c0 10.621094-8.609375 19.230469-19.230468 19.230469-10.621094 0-19.230469-8.609375-19.230469-19.230469 0-6.96875 8.28125-20.652344 13.972656-29.230469 2.496094-3.761719 8.015625-3.761719 10.511719 0 5.695312 8.578125 13.976562 22.261719 13.976562 29.230469zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m323.34375 172.90625c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.660156 0-5.199219-.542969-7.507812-1.519531 6.898437-2.921876 11.730469-9.753907 11.730469-17.710938 0-6.09375-6.328126-17.316406-11.722657-25.777344.773438-1.214844 1.527344-2.367187 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.519532-.011719 5.691406 8.582031 13.972656 22.261719 13.972656 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m356.394531 368.648438c0 10.621093-8.613281 19.230468-19.234375 19.230468s-19.230468-8.609375-19.230468-19.230468c0-6.972657 8.285156-20.652344 13.976562-29.234376 2.496094-3.761718 8.015625-3.761718 10.511719 0 5.691406 8.582032 13.976562 22.261719 13.976562 29.234376zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m356.390625 368.648438c0 10.617187-8.605469 19.230468-19.230469 19.230468-2.664062 0-5.199218-.542968-7.507812-1.519531 6.894531-2.921875 11.730468-9.753906 11.730468-17.710937 0-6.09375-6.328124-17.320313-11.722656-25.78125.769532-1.210938 1.523438-2.367188 2.238282-3.441407 2.496093-3.765625 8.019531-3.777343 10.515624-.011719 5.695313 8.582032 13.976563 22.261719 13.976563 29.234376zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.621094-8.609375 19.230469-19.230469 19.230469s-19.230469-8.609375-19.230469-19.230469c0-6.96875 8.28125-20.652344 13.976563-29.234375 2.496093-3.757813 8.015625-3.757813 10.511719 0 5.691406 8.582031 13.972656 22.265625 13.972656 29.234375zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.664063 0-5.199219-.542969-7.507813-1.519531 6.894532-2.921876 11.726563-9.753907 11.726563-17.710938 0-6.09375-6.328125-17.316406-11.71875-25.777344.769531-1.214844 1.523437-2.371094 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.515625-.011719 5.695313 8.582031 13.976563 22.261719 13.976563 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Sensación<br> térmica</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{cmm1LastData.windSpeed}}Km/h</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 -23 511.99037 511" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m238.945312 182.152344h-213.332031c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h213.332031c20.527344.90625 39.605469-10.542969 48.460938-29.082032 9.6875-19.246094 8.308594-42.207031-3.609375-60.160156-9.566406-12.449219-24.515625-17.066406-43.175781-13.320312-14.421875 1.917968-24.910156 14.648437-24.03125 29.167968 1.066406 10.726563 9.738281 22.1875 30.890625 22.1875 2.355469 0 4.265625-1.910156 4.265625-4.269531 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535157-3.820312-8.535157-8.53125 0-4.714844 3.820313-8.535156 8.535157-8.535156 11.78125 0 21.332031 9.550781 21.332031 21.332031 0 11.785156-9.550781 21.335937-21.332031 21.335937-30.5 0-46.019531-18.875-47.871094-37.546874-1.890625-23.285157 14.539063-44.070313 37.632813-47.609376 22.140624-6.042968 45.753906 1.675782 60.054687 19.628907 15.925781 23.097656 17.992187 53.039062 5.386719 78.105469-11.75 24.332031-36.726563 39.472656-63.738282 38.628906zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m269.742188 430.933594c-5.183594-.019532-10.355469-.554688-15.433594-1.597656-23.097656-3.535157-39.527344-24.324219-37.632813-47.613282 1.851563-18.671875 17.375-37.546875 47.871094-37.546875 11.78125 0 21.332031 9.550781 21.332031 21.332031s-9.550781 21.335938-21.332031 21.335938c-4.710937 0-8.53125-3.824219-8.53125-8.535156 0-4.714844 3.820313-8.535156 8.53125-8.535156 2.355469 0 4.265625-1.910157 4.265625-4.265626 0-2.355468-1.910156-4.265624-4.265625-4.265624-21.152344 0-29.867187 11.46875-30.890625 22.1875-.878906 14.535156 9.628906 27.273437 24.0625 29.183593 15.765625 4.65625 32.785156-.597656 43.179688-13.328125 11.917968-17.953125 13.296874-40.914062 3.609374-60.160156-8.859374-18.550781-27.957031-30.003906-48.492187-29.082031h-213.335937c-4.710938 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820312-8.53125 8.53125-8.53125h213.335937c27.015625-.859374 52.003906 14.265626 63.769531 38.601563 12.605469 25.066406 10.539063 55.011719-5.386718 78.109375-10.601563 13.847656-27.21875 21.753906-44.65625 21.246094zm0 0"
                                                                fill="#bdc3c7"></path>
                                                            <path
                                                                d="m375.480469 224.820312h-213.332031c-4.714844 0-8.535157-3.820312-8.535157-8.535156 0-4.710937 3.820313-8.53125 8.535157-8.53125h213.332031c20.523437.90625 39.605469-10.542968 48.460937-29.082031 9.683594-19.246094 8.308594-42.210937-3.609375-60.160156-9.566406-12.460938-24.535156-17.066407-43.179687-13.320313-14.417969 1.914063-24.90625 14.644532-24.03125 29.167969 1.070312 10.726563 9.738281 22.183594 30.894531 22.183594 2.355469 0 4.265625-1.910157 4.265625-4.265625 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535156-3.820313-8.535156-8.535157 0-4.710937 3.820312-8.53125 8.535156-8.53125 11.78125 0 21.332031 9.550782 21.332031 21.332032s-9.550781 21.332031-21.332031 21.332031c-30.5 0-46.023437-18.875-47.875-37.546875-1.890625-23.285156 14.539063-44.070312 37.632813-47.605469 22.144531-6.046875 45.757812 1.671875 60.058593 19.625 15.925781 23.097657 17.988281 53.042969 5.382813 78.105469-11.75 24.335938-36.722656 39.472656-63.734375 38.632812zm0 0"
                                                                fill="#7f8c8d"></path>
                                                            <path
                                                                d="m426.875 453.785156c-7.640625-.035156-15.261719-.824218-22.75-2.355468-33.242188-4.996094-56.972656-34.835938-54.355469-68.351563 2.457031-24.796875 22.050781-53.757813 69.1875-53.757813 15.414063.265626 27.773438 12.839844 27.773438 28.257813s-12.359375 27.988281-27.773438 28.257813c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125 6.0625-.167969 10.886719-5.128906 10.886719-11.191406s-4.824219-11.027344-10.886719-11.191406c-35.6875 0-50.347656 19.820312-52.199219 38.398437-1.625 24.75 16.152344 46.546875 40.722657 49.9375 38.664062 7.722656 60.015625-7.824218 71.097656-22.246094 19.414063-25.257812 21.898437-65.382812 6.042969-97.570312-14.375-30.1875-45.410156-48.851562-78.8125-47.394531h-397.261719c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125h397.261719c39.863281-1.292968 76.75 21.015626 94.121094 56.917969 18.628906 37.792969 15.410156 85.332031-7.816407 115.515625-15.507812 20.191406-39.785156 31.695313-65.238281 30.90625zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m40.34375 434.203125c-3.925781-.128906.757812-21.335937 1.234375-23.628906 2.132813-12.183594 8.472656-23.234375 17.921875-31.214844 26.070312-21.597656 62.0625-20.363281 93.867188-18.484375 2.175781.128906 16.492187 1.28125 17.3125-.621094-14.652344 33.890625-31.324219 75.226563-61.933594 97.890625-8.882813 6.957031-20.480469 9.414063-31.421875 6.65625-7.925781-2.652343-14.917969-7.535156-20.136719-14.0625-4.667969-5.144531-8.765625-16.28125-16.84375-16.535156zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m8.546875 465.988281c-3.59375.003907-6.808594-2.242187-8.035156-5.621093-1.226563-3.378907-.203125-7.164063 2.558593-9.464844 2.164063-1.765625 53.382813-44.160156 97.636719-53.019532 4.585938-.847656 9.007813 2.144532 9.921875 6.71875.914063 4.574219-2.015625 9.035157-6.578125 10.015626-40.035156 8.066406-89.53125 49.023437-90.035156 49.390624-1.535156 1.28125-3.46875 1.980469-5.46875 1.980469zm0 0"
                                                                fill="#805333"></path>
                                                            <path
                                                                d="m132.648438 105.386719c.953124 3.796875-20.753907 5.121093-23.042969 5.324219-12.296875 1.316406-24.664063-1.730469-34.941407-8.609376-27.964843-19.105468-36.710937-54.03125-43.683593-85.128906-.476563-2.132812-3.308594-16.214844-5.367188-16.476562 36.625 4.734375 80.949219 9.386718 111.179688 32.535156 9.136719 6.617188 14.699219 17.085938 15.0625 28.359375-.355469 8.359375-3.113281 16.441406-7.945313 23.277344-3.675781 5.863281-13.242187 12.894531-11.261718 20.71875zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m163.75 138.0625c-1.703125.003906-3.371094-.511719-4.777344-1.476562-2.328125-1.578126-57.171875-39.125-77.890625-79.242188-2.164062-4.1875-.523437-9.335938 3.664063-11.5 4.1875-2.160156 9.335937-.519531 11.496094 3.667969 18.773437 36.25 71.785156 72.601562 72.320312 72.957031 3.101562 2.109375 4.464844 5.992188 3.359375 9.574219s-4.421875 6.023437-8.171875 6.019531zm0 0"
                                                                fill="#805333"></path>
                                                            <g fill="#cb8252">
                                                                <path
                                                                    d="m366.945312 28.441406c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714843 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820313-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m486.414062 11.375c0 4.714844-3.820312 8.535156-8.535156 8.535156-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125 4.714844 0 8.535156 3.820312 8.535156 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m460.8125 207.640625c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m366.945312 310.042969c0 4.710937-3.820312 8.53125-8.53125 8.53125-4.714843 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820313-8.535157 8.535156-8.535157 4.710938 0 8.53125 3.820313 8.53125 8.535157zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m324.28125 455.109375c0 4.710937-3.820312 8.53125-8.535156 8.53125-4.710938 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820312-8.535156 8.53125-8.535156 4.714844 0 8.535156 3.820312 8.535156 8.535156zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m187.746094 412.441406c0 4.714844-3.820313 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710937 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m93.878906 224.707031h-85.332031c-4.710937 0-8.53125-3.820312-8.53125-8.53125 0-4.714843 3.820313-8.535156 8.53125-8.535156h85.332031c4.714844 0 8.535156 3.820313 8.535156 8.535156 0 4.710938-3.820312 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#bdc3c7"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Velocidad del<br> viento</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{obtenerDireccionViento(cmm1LastData.windDir)}}</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-4 0 511 511.99978" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m51.703125 51.097656h17.066406v51.203125h-17.066406zm0 0"
                                                                fill="#5d9aa4"></path>
                                                            <path
                                                                d="m43.167969 272.976562h34.136719v230.414063h-34.136719zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m145.574219 42.5625h-136.539063c-4.714844 0-8.535156-3.820312-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h136.539063c4.714843 0 8.535156 3.820312 8.535156 8.535156 0 4.710938-3.820313 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m128.507812 68.164062c-4.714843 0-8.535156-3.820312-8.53125-8.535156 0-2.261718.898438-4.429687 2.496094-6.03125l19.570313-19.566406-19.570313-19.570312c-3.273437-3.386719-3.179687-8.792969.210938-12.066407 3.308594-3.191406 8.550781-3.191406 11.855468 0l25.601563 25.601563c3.332031 3.332031 3.332031 8.734375 0 12.066406l-25.601563 25.601562c-1.597656 1.601563-3.769531 2.5-6.03125 2.5zm0 0"
                                                                fill="#b9b9b9"></path>
                                                            <path
                                                                d="m34.636719 76.699219h51.203125v213.347656h-51.203125zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m34.636719 162.039062c-4.714844 0-8.535157-3.820312-8.535157-8.535156v-42.667968c0-4.714844 3.820313-8.535157 8.535157-8.535157h128.007812c4.710938.011719 8.523438 3.839844 8.511719 8.554688-.007812 3.660156-2.347656 6.90625-5.816406 8.070312l-128.007813 42.667969c-.867187.296875-1.78125.445312-2.695312.445312zm8.53125-42.671874v22.292968l66.890625-22.292968zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m162.644531 272.976562h-128.007812c-4.714844 0-8.535157-3.820312-8.535157-8.53125v-42.671874c.003907-2.742188 1.324219-5.316407 3.550782-6.917969 2.214844-1.621094 5.082031-2.058594 7.679687-1.171875l128.007813 42.671875c4.46875 1.5 6.875 6.335937 5.375 10.804687-1.164063 3.46875-4.414063 5.808594-8.070313 5.816406zm-119.476562-17.066406h66.890625l-66.890625-22.28125zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m85.839844 34.03125c0 14.140625-11.464844 25.601562-25.601563 25.601562-14.140625 0-25.601562-11.460937-25.601562-25.601562s11.460937-25.601562 25.601562-25.601562c14.136719 0 25.601563 11.460937 25.601563 25.601562zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m290.652344 298.578125c-14.464844.015625-27.355469 9.128906-32.191406 22.761719-1.570313 4.445312.761718 9.320312 5.207031 10.890625 4.441406 1.566406 9.316406-.761719 10.886719-5.207031 3.144531-8.886719 12.898437-13.542969 21.785156-10.398438 8.882812 3.144531 13.539062 12.894531 10.398437 21.78125-2.414062 6.816406-8.859375 11.375-16.085937 11.375h-42.671875c-4.710938 0-8.53125 3.824219-8.53125 8.535156 0 4.714844 3.820312 8.535156 8.53125 8.535156h42.671875c18.851562 0 34.132812-15.285156 34.132812-34.136718 0-18.851563-15.28125-34.136719-34.132812-34.136719zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m444.257812 400.984375h-93.871093c-4.710938 0-8.535157-3.820313-8.535157-8.53125 0-4.714844 3.824219-8.535156 8.535157-8.535156h93.871093c4.714844 0 8.535157 3.820312 8.535157 8.535156 0 4.710937-3.820313 8.53125-8.535157 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m316.253906 400.984375h-25.601562c-4.714844 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h25.601562c4.710938 0 8.53125 3.820312 8.53125 8.535156 0 4.710937-3.820312 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m256.515625 400.984375h-85.339844c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h85.339844c4.710937 0 8.53125 3.820312 8.53125 8.535156.003906 4.710937-3.820313 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m205.3125 435.121094h-34.136719c-4.710937 0-8.53125-3.820313-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125h34.136719c4.710938 0 8.53125 3.820312 8.53125 8.53125.003906 4.714843-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m213.847656 366.851562h-42.671875c-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710937 3.820313-8.535156 8.53125-8.535156h42.671875c4.710938 0 8.53125 3.824219 8.53125 8.535156 0 4.714844-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m273.582031 435.121094h-34.132812c-4.714844 0-8.535157-3.820313-8.535157-8.535156 0-4.710938 3.820313-8.53125 8.535157-8.53125h34.132812c4.714844 0 8.535157 3.820312 8.535157 8.53125 0 4.714843-3.820313 8.535156-8.535157 8.535156zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m407.042969 418.113281c-.101563 0-.195313-.058593-.296875-.058593h-90.492188c-4.714844 0-8.535156 3.820312-8.535156 8.53125 0 4.714843 3.820312 8.535156 8.535156 8.535156h88.222656.167969 1.210938c16.496093.003906 29.867187 13.378906 29.863281 29.875s-13.378906 29.867187-29.875 29.863281c-15.207031-.003906-27.980469-11.429687-29.675781-26.542969-.519531-4.683594-4.738281-8.0625-9.421875-7.542968-4.683594.519531-8.0625 4.738281-7.542969 9.421874 2.835937 25.765626 26.023437 44.355469 51.789063 41.519532 25.765624-2.835938 44.355468-26.023438 41.519531-51.789063-2.570313-23.34375-21.992188-41.195312-45.46875-41.789062zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m503.996094 230.308594-256.015625 39.828125-85.335938 2.839843v-170.675781l85.335938 2.84375 256.015625 39.824219zm0 0"
                                                                fill="#ea6b65"></path>
                                                            <g fill="#d9d9d9">
                                                                <path
                                                                    d="m418.65625 243.585938 85.339844-13.277344v-85.339844l-85.339844-13.277344zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m333.320312 256.859375v-138.4375l-85.339843-13.277344v164.992188zm0 0">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Dirección del<br> viento</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="cmm2" class="col-xl-4"> <!-- -->
                <div class="container py-5 h-100">

                    <div class="row d-flex justify-content-center align-items-center h-100" style="color: #282828;">
                        <div class="col-xl-11 bgc fullh" *ngIf="cmm2Loading" style="min-height: 55vh;">
                            <mat-progress-bar mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                        <div class="col-xl-11 bgc fullh" *ngIf="!cmm2Loading">

                            <div class="card mb-4 gradient-custom" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo1" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <h2 class="display-2"><strong>{{cmm2Name}}</strong></h2>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">calendar_today</mat-icon>
                                                                {{getDia(cmm2LastData.formattedDateTime)}}</strong></span>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">access_time</mat-icon>
                                                                {{getHora(cmm2LastData.formattedDateTime)}}</strong></span>
                                                        <br>
                                                        <button mat-button (click)="isOpenCmmData(2)" class="mt-5 pt-1">
                                                            <i class="fa-solid fa-clock-rotate-left fa-2x text-muted historial-i"
                                                                style="color: #ddd;"></i></button>
                                                    </div>
                                                    <div>
                                                        <div class="ml-4">
                                                            <div class="mat-elevation-z2" style="border-radius: 20px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="120"
                                                                    height="120"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    version="1.1" x="0px" y="0px" viewBox="0 0 512 512"
                                                                    enable-background="new 0 0 512 512"
                                                                    xml:space="preserve">
                                                                    <g id="Ebene_3" display="none">
                                                                        <rect x="-2020" y="-2565" display="inline"
                                                                            fill="#4D4D4E" width="5364" height="3336" />
                                                                    </g>
                                                                    <g id="Grid" display="none">
                                                                        <g display="inline">
                                                                            <g>
                                                                                <path fill="#B3B3B3"
                                                                                    d="M511,1v510H1V1H511 M512,0H0v512h512V0L512,0z" />
                                                                            </g>
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="6" y1="6"
                                                                                x2="506" y2="506" />
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="506" y1="6"
                                                                                x2="6" y2="506" />
                                                                            <circle fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" cx="256" cy="256"
                                                                                r="250" />
                                                                        </g>
                                                                    </g>
                                                                    <g id="Ebene_2">
                                                                        <circle fill="#84abc1" cx="353.935" cy="183.958"
                                                                            r="21.065" />
                                                                        <circle fill="#84abc1" cx="428.935" cy="183.958"
                                                                            r="21.065" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="87.37" x2="255.997" y2="471.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="256.003"
                                                                            y1="331.37" x2="316.874" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="316.874"
                                                                            y1="236.37" x2="390.997" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="390.997"
                                                                            y1="236.37" x2="390.997" y2="198.037" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="361.371" x2="165.871" y2="400.372" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="165.871"
                                                                            y1="400.372" x2="165.871" y2="354.371" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="206.871" y2="324.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="206.871"
                                                                            y1="324.368" x2="206.871" y2="298.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="124.871" y2="298.368" />

                                                                        <polygon fill="none" stroke="#7fed6f"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="   184.997,165.621 154.997,182.364 126.692,165.621 126.692,86.624 184.997,86.624  " />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="202.302"
                                                                            y1="118.364" x2="255.997" y2="172.059" />
                                                                    </g>
                                                                    <g id="txt">
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <br>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card mb-4" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo2" class="">
                                        <!-- Indicators -->
                                        <div>
                                            <div>
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm2LastData.outTemp}}°C</strong>
                                                        </p>
                                                        <svg style="margin-right: 20px" height="60px"
                                                            viewBox="-42 0 512 512" width="60px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m84.144531 38.53125c4.394531 19.445312 27.988281 27.109375 42.96875 13.960938 2.53125-2.222657 6.324219.53125 4.992188 3.625-7.878907 18.3125 6.703125 38.382812 26.554687 36.550781 3.355469-.308594 4.804688 4.144531 1.90625 5.867187-17.136718 10.1875-17.136718 34.996094 0 45.179688 2.898438 1.722656 1.449219 6.175781-1.90625 5.867187-19.851562-1.832031-34.433594 18.238281-26.554687 36.550781 1.332031 3.097657-2.460938 5.847657-4.992188 3.625-14.980469-13.148437-38.578125-5.480468-42.96875 13.960938-.742187 3.289062-5.425781 3.289062-6.167969 0-4.390624-19.445312-27.984374-27.109375-42.96875-13.960938-2.53125 2.222657-6.324218-.527343-4.992187-3.625 7.878906-18.3125-6.703125-38.382812-26.554687-36.550781-3.355469.308594-4.804688-4.144531-1.90625-5.867187 17.136718-10.183594 17.136718-34.992188 0-45.179688-2.894532-1.722656-1.449219-6.175781 1.90625-5.867187 19.851562 1.832031 34.433593-18.238281 26.554687-36.550781-1.332031-3.09375 2.460937-5.847657 4.992187-3.625 14.984376 13.148437 38.578126 5.484374 42.96875-13.960938.742188-3.289062 5.425782-3.289062 6.167969 0zm0 0"
                                                                fill="#fd8f31"></path>
                                                            <path
                                                                d="m160.566406 143.714844c-17.136718-10.183594-17.136718-34.992188 0-45.179688 2.898438-1.71875 1.449219-6.175781-1.90625-5.863281-19.847656 1.832031-34.429687-18.242187-26.554687-36.554687 1.332031-3.09375-2.457031-5.847657-4.992188-3.625-14.980469 13.152343-38.574219 5.484374-42.96875-13.960938-.742187-3.289062-5.425781-3.289062-6.167969 0-1.625 7.191406-5.875 12.761719-11.34375 16.277344 19.398438 15.589844 31.824219 39.496094 31.824219 66.320312 0 26.820313-12.425781 50.730469-31.824219 66.320313 5.46875 3.511719 9.71875 9.085937 11.34375 16.273437.742188 3.289063 5.429688 3.289063 6.167969 0 4.394531-19.445312 27.988281-27.109375 42.96875-13.960937 2.535157 2.222656 6.324219-.527344 4.992188-3.625-7.875-18.3125 6.707031-38.382813 26.554687-36.550781 3.355469.308593 4.804688-4.148438 1.90625-5.871094zm0 0"
                                                                fill="#ff7f1f"></path>
                                                            <path
                                                                d="m115.933594 121.125c0 19.261719-15.613282 34.871094-34.875 34.871094-19.257813 0-34.871094-15.609375-34.871094-34.871094 0-19.257812 15.613281-34.871094 34.871094-34.871094 19.261718 0 34.875 15.613282 34.875 34.871094zm0 0"
                                                                fill="#fccf3f"></path>
                                                            <path
                                                                d="m334.011719 315.355469v-253.195313c0-34.332031-27.832031-62.160156-62.160157-62.160156-34.332031 0-62.160156 27.828125-62.160156 62.160156v253.195313c-27.8125 19.566406-45.984375 51.910156-45.984375 88.5 0 59.726562 48.417969 108.144531 108.144531 108.144531 59.726563 0 108.144532-48.417969 108.144532-108.144531 0-36.589844-18.175782-68.933594-45.984375-88.5zm0 0"
                                                                fill="#d8d8d8"></path>
                                                            <path
                                                                d="m271.851562 476.582031c-41.183593 0-74.6875-33.507812-74.6875-74.6875 0-31.273437 19.433594-59.117187 48.59375-69.980469v-269.753906c0-14.390625 11.703126-26.09375 26.09375-26.09375 14.386719 0 26.09375 11.703125 26.09375 26.09375v269.753906c29.15625 10.859376 48.59375 38.703126 48.59375 69.976563 0 41.183594-33.503906 74.691406-74.6875 74.691406zm0 0"
                                                                fill="#f3e8d7"></path>
                                                            <path
                                                                d="m245.757812 109.871094v222.042968c-29.160156 10.859376-48.59375 38.703126-48.59375 69.976563 0 41.183594 33.503907 74.6875 74.6875 74.6875 41.183594 0 74.6875-33.503906 74.6875-74.6875 0-31.273437-19.4375-59.117187-48.59375-69.976563v-222.042968zm0 0"
                                                                fill="#fc4e51"></path>
                                                            <g fill="#0d6e9a">
                                                                <path
                                                                    d="m420.105469 180.21875h-44.824219c-4.269531 0-7.726562-3.460938-7.726562-7.726562 0-4.265626 3.457031-7.726563 7.726562-7.726563h44.824219c4.265625 0 7.726562 3.460937 7.726562 7.726563 0 4.265624-3.460937 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 117.597656h-20.351562c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726563 7.726562-7.726563h20.351562c4.265626 0 7.726563 3.460938 7.726563 7.726563s-3.460937 7.726562-7.726563 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 54.976562h-27.5625c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726562 7.726562-7.726562h27.5625c4.269531 0 7.726562 3.460937 7.726562 7.726562s-3.457031 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 305.460938h-27.5625c-4.269531 0-7.726562-3.460938-7.726562-7.726563 0-4.269531 3.457031-7.726563 7.726562-7.726563h27.5625c4.269531 0 7.726562 3.457032 7.726562 7.726563 0 4.265625-3.457031 7.726563-7.726562 7.726563zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 242.839844h-20.351562c-4.269531 0-7.726562-3.460938-7.726562-7.726563s3.457031-7.726562 7.726562-7.726562h20.351562c4.265626 0 7.726563 3.460937 7.726563 7.726562s-3.460937 7.726563-7.726563 7.726563zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m297.945312 331.914062v-222.042968h-52.1875v222.042968s36.074219 16.738282 36.074219 69.976563c0 29.601563-17.308593 55.234375-42.335937 67.3125 9.792968 4.726563 20.769531 7.375 32.351562 7.375 41.183594 0 74.691406-33.503906 74.691406-74.6875 0-31.273437-19.4375-59.113281-48.59375-69.976563zm0 0"
                                                                fill="#e5394b"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Temperatura</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm2LastData.outHumidity}}%</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-38 0 420 420.192" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m344.425781 175.695312c.210938 31.570313-16.507812 60.835938-43.816406 76.679688-27.304687 15.847656-61.007813 15.847656-88.3125 0-27.304687-15.84375-44.027344-45.109375-43.8125-76.679688 0-48.582031 87.972656-175.6874995 87.972656-175.6874995s87.96875 127.0976565 87.96875 175.6874995zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m256.453125 0s-5.3125 7.695312-13.3125 19.960938c24.585937 37.734374 74.660156 119.066406 74.660156 155.734374-.011719 43.441407-31.722656 80.375-74.660156 86.960938 25.355469 3.859375 51.136719-3.527344 70.597656-20.238281 19.460938-16.707031 30.664063-41.074219 30.6875-66.722657 0-48.589843-87.972656-175.695312-87.972656-175.695312zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m214.261719 66.601562c-22.570313 38.511719-45.761719 84.136719-45.761719 109.085938.003906 32.640625 18.074219 62.59375 46.949219 77.8125s63.800781 13.199219 90.726562-5.246094c-15.175781-52.742187-58.601562-128.222656-91.914062-181.652344zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m279.007812 280.734375c0 77.019531-62.4375 139.457031-139.457031 139.457031s-139.4531248-62.4375-139.4531248-139.457031 139.4531248-278.511719 139.4531248-278.511719 139.457031 201.472656 139.457031 278.511719zm0 0"
                                                                fill="#26a3b9"></path>
                                                            <g fill="#168aa5">
                                                                <path
                                                                    d="m227.484375 187.15625 19.304687 19.308594-156.308593 156.308594-19.308594-19.308594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m126.574219 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m229.730469 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m139.550781 2.222656s-7.769531 11.230469-19.636719 29.390625c38.402344 58.796875 119.820313 190.277344 119.820313 249.121094-.007813 69.425781-51.082031 128.273437-119.8125 138.054687 40.011719 5.71875 80.535156-6.214843 111.0625-32.703124 30.527344-26.492188 48.050781-64.929688 48.023437-105.351563 0-77.039063-139.457031-278.511719-139.457031-278.511719zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m208.058594 187.15625 19.304687 19.304688-156.308593 156.3125-19.308594-19.308594zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m107.148438 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m210.304688 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Humedad</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm2LastData.pressure}}hPa</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 0 512 512" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m256 512c-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.636719-74.980469-181.019531s26.628906-132.667969 74.980469-181.019531c48.351562-48.351563 112.640625-74.980469 181.019531-74.980469s132.667969 26.628906 181.019531 74.980469c48.351563 48.351562 74.980469 112.636719 74.980469 181.019531s-26.628906 132.667969-74.980469 181.019531c-48.351562 48.351563-112.640625 74.980469-181.019531 74.980469zm0-478.609375c-122.746094 0-222.609375 99.863281-222.609375 222.609375s99.863281 222.609375 222.609375 222.609375 222.609375-99.863281 222.609375-222.609375-99.863281-222.609375-222.609375-222.609375zm0 0"
                                                                fill="#736056"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-36.414063-36.414063-84.097656-54.839844-131.929687-55.308594-.613282-.070312-1.234376-.113281-1.867188-.113281s-1.253906.042969-1.867188.113281c-47.828124.46875-95.507812 18.894531-131.921874 55.300781-.003907.003907-.003907.003907-.007813.007813s-.003906.003906-.007813.007813c-36.410156 36.414062-54.832031 84.09375-55.300781 131.921874-.070312.613282-.113281 1.234376-.113281 1.867188s.042969 1.253906.113281 1.867188c.46875 47.832031 18.894531 95.515624 55.308594 131.929687 3.261719 3.261719 7.53125 4.890625 11.804687 4.890625 4.273438 0 8.546876-1.628906 11.804688-4.890625l23.613281-23.609375c6.519531-6.519531 6.519531-17.089844 0-23.613281-6.523437-6.519531-17.09375-6.519531-23.613281 0l-11.175781 11.175781c-19.242188-23.847656-30.410157-52.097656-33.503907-81.054688h15.738282c9.21875 0 16.695312-7.476562 16.695312-16.695312s-7.476562-16.695312-16.695312-16.695312h-15.726563c3.09375-28.953126 14.261719-57.199219 33.5-81.050782l11.171875 11.171875c3.257813 3.257813 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.546875-1.632812 11.804687-4.890625 6.519531-6.519531 6.519531-17.09375 0-23.613281l-11.171875-11.171875c23.851563-19.238281 52.097656-30.402344 81.050782-33.492187v15.722656c0 9.21875 7.476562 16.695312 16.695312 16.695312 9.222656 0 16.695312-7.476562 16.695312-16.695312v-15.722656c34.171876 3.648437 67.355469 18.53125 93.492188 44.664062 6.519531 6.519531 17.089844 6.519531 23.609375 0s6.519531-17.089844 0-23.609375zm0 0"
                                                                fill="#88cc2a"></path>
                                                            <path
                                                                d="m445.21875 256c0-.632812-.042969-1.253906-.113281-1.867188-.46875-47.832031-18.894531-95.515624-55.308594-131.929687-6.519531-6.519531-17.089844-6.519531-23.609375 0-6.523438 6.519531-6.523438 17.089844 0 23.609375 26.132812 26.136719 41.015625 59.324219 44.667969 93.492188h-15.726563c-9.21875 0-16.695312 7.476562-16.695312 16.695312s7.476562 16.695312 16.695312 16.695312h15.734375c-3.09375 28.957032-14.257812 57.207032-33.5 81.054688l-11.175781-11.175781c-6.519531-6.519531-17.09375-6.519531-23.613281 0s-6.519531 17.089843 0 23.613281l23.613281 23.609375c3.257812 3.261719 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.542968-1.628906 11.804687-4.890625 36.414063-36.414063 54.839844-84.097656 55.308594-131.929687.070312-.613282.113281-1.234376.113281-1.867188zm0 0"
                                                                fill="#ff9f19"></path>
                                                            <path
                                                                d="m272.695312 445.21875h-33.390624c-27.617188 0-50.085938-22.46875-50.085938-50.089844 0-27.617187 22.46875-50.085937 50.085938-50.085937h33.390624c27.617188 0 50.085938 22.46875 50.085938 50.085937 0 27.621094-22.46875 50.089844-50.085938 50.089844zm-33.390624-66.785156c-9.207032 0-16.695313 7.492187-16.695313 16.695312 0 9.207032 7.488281 16.695313 16.695313 16.695313h33.390624c9.207032 0 16.695313-7.488281 16.695313-16.695313 0-9.203125-7.488281-16.695312-16.695313-16.695312zm0 0"
                                                                fill="#bddeff"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-6.519531-6.519531-17.089844-6.519531-23.609375 0l-84.46875 84.46875c-7.699219-4.03125-16.445312-6.324219-25.71875-6.324219-30.6875 0-55.652344 24.964844-55.652344 55.652344s24.964844 55.652344 55.652344 55.652344 55.652344-24.964844 55.652344-55.652344c0-9.273438-2.292969-18.019531-6.324219-25.71875l84.46875-84.46875c6.519531-6.519531 6.519531-17.089844 0-23.609375zm-133.796875 156.058594c-12.273438 0-22.261719-9.988281-22.261719-22.261719s9.988281-22.261719 22.261719-22.261719 22.261719 9.988281 22.261719 22.261719-9.988281 22.261719-22.261719 22.261719zm0 0"
                                                                fill="#e6563a"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Presión</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm2LastData.rain}}mm</strong></p>
                                                        <svg height="45.541px" viewBox="0 -7 512 512" width="47.542px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m21.316406 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.0117188 1.125.03125 1.683594 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578124-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m21.316406 457.257812c-10.378906 0-20.753906-6.703124-21.2734372-20.097656-.0234376-.5625-.0429688-1.125-.0429688-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.570312 7.371094-4.292968 21.140625-4.292968 36.914062 0 .558594 0 1.121094.007812 1.683594.105469 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 438.421875c-12.742187 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574218-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 329.023438c-12.742187 7.371093-21.316406 21.136718-21.316406 36.914062 0 .5625.011719 1.125.03125 1.679688 1.039062 26.800781 41.527344 26.800781 42.566406 0 .023438-.554688.03125-1.117188.03125-1.679688.003906-15.777344-8.574218-29.542969-21.3125-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 387.71875c-10.378906 0-20.757812-6.707031-21.277344-20.101562-.019531-.558594-.039062-1.121094-.039062-1.683594 0-15.773438 8.578125-29.539063 21.316406-36.914063-2.570312 7.375-4.296875 21.140625-4.296875 36.914063 0 .5625 0 1.125.011719 1.683594.105469 13.394531 2.195312 20.101562 4.285156 20.101562zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m208.1875 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m208.1875 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m301.625 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.007812 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574219-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m301.625 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105468 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m489.035156 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.035156-1.117187.035156-1.683593 0-15.777344-8.578124-29.542969-21.316406-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m489.035156 497.117188c-10.378906 0-20.753906-6.703126-21.273437-20.097657-.023438-.5625-.042969-1.125-.042969-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m395.058594 398.5625c-12.738282 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527343 26.796875 42.566406 0 .023437-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m395.058594 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578124-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m408.25 181.570312c0 37.824219-30.664062 68.492188-68.503906 68.492188h-247.886719c-50.296875 0-91.082031-40.773438-91.082031-91.082031 0-50.296875 40.78125-91.082031 91.082031-91.082031 4.511719 0 8.945313.347656 13.292969.976562 20.570312-40.84375 62.871094-68.875 111.742187-68.875 59.128907 0 108.667969 41.054688 121.679688 96.210938 1.300781 5.503906 2.238281 11.144531 2.777343 16.894531v.011719c37.082032.851562 66.898438 31.167968 66.898438 68.453124zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m260.351562 7.769531c-13.539062-5.027343-28.183593-7.76562475-43.457031-7.76562475-48.871093 0-91.160156 28.03124975-111.742187 68.86328175v.011718c-4.347656-.628906-8.78125-.976562-13.292969-.976562-50.3125 0-91.082031 40.785156-91.082031 91.082031 0 50.3125 40.769531 91.082031 91.082031 91.082031h44.019531c-45.332031 0-82.066406-36.746094-82.066406-82.070312 0-40.214844 28.929688-73.683594 67.128906-80.703125 3.777344-.707031 7.648438-1.144531 11.597656-1.300781 1.105469-.035157 2.222657-.0625 3.339844-.0625 14.734375 0 28.570313 3.882812 40.515625 10.699218-1.660156-11.5625-13.613281-22.394531-31.238281-31.125 9.679688-32.726562 42.613281-63.078125 115.195312-57.734375zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m264.6875 245.550781c.496094.089844.789062.089844.835938.089844h-3.792969c.992187 0 1.972656-.03125 2.957031-.089844zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m512 313.621094c0 30.589844-24.800781 55.390625-55.402344 55.390625h-200.476562c-40.675782 0-73.660156-32.972657-73.660156-73.660157 0-40.679687 32.984374-73.660156 73.660156-73.660156 3.648437 0 7.238281.28125 10.75.789063 16.640625-33.035157 50.847656-55.703125 90.371094-55.703125 47.824218 0 87.886718 33.203125 98.410156 77.808594 1.050781 4.453124 1.808594 9.015624 2.246094 13.664062v.011719c29.988281.683593 54.101562 25.203125 54.101562 55.359375zm0 0"
                                                                fill="#d3ddea"></path>
                                                            <path
                                                                d="m392.386719 173.0625c-10.949219-4.066406-22.792969-6.28125-35.144531-6.28125-39.523438 0-73.722657 22.667969-90.371094 55.691406v.011719c-3.515625-.511719-7.101563-.789063-10.75-.789063-40.6875 0-73.660156 32.980469-73.660156 73.660157 0 40.6875 32.972656 73.660156 73.660156 73.660156h35.601562c-36.664062 0-66.371094-29.71875-66.371094-66.371094 0-32.527343 23.394532-59.59375 54.289063-65.269531 3.058594-.574219 6.1875-.925781 9.378906-1.050781.894531-.03125 1.800781-.050781 2.703125-.050781 11.917969 0 23.105469 3.140624 32.765625 8.648437-1.339843-9.351563-11.007812-18.109375-25.261719-25.167969 7.824219-26.46875 34.460938-51.015625 93.160157-46.691406zm0 0"
                                                                fill="#84abc1"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Lluvia</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo3" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm2LastData.dewpoint}}°C</strong>
                                                        </p>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                                            id="Layer_1" x="0px" y="0px" viewBox="0 0 497 497"
                                                            style="enable-background:new 0 0 497 497;"
                                                            xml:space="preserve" width="55px" height="55px">
                                                            <g>
                                                                <path style="fill:#59C2E8;"
                                                                    d="M183.391,58.325c-6.66-6.477-17.246-6.477-23.907,0C-78.787,290.047-14.684,497,171.438,497   S421.663,290.047,183.391,58.325z">
                                                                </path>
                                                                <path style="fill:#0097D1;"
                                                                    d="M201.438,477C19.441,477-45.878,279.117,174.095,53.691c-5.15-0.806-10.593,0.727-14.611,4.634   C-78.787,290.047-14.684,497,171.438,497c48.559,0,88.805-14.094,117.55-39.032C264.453,470.302,234.92,477,201.438,477z">
                                                                </path>
                                                                <g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M99.951,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5s7.5,3.358,7.5,7.5     v12.938C107.451,283.894,104.094,287.251,99.951,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M242.925,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5     s7.5,3.358,7.5,7.5v12.938C250.425,283.894,247.067,287.251,242.925,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M171.438,294.157c-9.041,0-17.53-3.78-23.291-10.371c-2.727-3.119-2.408-7.857,0.711-10.583     c3.117-2.726,7.856-2.408,10.582,0.711c2.912,3.332,7.285,5.242,11.998,5.242c4.712,0,9.085-1.911,11.997-5.242     c2.726-3.119,7.464-3.437,10.583-0.711c3.118,2.726,3.437,7.464,0.71,10.583C188.968,290.378,180.478,294.157,171.438,294.157z">
                                                                        </path>
                                                                    </g>
                                                                </g>
                                                                <path style="fill:#D5EBF6;"
                                                                    d="M420.006,262.727V50.657C420.006,22.68,397.326,0,369.35,0h0c-25.428,0-46.475,18.737-50.1,43.157   l4.08,7.5l-4.637,7.5V79.17l3.97,7.5l-3.97,7.5v21.014l4.637,7.5l-4.637,7.5v21.014l3.637,7.5l-3.637,7.5v21.013l3.637,7.5   l-3.637,7.5v60.516c-42.433,19.279-71.942,62.03-71.942,111.674C246.751,442.111,301.64,497,369.35,497   s122.599-54.889,122.599-122.599C491.948,324.757,462.439,282.006,420.006,262.727z">
                                                                </path>
                                                                <path style="fill:#FF6E80;"
                                                                    d="M369.349,462.349c-48.494,0-87.947-39.453-87.947-87.947c0-34.427,20.264-65.879,51.625-80.127   l20.318-9.23V50.656c0-8.825,7.18-16.005,16.005-16.005c8.825,0,16.006,7.18,16.006,16.005v234.388l20.318,9.231   c31.361,14.248,51.625,45.7,51.625,80.127C457.297,422.896,417.844,462.349,369.349,462.349z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,187.211v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H318.693   L318.693,187.211z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,151.198v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,151.198z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,115.184v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,115.184z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,50.657v7.5h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-34.095   C318.886,45.604,318.693,48.107,318.693,50.657z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,79.17v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,79.17z">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Punto de<br> rocío</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm2LastData.heatindex}}°C</strong>
                                                        </p>
                                                        <svg style="margin-right: 20px" height="70px"
                                                            viewBox="-78 0 512 512" width="75px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-13.621094-15.976562-21.824218-36.6875-21.824218-59.308593 0-50.550782 40.976562-91.527344 91.527343-91.527344 46.082031 0 84.203125 34.046875 90.585938 78.363281.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fab03c"></path>
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-.621094-4.300781-.9375-8.691406-.9375-13.160156 0-50.554687 40.984375-91.527344 91.527344-91.527344 27.925781 0 52.925781 12.503907 69.699219 32.214844.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <g fill="#f8e98e">
                                                                <path
                                                                    d="m150.769531 34.9375c-4.207031 0-7.613281-3.410156-7.613281-7.613281v-19.714844c0-4.203125 3.410156-7.609375 7.613281-7.609375s7.609375 3.40625 7.609375 7.609375v19.714844c0 4.203125-3.40625 7.613281-7.609375 7.613281zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m89.054688 51.476562c-2.632813 0-5.191407-1.367187-6.601563-3.808593l-9.855469-17.070313c-2.101562-3.640625-.855468-8.296875 2.785156-10.398437 3.640626-2.101563 8.296876-.855469 10.398438 2.785156l9.855469 17.074219c2.101562 3.636718.855469 8.292968-2.785157 10.394531-1.199218.691406-2.507812 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m43.855469 96.660156c-1.289063 0-2.597657-.332031-3.796875-1.023437l-17.074219-9.855469c-3.640625-2.101562-4.886719-6.757812-2.785156-10.398438 2.101562-3.640624 6.753906-4.886718 10.394531-2.785156l17.074219 9.855469c3.640625 2.105469 4.886719 6.757813 2.785156 10.398437-1.410156 2.445313-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m27.324219 158.378906h-19.714844c-4.203125 0-7.609375-3.40625-7.609375-7.609375s3.40625-7.613281 7.609375-7.613281h19.714844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m257.679688 96.660156c-2.628907 0-5.1875-1.367187-6.597657-3.808594-2.101562-3.640624-.855469-8.292968 2.785157-10.398437l17.074218-9.855469c3.636719-2.101562 8.292969-.855468 10.394532 2.785156 2.101562 3.640626.855468 8.296876-2.785157 10.398438l-17.074219 9.855469c-1.195312.691406-2.503906 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m26.796875 229.960938c-2.628906 0-5.1875-1.367188-6.597656-3.808594-2.101563-3.640625-.855469-8.296875 2.785156-10.394532l17.074219-9.859374c3.636718-2.097657 8.292968-.851563 10.394531 2.789062s.855469 8.292969-2.785156 10.394531l-17.074219 9.855469c-1.195312.695312-2.503906 1.023438-3.796875 1.023438zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m212.484375 51.476562c-1.292969 0-2.601563-.332031-3.800781-1.023437-3.640625-2.101563-4.886719-6.757813-2.785156-10.394531l9.855468-17.074219c2.101563-3.640625 6.757813-4.886719 10.398438-2.785156 3.640625 2.101562 4.886718 6.757812 2.785156 10.398437l-9.855469 17.070313c-1.410156 2.441406-3.96875 3.808593-6.597656 3.808593zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m79.183594 282.359375c-1.292969 0-2.601563-.328125-3.800782-1.019531-3.640624-2.105469-4.886718-6.757813-2.785156-10.398438l9.855469-17.074218c2.101563-3.640626 6.757813-4.886719 10.398437-2.785157 3.640626 2.101563 4.886719 6.757813 2.785157 10.398438l-9.855469 17.070312c-1.410156 2.441407-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m329.234375 240.59375c0 10.503906-8.519531 19.019531-19.023437 19.019531-10.503907 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.519531-19.023438 19.023438-19.023438 10.503906 0 19.023437 8.515626 19.023437 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m177.15625 240.59375c0 10.503906-8.515625 19.019531-19.019531 19.019531-10.507813 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.515625-19.023438 19.023438-19.023438 10.503906 0 19.019531 8.515626 19.019531 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m353.683594 439.070312v62.78125c0 5.605469-4.542969 10.148438-10.148438 10.148438h-218.722656c-5.601562 0-10.148438-4.542969-10.148438-10.148438v-62.78125c0-43.292968 23.027344-81.207031 57.492188-102.175781 18.074219-10.988281 39.308594-17.332031 62.019531-17.332031 22.5 0 43.535157 6.21875 61.5 17.03125 8.390625 5.042969 16.125 11.089844 23.007813 17.972656 21.625 21.636719 35 51.511719 35 84.503906zm0 0"
                                                                fill="#f0f5f7"></path>
                                                            <path
                                                                d="m172.503906 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.609375-7.609375 4.203125 0 7.613281 3.398437 7.613281 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m311.171875 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.613281-7.609375 4.199219 0 7.609375 3.398437 7.609375 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m343.539062 511.992188h-47.589843v-67.089844h57.738281v56.945312c0 5.601563-4.542969 10.144532-10.148438 10.144532zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m295.949219 444.90625h15.222656v67.082031h-15.222656zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m172.402344 444.90625v67.082031h-47.589844c-5.601562 0-10.148438-4.542969-10.148438-10.148437v-56.933594zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m157.28125 444.90625h15.121094v67.082031h-15.121094zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m295.679688 336.59375c-13.59375 19.570312-36.234376 32.367188-61.863282 32.367188-25.496094 0-48.042968-12.671876-61.65625-32.066407 18.070313-10.992187 39.304688-17.328125 62.015625-17.328125 22.5 0 43.535157 6.210938 61.503907 17.027344zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m164.230469 174.1875v85.160156c0 38.625 31.316406 69.941406 69.941406 69.941406 38.628906 0 69.941406-31.316406 69.941406-69.941406v-85.160156zm0 0"
                                                                fill="#fbdbb7"></path>
                                                            <path
                                                                d="m304.113281 174.1875v85.15625c0 19.3125-7.828125 36.808594-20.488281 49.460938-12.652344 12.652343-30.140625 20.480468-49.453125 20.480468-3.757813 0-7.445313-.292968-11.050781-.875 14.886718-2.355468 28.207031-9.421875 38.402344-19.605468 12.652343-12.652344 20.480468-30.148438 20.480468-49.460938v-85.15625zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m234.175781 119.171875c-44.792969 0-81.101562 36.308594-81.101562 81.101563v17.472656c0 6.152344 5.417969 10.886718 11.515625 10.054687 20.5-2.789062 62.964844-10.910156 82.292968-31.78125 7.195313 11.8125 23.039063 26.890625 57.433594 29.546875 5.90625.457032 10.957032-4.199218 10.957032-10.121094v-15.171874c0-44.792969-36.308594-81.101563-81.097657-81.101563zm0 0"
                                                                fill="#82807f"></path>
                                                            <path
                                                                d="m315.273438 200.277344v15.164062c0 5.929688-5.066407 10.578125-10.980469 10.121094-4.617188-.359375-8.902344-.941406-12.878907-1.710938v-23.574218c0-40.738282-30.03125-74.460938-69.175781-80.238282 3.898438-.578124 7.875-.871093 11.9375-.871093 44.785157 0 81.097657 36.3125 81.097657 81.109375zm0 0"
                                                                fill="#6d6d6d"></path>
                                                            <path
                                                                d="m201.347656 248.203125c-4.207031 0-7.660156-3.40625-7.660156-7.609375s3.363281-7.613281 7.570312-7.613281h.089844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m267.089844 248.203125c-4.203125 0-7.65625-3.40625-7.65625-7.609375s3.363281-7.613281 7.566406-7.613281h.089844c4.207031 0 7.613281 3.410156 7.613281 7.613281s-3.410156 7.609375-7.613281 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m241.503906 292.371094h-14.65625c-4.207031 0-7.613281-3.40625-7.613281-7.609375v-44.167969c0-4.203125 3.410156-7.613281 7.613281-7.613281s7.609375 3.410156 7.609375 7.613281v36.554688h7.046875c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.410156 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <path
                                                                d="m323.347656 172.90625c0 10.621094-8.609375 19.230469-19.230468 19.230469-10.621094 0-19.230469-8.609375-19.230469-19.230469 0-6.96875 8.28125-20.652344 13.972656-29.230469 2.496094-3.761719 8.015625-3.761719 10.511719 0 5.695312 8.578125 13.976562 22.261719 13.976562 29.230469zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m323.34375 172.90625c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.660156 0-5.199219-.542969-7.507812-1.519531 6.898437-2.921876 11.730469-9.753907 11.730469-17.710938 0-6.09375-6.328126-17.316406-11.722657-25.777344.773438-1.214844 1.527344-2.367187 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.519532-.011719 5.691406 8.582031 13.972656 22.261719 13.972656 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m356.394531 368.648438c0 10.621093-8.613281 19.230468-19.234375 19.230468s-19.230468-8.609375-19.230468-19.230468c0-6.972657 8.285156-20.652344 13.976562-29.234376 2.496094-3.761718 8.015625-3.761718 10.511719 0 5.691406 8.582032 13.976562 22.261719 13.976562 29.234376zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m356.390625 368.648438c0 10.617187-8.605469 19.230468-19.230469 19.230468-2.664062 0-5.199218-.542968-7.507812-1.519531 6.894531-2.921875 11.730468-9.753906 11.730468-17.710937 0-6.09375-6.328124-17.320313-11.722656-25.78125.769532-1.210938 1.523438-2.367188 2.238282-3.441407 2.496093-3.765625 8.019531-3.777343 10.515624-.011719 5.695313 8.582032 13.976563 22.261719 13.976563 29.234376zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.621094-8.609375 19.230469-19.230469 19.230469s-19.230469-8.609375-19.230469-19.230469c0-6.96875 8.28125-20.652344 13.976563-29.234375 2.496093-3.757813 8.015625-3.757813 10.511719 0 5.691406 8.582031 13.972656 22.265625 13.972656 29.234375zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.664063 0-5.199219-.542969-7.507813-1.519531 6.894532-2.921876 11.726563-9.753907 11.726563-17.710938 0-6.09375-6.328125-17.316406-11.71875-25.777344.769531-1.214844 1.523437-2.371094 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.515625-.011719 5.695313 8.582031 13.976563 22.261719 13.976563 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Sensación<br> térmica</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{cmm2LastData.windSpeed}}Km/h</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 -23 511.99037 511" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m238.945312 182.152344h-213.332031c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h213.332031c20.527344.90625 39.605469-10.542969 48.460938-29.082032 9.6875-19.246094 8.308594-42.207031-3.609375-60.160156-9.566406-12.449219-24.515625-17.066406-43.175781-13.320312-14.421875 1.917968-24.910156 14.648437-24.03125 29.167968 1.066406 10.726563 9.738281 22.1875 30.890625 22.1875 2.355469 0 4.265625-1.910156 4.265625-4.269531 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535157-3.820312-8.535157-8.53125 0-4.714844 3.820313-8.535156 8.535157-8.535156 11.78125 0 21.332031 9.550781 21.332031 21.332031 0 11.785156-9.550781 21.335937-21.332031 21.335937-30.5 0-46.019531-18.875-47.871094-37.546874-1.890625-23.285157 14.539063-44.070313 37.632813-47.609376 22.140624-6.042968 45.753906 1.675782 60.054687 19.628907 15.925781 23.097656 17.992187 53.039062 5.386719 78.105469-11.75 24.332031-36.726563 39.472656-63.738282 38.628906zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m269.742188 430.933594c-5.183594-.019532-10.355469-.554688-15.433594-1.597656-23.097656-3.535157-39.527344-24.324219-37.632813-47.613282 1.851563-18.671875 17.375-37.546875 47.871094-37.546875 11.78125 0 21.332031 9.550781 21.332031 21.332031s-9.550781 21.335938-21.332031 21.335938c-4.710937 0-8.53125-3.824219-8.53125-8.535156 0-4.714844 3.820313-8.535156 8.53125-8.535156 2.355469 0 4.265625-1.910157 4.265625-4.265626 0-2.355468-1.910156-4.265624-4.265625-4.265624-21.152344 0-29.867187 11.46875-30.890625 22.1875-.878906 14.535156 9.628906 27.273437 24.0625 29.183593 15.765625 4.65625 32.785156-.597656 43.179688-13.328125 11.917968-17.953125 13.296874-40.914062 3.609374-60.160156-8.859374-18.550781-27.957031-30.003906-48.492187-29.082031h-213.335937c-4.710938 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820312-8.53125 8.53125-8.53125h213.335937c27.015625-.859374 52.003906 14.265626 63.769531 38.601563 12.605469 25.066406 10.539063 55.011719-5.386718 78.109375-10.601563 13.847656-27.21875 21.753906-44.65625 21.246094zm0 0"
                                                                fill="#bdc3c7"></path>
                                                            <path
                                                                d="m375.480469 224.820312h-213.332031c-4.714844 0-8.535157-3.820312-8.535157-8.535156 0-4.710937 3.820313-8.53125 8.535157-8.53125h213.332031c20.523437.90625 39.605469-10.542968 48.460937-29.082031 9.683594-19.246094 8.308594-42.210937-3.609375-60.160156-9.566406-12.460938-24.535156-17.066407-43.179687-13.320313-14.417969 1.914063-24.90625 14.644532-24.03125 29.167969 1.070312 10.726563 9.738281 22.183594 30.894531 22.183594 2.355469 0 4.265625-1.910157 4.265625-4.265625 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535156-3.820313-8.535156-8.535157 0-4.710937 3.820312-8.53125 8.535156-8.53125 11.78125 0 21.332031 9.550782 21.332031 21.332032s-9.550781 21.332031-21.332031 21.332031c-30.5 0-46.023437-18.875-47.875-37.546875-1.890625-23.285156 14.539063-44.070312 37.632813-47.605469 22.144531-6.046875 45.757812 1.671875 60.058593 19.625 15.925781 23.097657 17.988281 53.042969 5.382813 78.105469-11.75 24.335938-36.722656 39.472656-63.734375 38.632812zm0 0"
                                                                fill="#7f8c8d"></path>
                                                            <path
                                                                d="m426.875 453.785156c-7.640625-.035156-15.261719-.824218-22.75-2.355468-33.242188-4.996094-56.972656-34.835938-54.355469-68.351563 2.457031-24.796875 22.050781-53.757813 69.1875-53.757813 15.414063.265626 27.773438 12.839844 27.773438 28.257813s-12.359375 27.988281-27.773438 28.257813c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125 6.0625-.167969 10.886719-5.128906 10.886719-11.191406s-4.824219-11.027344-10.886719-11.191406c-35.6875 0-50.347656 19.820312-52.199219 38.398437-1.625 24.75 16.152344 46.546875 40.722657 49.9375 38.664062 7.722656 60.015625-7.824218 71.097656-22.246094 19.414063-25.257812 21.898437-65.382812 6.042969-97.570312-14.375-30.1875-45.410156-48.851562-78.8125-47.394531h-397.261719c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125h397.261719c39.863281-1.292968 76.75 21.015626 94.121094 56.917969 18.628906 37.792969 15.410156 85.332031-7.816407 115.515625-15.507812 20.191406-39.785156 31.695313-65.238281 30.90625zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m40.34375 434.203125c-3.925781-.128906.757812-21.335937 1.234375-23.628906 2.132813-12.183594 8.472656-23.234375 17.921875-31.214844 26.070312-21.597656 62.0625-20.363281 93.867188-18.484375 2.175781.128906 16.492187 1.28125 17.3125-.621094-14.652344 33.890625-31.324219 75.226563-61.933594 97.890625-8.882813 6.957031-20.480469 9.414063-31.421875 6.65625-7.925781-2.652343-14.917969-7.535156-20.136719-14.0625-4.667969-5.144531-8.765625-16.28125-16.84375-16.535156zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m8.546875 465.988281c-3.59375.003907-6.808594-2.242187-8.035156-5.621093-1.226563-3.378907-.203125-7.164063 2.558593-9.464844 2.164063-1.765625 53.382813-44.160156 97.636719-53.019532 4.585938-.847656 9.007813 2.144532 9.921875 6.71875.914063 4.574219-2.015625 9.035157-6.578125 10.015626-40.035156 8.066406-89.53125 49.023437-90.035156 49.390624-1.535156 1.28125-3.46875 1.980469-5.46875 1.980469zm0 0"
                                                                fill="#805333"></path>
                                                            <path
                                                                d="m132.648438 105.386719c.953124 3.796875-20.753907 5.121093-23.042969 5.324219-12.296875 1.316406-24.664063-1.730469-34.941407-8.609376-27.964843-19.105468-36.710937-54.03125-43.683593-85.128906-.476563-2.132812-3.308594-16.214844-5.367188-16.476562 36.625 4.734375 80.949219 9.386718 111.179688 32.535156 9.136719 6.617188 14.699219 17.085938 15.0625 28.359375-.355469 8.359375-3.113281 16.441406-7.945313 23.277344-3.675781 5.863281-13.242187 12.894531-11.261718 20.71875zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m163.75 138.0625c-1.703125.003906-3.371094-.511719-4.777344-1.476562-2.328125-1.578126-57.171875-39.125-77.890625-79.242188-2.164062-4.1875-.523437-9.335938 3.664063-11.5 4.1875-2.160156 9.335937-.519531 11.496094 3.667969 18.773437 36.25 71.785156 72.601562 72.320312 72.957031 3.101562 2.109375 4.464844 5.992188 3.359375 9.574219s-4.421875 6.023437-8.171875 6.019531zm0 0"
                                                                fill="#805333"></path>
                                                            <g fill="#cb8252">
                                                                <path
                                                                    d="m366.945312 28.441406c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714843 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820313-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m486.414062 11.375c0 4.714844-3.820312 8.535156-8.535156 8.535156-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125 4.714844 0 8.535156 3.820312 8.535156 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m460.8125 207.640625c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m366.945312 310.042969c0 4.710937-3.820312 8.53125-8.53125 8.53125-4.714843 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820313-8.535157 8.535156-8.535157 4.710938 0 8.53125 3.820313 8.53125 8.535157zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m324.28125 455.109375c0 4.710937-3.820312 8.53125-8.535156 8.53125-4.710938 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820312-8.535156 8.53125-8.535156 4.714844 0 8.535156 3.820312 8.535156 8.535156zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m187.746094 412.441406c0 4.714844-3.820313 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710937 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m93.878906 224.707031h-85.332031c-4.710937 0-8.53125-3.820312-8.53125-8.53125 0-4.714843 3.820313-8.535156 8.53125-8.535156h85.332031c4.714844 0 8.535156 3.820313 8.535156 8.535156 0 4.710938-3.820312 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#bdc3c7"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Velocidad del<br> viento</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{obtenerDireccionViento(cmm2LastData.windDir)}}</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-4 0 511 511.99978" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m51.703125 51.097656h17.066406v51.203125h-17.066406zm0 0"
                                                                fill="#5d9aa4"></path>
                                                            <path
                                                                d="m43.167969 272.976562h34.136719v230.414063h-34.136719zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m145.574219 42.5625h-136.539063c-4.714844 0-8.535156-3.820312-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h136.539063c4.714843 0 8.535156 3.820312 8.535156 8.535156 0 4.710938-3.820313 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m128.507812 68.164062c-4.714843 0-8.535156-3.820312-8.53125-8.535156 0-2.261718.898438-4.429687 2.496094-6.03125l19.570313-19.566406-19.570313-19.570312c-3.273437-3.386719-3.179687-8.792969.210938-12.066407 3.308594-3.191406 8.550781-3.191406 11.855468 0l25.601563 25.601563c3.332031 3.332031 3.332031 8.734375 0 12.066406l-25.601563 25.601562c-1.597656 1.601563-3.769531 2.5-6.03125 2.5zm0 0"
                                                                fill="#b9b9b9"></path>
                                                            <path
                                                                d="m34.636719 76.699219h51.203125v213.347656h-51.203125zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m34.636719 162.039062c-4.714844 0-8.535157-3.820312-8.535157-8.535156v-42.667968c0-4.714844 3.820313-8.535157 8.535157-8.535157h128.007812c4.710938.011719 8.523438 3.839844 8.511719 8.554688-.007812 3.660156-2.347656 6.90625-5.816406 8.070312l-128.007813 42.667969c-.867187.296875-1.78125.445312-2.695312.445312zm8.53125-42.671874v22.292968l66.890625-22.292968zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m162.644531 272.976562h-128.007812c-4.714844 0-8.535157-3.820312-8.535157-8.53125v-42.671874c.003907-2.742188 1.324219-5.316407 3.550782-6.917969 2.214844-1.621094 5.082031-2.058594 7.679687-1.171875l128.007813 42.671875c4.46875 1.5 6.875 6.335937 5.375 10.804687-1.164063 3.46875-4.414063 5.808594-8.070313 5.816406zm-119.476562-17.066406h66.890625l-66.890625-22.28125zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m85.839844 34.03125c0 14.140625-11.464844 25.601562-25.601563 25.601562-14.140625 0-25.601562-11.460937-25.601562-25.601562s11.460937-25.601562 25.601562-25.601562c14.136719 0 25.601563 11.460937 25.601563 25.601562zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m290.652344 298.578125c-14.464844.015625-27.355469 9.128906-32.191406 22.761719-1.570313 4.445312.761718 9.320312 5.207031 10.890625 4.441406 1.566406 9.316406-.761719 10.886719-5.207031 3.144531-8.886719 12.898437-13.542969 21.785156-10.398438 8.882812 3.144531 13.539062 12.894531 10.398437 21.78125-2.414062 6.816406-8.859375 11.375-16.085937 11.375h-42.671875c-4.710938 0-8.53125 3.824219-8.53125 8.535156 0 4.714844 3.820312 8.535156 8.53125 8.535156h42.671875c18.851562 0 34.132812-15.285156 34.132812-34.136718 0-18.851563-15.28125-34.136719-34.132812-34.136719zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m444.257812 400.984375h-93.871093c-4.710938 0-8.535157-3.820313-8.535157-8.53125 0-4.714844 3.824219-8.535156 8.535157-8.535156h93.871093c4.714844 0 8.535157 3.820312 8.535157 8.535156 0 4.710937-3.820313 8.53125-8.535157 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m316.253906 400.984375h-25.601562c-4.714844 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h25.601562c4.710938 0 8.53125 3.820312 8.53125 8.535156 0 4.710937-3.820312 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m256.515625 400.984375h-85.339844c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h85.339844c4.710937 0 8.53125 3.820312 8.53125 8.535156.003906 4.710937-3.820313 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m205.3125 435.121094h-34.136719c-4.710937 0-8.53125-3.820313-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125h34.136719c4.710938 0 8.53125 3.820312 8.53125 8.53125.003906 4.714843-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m213.847656 366.851562h-42.671875c-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710937 3.820313-8.535156 8.53125-8.535156h42.671875c4.710938 0 8.53125 3.824219 8.53125 8.535156 0 4.714844-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m273.582031 435.121094h-34.132812c-4.714844 0-8.535157-3.820313-8.535157-8.535156 0-4.710938 3.820313-8.53125 8.535157-8.53125h34.132812c4.714844 0 8.535157 3.820312 8.535157 8.53125 0 4.714843-3.820313 8.535156-8.535157 8.535156zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m407.042969 418.113281c-.101563 0-.195313-.058593-.296875-.058593h-90.492188c-4.714844 0-8.535156 3.820312-8.535156 8.53125 0 4.714843 3.820312 8.535156 8.535156 8.535156h88.222656.167969 1.210938c16.496093.003906 29.867187 13.378906 29.863281 29.875s-13.378906 29.867187-29.875 29.863281c-15.207031-.003906-27.980469-11.429687-29.675781-26.542969-.519531-4.683594-4.738281-8.0625-9.421875-7.542968-4.683594.519531-8.0625 4.738281-7.542969 9.421874 2.835937 25.765626 26.023437 44.355469 51.789063 41.519532 25.765624-2.835938 44.355468-26.023438 41.519531-51.789063-2.570313-23.34375-21.992188-41.195312-45.46875-41.789062zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m503.996094 230.308594-256.015625 39.828125-85.335938 2.839843v-170.675781l85.335938 2.84375 256.015625 39.824219zm0 0"
                                                                fill="#ea6b65"></path>
                                                            <g fill="#d9d9d9">
                                                                <path
                                                                    d="m418.65625 243.585938 85.339844-13.277344v-85.339844l-85.339844-13.277344zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m333.320312 256.859375v-138.4375l-85.339843-13.277344v164.992188zm0 0">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Dirección del<br> viento</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div *ngIf="cmm3" class="col-xl-4"> <!-- -->
                <div class="container py-5 h-100">

                    <div class="row d-flex justify-content-center align-items-center h-100" style="color: #282828;">
                        <div class="col-xl-11 bgc fullh" *ngIf="cmm3Loading" style="min-height: 55vh;">
                            <mat-progress-bar mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                        <div class="col-xl-11 bgc fullh" *ngIf="!cmm3Loading">

                            <div class="card mb-4 gradient-custom" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo1" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <h2 class="display-2"><strong>{{cmm3Name}}</strong></h2>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">calendar_today</mat-icon>
                                                                {{getDia(cmm3LastData.formattedDateTime)}}</strong></span>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">access_time</mat-icon>
                                                                {{getHora(cmm3LastData.formattedDateTime)}}</strong></span>
                                                        <br>
                                                        <button mat-button (click)="isOpenCmmData(3)" class="mt-5 pt-1">
                                                            <i class="fa-solid fa-clock-rotate-left fa-2x text-muted historial-i"
                                                                style="color: #ddd;"></i></button>
                                                    </div>
                                                    <div>
                                                        <div class="ml-4">
                                                            <div class="mat-elevation-z2" style="border-radius: 20px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="120"
                                                                    height="120"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    version="1.1" x="0px" y="0px" viewBox="0 0 512 512"
                                                                    enable-background="new 0 0 512 512"
                                                                    xml:space="preserve">
                                                                    <g id="Ebene_3" display="none">
                                                                        <rect x="-2020" y="-2565" display="inline"
                                                                            fill="#4D4D4E" width="5364" height="3336" />
                                                                    </g>
                                                                    <g id="Grid" display="none">
                                                                        <g display="inline">
                                                                            <g>
                                                                                <path fill="#B3B3B3"
                                                                                    d="M511,1v510H1V1H511 M512,0H0v512h512V0L512,0z" />
                                                                            </g>
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="6" y1="6"
                                                                                x2="506" y2="506" />
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="506" y1="6"
                                                                                x2="6" y2="506" />
                                                                            <circle fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" cx="256" cy="256"
                                                                                r="250" />
                                                                        </g>
                                                                    </g>
                                                                    <g id="Ebene_2">
                                                                        <circle fill="#84abc1" cx="353.935" cy="183.958"
                                                                            r="21.065" />
                                                                        <circle fill="#84abc1" cx="428.935" cy="183.958"
                                                                            r="21.065" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="87.37" x2="255.997" y2="471.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="256.003"
                                                                            y1="331.37" x2="316.874" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="316.874"
                                                                            y1="236.37" x2="390.997" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="390.997"
                                                                            y1="236.37" x2="390.997" y2="198.037" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="361.371" x2="165.871" y2="400.372" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="165.871"
                                                                            y1="400.372" x2="165.871" y2="354.371" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="206.871" y2="324.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="206.871"
                                                                            y1="324.368" x2="206.871" y2="298.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="124.871" y2="298.368" />

                                                                        <polygon fill="none" stroke="#7fed6f"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="   184.997,165.621 154.997,182.364 126.692,165.621 126.692,86.624 184.997,86.624  " />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="202.302"
                                                                            y1="118.364" x2="255.997" y2="172.059" />
                                                                    </g>
                                                                    <g id="txt">
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <br>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card mb-4" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo2" class="">
                                        <!-- Indicators -->
                                        <div>
                                            <div>
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm3LastData.outTemp}}°C</strong>
                                                        </p>
                                                        <svg style="margin-right: 20px" height="60px"
                                                            viewBox="-42 0 512 512" width="60px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m84.144531 38.53125c4.394531 19.445312 27.988281 27.109375 42.96875 13.960938 2.53125-2.222657 6.324219.53125 4.992188 3.625-7.878907 18.3125 6.703125 38.382812 26.554687 36.550781 3.355469-.308594 4.804688 4.144531 1.90625 5.867187-17.136718 10.1875-17.136718 34.996094 0 45.179688 2.898438 1.722656 1.449219 6.175781-1.90625 5.867187-19.851562-1.832031-34.433594 18.238281-26.554687 36.550781 1.332031 3.097657-2.460938 5.847657-4.992188 3.625-14.980469-13.148437-38.578125-5.480468-42.96875 13.960938-.742187 3.289062-5.425781 3.289062-6.167969 0-4.390624-19.445312-27.984374-27.109375-42.96875-13.960938-2.53125 2.222657-6.324218-.527343-4.992187-3.625 7.878906-18.3125-6.703125-38.382812-26.554687-36.550781-3.355469.308594-4.804688-4.144531-1.90625-5.867187 17.136718-10.183594 17.136718-34.992188 0-45.179688-2.894532-1.722656-1.449219-6.175781 1.90625-5.867187 19.851562 1.832031 34.433593-18.238281 26.554687-36.550781-1.332031-3.09375 2.460937-5.847657 4.992187-3.625 14.984376 13.148437 38.578126 5.484374 42.96875-13.960938.742188-3.289062 5.425782-3.289062 6.167969 0zm0 0"
                                                                fill="#fd8f31"></path>
                                                            <path
                                                                d="m160.566406 143.714844c-17.136718-10.183594-17.136718-34.992188 0-45.179688 2.898438-1.71875 1.449219-6.175781-1.90625-5.863281-19.847656 1.832031-34.429687-18.242187-26.554687-36.554687 1.332031-3.09375-2.457031-5.847657-4.992188-3.625-14.980469 13.152343-38.574219 5.484374-42.96875-13.960938-.742187-3.289062-5.425781-3.289062-6.167969 0-1.625 7.191406-5.875 12.761719-11.34375 16.277344 19.398438 15.589844 31.824219 39.496094 31.824219 66.320312 0 26.820313-12.425781 50.730469-31.824219 66.320313 5.46875 3.511719 9.71875 9.085937 11.34375 16.273437.742188 3.289063 5.429688 3.289063 6.167969 0 4.394531-19.445312 27.988281-27.109375 42.96875-13.960937 2.535157 2.222656 6.324219-.527344 4.992188-3.625-7.875-18.3125 6.707031-38.382813 26.554687-36.550781 3.355469.308593 4.804688-4.148438 1.90625-5.871094zm0 0"
                                                                fill="#ff7f1f"></path>
                                                            <path
                                                                d="m115.933594 121.125c0 19.261719-15.613282 34.871094-34.875 34.871094-19.257813 0-34.871094-15.609375-34.871094-34.871094 0-19.257812 15.613281-34.871094 34.871094-34.871094 19.261718 0 34.875 15.613282 34.875 34.871094zm0 0"
                                                                fill="#fccf3f"></path>
                                                            <path
                                                                d="m334.011719 315.355469v-253.195313c0-34.332031-27.832031-62.160156-62.160157-62.160156-34.332031 0-62.160156 27.828125-62.160156 62.160156v253.195313c-27.8125 19.566406-45.984375 51.910156-45.984375 88.5 0 59.726562 48.417969 108.144531 108.144531 108.144531 59.726563 0 108.144532-48.417969 108.144532-108.144531 0-36.589844-18.175782-68.933594-45.984375-88.5zm0 0"
                                                                fill="#d8d8d8"></path>
                                                            <path
                                                                d="m271.851562 476.582031c-41.183593 0-74.6875-33.507812-74.6875-74.6875 0-31.273437 19.433594-59.117187 48.59375-69.980469v-269.753906c0-14.390625 11.703126-26.09375 26.09375-26.09375 14.386719 0 26.09375 11.703125 26.09375 26.09375v269.753906c29.15625 10.859376 48.59375 38.703126 48.59375 69.976563 0 41.183594-33.503906 74.691406-74.6875 74.691406zm0 0"
                                                                fill="#f3e8d7"></path>
                                                            <path
                                                                d="m245.757812 109.871094v222.042968c-29.160156 10.859376-48.59375 38.703126-48.59375 69.976563 0 41.183594 33.503907 74.6875 74.6875 74.6875 41.183594 0 74.6875-33.503906 74.6875-74.6875 0-31.273437-19.4375-59.117187-48.59375-69.976563v-222.042968zm0 0"
                                                                fill="#fc4e51"></path>
                                                            <g fill="#0d6e9a">
                                                                <path
                                                                    d="m420.105469 180.21875h-44.824219c-4.269531 0-7.726562-3.460938-7.726562-7.726562 0-4.265626 3.457031-7.726563 7.726562-7.726563h44.824219c4.265625 0 7.726562 3.460937 7.726562 7.726563 0 4.265624-3.460937 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 117.597656h-20.351562c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726563 7.726562-7.726563h20.351562c4.265626 0 7.726563 3.460938 7.726563 7.726563s-3.460937 7.726562-7.726563 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 54.976562h-27.5625c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726562 7.726562-7.726562h27.5625c4.269531 0 7.726562 3.460937 7.726562 7.726562s-3.457031 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 305.460938h-27.5625c-4.269531 0-7.726562-3.460938-7.726562-7.726563 0-4.269531 3.457031-7.726563 7.726562-7.726563h27.5625c4.269531 0 7.726562 3.457032 7.726562 7.726563 0 4.265625-3.457031 7.726563-7.726562 7.726563zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 242.839844h-20.351562c-4.269531 0-7.726562-3.460938-7.726562-7.726563s3.457031-7.726562 7.726562-7.726562h20.351562c4.265626 0 7.726563 3.460937 7.726563 7.726562s-3.460937 7.726563-7.726563 7.726563zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m297.945312 331.914062v-222.042968h-52.1875v222.042968s36.074219 16.738282 36.074219 69.976563c0 29.601563-17.308593 55.234375-42.335937 67.3125 9.792968 4.726563 20.769531 7.375 32.351562 7.375 41.183594 0 74.691406-33.503906 74.691406-74.6875 0-31.273437-19.4375-59.113281-48.59375-69.976563zm0 0"
                                                                fill="#e5394b"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Temperatura</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm3LastData.outHumidity}}%</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-38 0 420 420.192" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m344.425781 175.695312c.210938 31.570313-16.507812 60.835938-43.816406 76.679688-27.304687 15.847656-61.007813 15.847656-88.3125 0-27.304687-15.84375-44.027344-45.109375-43.8125-76.679688 0-48.582031 87.972656-175.6874995 87.972656-175.6874995s87.96875 127.0976565 87.96875 175.6874995zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m256.453125 0s-5.3125 7.695312-13.3125 19.960938c24.585937 37.734374 74.660156 119.066406 74.660156 155.734374-.011719 43.441407-31.722656 80.375-74.660156 86.960938 25.355469 3.859375 51.136719-3.527344 70.597656-20.238281 19.460938-16.707031 30.664063-41.074219 30.6875-66.722657 0-48.589843-87.972656-175.695312-87.972656-175.695312zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m214.261719 66.601562c-22.570313 38.511719-45.761719 84.136719-45.761719 109.085938.003906 32.640625 18.074219 62.59375 46.949219 77.8125s63.800781 13.199219 90.726562-5.246094c-15.175781-52.742187-58.601562-128.222656-91.914062-181.652344zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m279.007812 280.734375c0 77.019531-62.4375 139.457031-139.457031 139.457031s-139.4531248-62.4375-139.4531248-139.457031 139.4531248-278.511719 139.4531248-278.511719 139.457031 201.472656 139.457031 278.511719zm0 0"
                                                                fill="#26a3b9"></path>
                                                            <g fill="#168aa5">
                                                                <path
                                                                    d="m227.484375 187.15625 19.304687 19.308594-156.308593 156.308594-19.308594-19.308594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m126.574219 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m229.730469 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m139.550781 2.222656s-7.769531 11.230469-19.636719 29.390625c38.402344 58.796875 119.820313 190.277344 119.820313 249.121094-.007813 69.425781-51.082031 128.273437-119.8125 138.054687 40.011719 5.71875 80.535156-6.214843 111.0625-32.703124 30.527344-26.492188 48.050781-64.929688 48.023437-105.351563 0-77.039063-139.457031-278.511719-139.457031-278.511719zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m208.058594 187.15625 19.304687 19.304688-156.308593 156.3125-19.308594-19.308594zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m107.148438 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m210.304688 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Humedad</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm3LastData.pressure}}hPa</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 0 512 512" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m256 512c-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.636719-74.980469-181.019531s26.628906-132.667969 74.980469-181.019531c48.351562-48.351563 112.640625-74.980469 181.019531-74.980469s132.667969 26.628906 181.019531 74.980469c48.351563 48.351562 74.980469 112.636719 74.980469 181.019531s-26.628906 132.667969-74.980469 181.019531c-48.351562 48.351563-112.640625 74.980469-181.019531 74.980469zm0-478.609375c-122.746094 0-222.609375 99.863281-222.609375 222.609375s99.863281 222.609375 222.609375 222.609375 222.609375-99.863281 222.609375-222.609375-99.863281-222.609375-222.609375-222.609375zm0 0"
                                                                fill="#736056"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-36.414063-36.414063-84.097656-54.839844-131.929687-55.308594-.613282-.070312-1.234376-.113281-1.867188-.113281s-1.253906.042969-1.867188.113281c-47.828124.46875-95.507812 18.894531-131.921874 55.300781-.003907.003907-.003907.003907-.007813.007813s-.003906.003906-.007813.007813c-36.410156 36.414062-54.832031 84.09375-55.300781 131.921874-.070312.613282-.113281 1.234376-.113281 1.867188s.042969 1.253906.113281 1.867188c.46875 47.832031 18.894531 95.515624 55.308594 131.929687 3.261719 3.261719 7.53125 4.890625 11.804687 4.890625 4.273438 0 8.546876-1.628906 11.804688-4.890625l23.613281-23.609375c6.519531-6.519531 6.519531-17.089844 0-23.613281-6.523437-6.519531-17.09375-6.519531-23.613281 0l-11.175781 11.175781c-19.242188-23.847656-30.410157-52.097656-33.503907-81.054688h15.738282c9.21875 0 16.695312-7.476562 16.695312-16.695312s-7.476562-16.695312-16.695312-16.695312h-15.726563c3.09375-28.953126 14.261719-57.199219 33.5-81.050782l11.171875 11.171875c3.257813 3.257813 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.546875-1.632812 11.804687-4.890625 6.519531-6.519531 6.519531-17.09375 0-23.613281l-11.171875-11.171875c23.851563-19.238281 52.097656-30.402344 81.050782-33.492187v15.722656c0 9.21875 7.476562 16.695312 16.695312 16.695312 9.222656 0 16.695312-7.476562 16.695312-16.695312v-15.722656c34.171876 3.648437 67.355469 18.53125 93.492188 44.664062 6.519531 6.519531 17.089844 6.519531 23.609375 0s6.519531-17.089844 0-23.609375zm0 0"
                                                                fill="#88cc2a"></path>
                                                            <path
                                                                d="m445.21875 256c0-.632812-.042969-1.253906-.113281-1.867188-.46875-47.832031-18.894531-95.515624-55.308594-131.929687-6.519531-6.519531-17.089844-6.519531-23.609375 0-6.523438 6.519531-6.523438 17.089844 0 23.609375 26.132812 26.136719 41.015625 59.324219 44.667969 93.492188h-15.726563c-9.21875 0-16.695312 7.476562-16.695312 16.695312s7.476562 16.695312 16.695312 16.695312h15.734375c-3.09375 28.957032-14.257812 57.207032-33.5 81.054688l-11.175781-11.175781c-6.519531-6.519531-17.09375-6.519531-23.613281 0s-6.519531 17.089843 0 23.613281l23.613281 23.609375c3.257812 3.261719 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.542968-1.628906 11.804687-4.890625 36.414063-36.414063 54.839844-84.097656 55.308594-131.929687.070312-.613282.113281-1.234376.113281-1.867188zm0 0"
                                                                fill="#ff9f19"></path>
                                                            <path
                                                                d="m272.695312 445.21875h-33.390624c-27.617188 0-50.085938-22.46875-50.085938-50.089844 0-27.617187 22.46875-50.085937 50.085938-50.085937h33.390624c27.617188 0 50.085938 22.46875 50.085938 50.085937 0 27.621094-22.46875 50.089844-50.085938 50.089844zm-33.390624-66.785156c-9.207032 0-16.695313 7.492187-16.695313 16.695312 0 9.207032 7.488281 16.695313 16.695313 16.695313h33.390624c9.207032 0 16.695313-7.488281 16.695313-16.695313 0-9.203125-7.488281-16.695312-16.695313-16.695312zm0 0"
                                                                fill="#bddeff"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-6.519531-6.519531-17.089844-6.519531-23.609375 0l-84.46875 84.46875c-7.699219-4.03125-16.445312-6.324219-25.71875-6.324219-30.6875 0-55.652344 24.964844-55.652344 55.652344s24.964844 55.652344 55.652344 55.652344 55.652344-24.964844 55.652344-55.652344c0-9.273438-2.292969-18.019531-6.324219-25.71875l84.46875-84.46875c6.519531-6.519531 6.519531-17.089844 0-23.609375zm-133.796875 156.058594c-12.273438 0-22.261719-9.988281-22.261719-22.261719s9.988281-22.261719 22.261719-22.261719 22.261719 9.988281 22.261719 22.261719-9.988281 22.261719-22.261719 22.261719zm0 0"
                                                                fill="#e6563a"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Presión</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm3LastData.rain}}mm</strong></p>
                                                        <svg height="45.541px" viewBox="0 -7 512 512" width="47.542px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m21.316406 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.0117188 1.125.03125 1.683594 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578124-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m21.316406 457.257812c-10.378906 0-20.753906-6.703124-21.2734372-20.097656-.0234376-.5625-.0429688-1.125-.0429688-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.570312 7.371094-4.292968 21.140625-4.292968 36.914062 0 .558594 0 1.121094.007812 1.683594.105469 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 438.421875c-12.742187 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574218-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 329.023438c-12.742187 7.371093-21.316406 21.136718-21.316406 36.914062 0 .5625.011719 1.125.03125 1.679688 1.039062 26.800781 41.527344 26.800781 42.566406 0 .023438-.554688.03125-1.117188.03125-1.679688.003906-15.777344-8.574218-29.542969-21.3125-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 387.71875c-10.378906 0-20.757812-6.707031-21.277344-20.101562-.019531-.558594-.039062-1.121094-.039062-1.683594 0-15.773438 8.578125-29.539063 21.316406-36.914063-2.570312 7.375-4.296875 21.140625-4.296875 36.914063 0 .5625 0 1.125.011719 1.683594.105469 13.394531 2.195312 20.101562 4.285156 20.101562zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m208.1875 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m208.1875 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m301.625 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.007812 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574219-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m301.625 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105468 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m489.035156 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.035156-1.117187.035156-1.683593 0-15.777344-8.578124-29.542969-21.316406-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m489.035156 497.117188c-10.378906 0-20.753906-6.703126-21.273437-20.097657-.023438-.5625-.042969-1.125-.042969-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m395.058594 398.5625c-12.738282 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527343 26.796875 42.566406 0 .023437-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m395.058594 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578124-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m408.25 181.570312c0 37.824219-30.664062 68.492188-68.503906 68.492188h-247.886719c-50.296875 0-91.082031-40.773438-91.082031-91.082031 0-50.296875 40.78125-91.082031 91.082031-91.082031 4.511719 0 8.945313.347656 13.292969.976562 20.570312-40.84375 62.871094-68.875 111.742187-68.875 59.128907 0 108.667969 41.054688 121.679688 96.210938 1.300781 5.503906 2.238281 11.144531 2.777343 16.894531v.011719c37.082032.851562 66.898438 31.167968 66.898438 68.453124zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m260.351562 7.769531c-13.539062-5.027343-28.183593-7.76562475-43.457031-7.76562475-48.871093 0-91.160156 28.03124975-111.742187 68.86328175v.011718c-4.347656-.628906-8.78125-.976562-13.292969-.976562-50.3125 0-91.082031 40.785156-91.082031 91.082031 0 50.3125 40.769531 91.082031 91.082031 91.082031h44.019531c-45.332031 0-82.066406-36.746094-82.066406-82.070312 0-40.214844 28.929688-73.683594 67.128906-80.703125 3.777344-.707031 7.648438-1.144531 11.597656-1.300781 1.105469-.035157 2.222657-.0625 3.339844-.0625 14.734375 0 28.570313 3.882812 40.515625 10.699218-1.660156-11.5625-13.613281-22.394531-31.238281-31.125 9.679688-32.726562 42.613281-63.078125 115.195312-57.734375zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m264.6875 245.550781c.496094.089844.789062.089844.835938.089844h-3.792969c.992187 0 1.972656-.03125 2.957031-.089844zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m512 313.621094c0 30.589844-24.800781 55.390625-55.402344 55.390625h-200.476562c-40.675782 0-73.660156-32.972657-73.660156-73.660157 0-40.679687 32.984374-73.660156 73.660156-73.660156 3.648437 0 7.238281.28125 10.75.789063 16.640625-33.035157 50.847656-55.703125 90.371094-55.703125 47.824218 0 87.886718 33.203125 98.410156 77.808594 1.050781 4.453124 1.808594 9.015624 2.246094 13.664062v.011719c29.988281.683593 54.101562 25.203125 54.101562 55.359375zm0 0"
                                                                fill="#d3ddea"></path>
                                                            <path
                                                                d="m392.386719 173.0625c-10.949219-4.066406-22.792969-6.28125-35.144531-6.28125-39.523438 0-73.722657 22.667969-90.371094 55.691406v.011719c-3.515625-.511719-7.101563-.789063-10.75-.789063-40.6875 0-73.660156 32.980469-73.660156 73.660157 0 40.6875 32.972656 73.660156 73.660156 73.660156h35.601562c-36.664062 0-66.371094-29.71875-66.371094-66.371094 0-32.527343 23.394532-59.59375 54.289063-65.269531 3.058594-.574219 6.1875-.925781 9.378906-1.050781.894531-.03125 1.800781-.050781 2.703125-.050781 11.917969 0 23.105469 3.140624 32.765625 8.648437-1.339843-9.351563-11.007812-18.109375-25.261719-25.167969 7.824219-26.46875 34.460938-51.015625 93.160157-46.691406zm0 0"
                                                                fill="#84abc1"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Lluvia</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo3" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm3LastData.dewpoint}}°C</strong>
                                                        </p>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                                            id="Layer_1" x="0px" y="0px" viewBox="0 0 497 497"
                                                            style="enable-background:new 0 0 497 497;"
                                                            xml:space="preserve" width="55px" height="55px">
                                                            <g>
                                                                <path style="fill:#59C2E8;"
                                                                    d="M183.391,58.325c-6.66-6.477-17.246-6.477-23.907,0C-78.787,290.047-14.684,497,171.438,497   S421.663,290.047,183.391,58.325z">
                                                                </path>
                                                                <path style="fill:#0097D1;"
                                                                    d="M201.438,477C19.441,477-45.878,279.117,174.095,53.691c-5.15-0.806-10.593,0.727-14.611,4.634   C-78.787,290.047-14.684,497,171.438,497c48.559,0,88.805-14.094,117.55-39.032C264.453,470.302,234.92,477,201.438,477z">
                                                                </path>
                                                                <g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M99.951,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5s7.5,3.358,7.5,7.5     v12.938C107.451,283.894,104.094,287.251,99.951,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M242.925,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5     s7.5,3.358,7.5,7.5v12.938C250.425,283.894,247.067,287.251,242.925,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M171.438,294.157c-9.041,0-17.53-3.78-23.291-10.371c-2.727-3.119-2.408-7.857,0.711-10.583     c3.117-2.726,7.856-2.408,10.582,0.711c2.912,3.332,7.285,5.242,11.998,5.242c4.712,0,9.085-1.911,11.997-5.242     c2.726-3.119,7.464-3.437,10.583-0.711c3.118,2.726,3.437,7.464,0.71,10.583C188.968,290.378,180.478,294.157,171.438,294.157z">
                                                                        </path>
                                                                    </g>
                                                                </g>
                                                                <path style="fill:#D5EBF6;"
                                                                    d="M420.006,262.727V50.657C420.006,22.68,397.326,0,369.35,0h0c-25.428,0-46.475,18.737-50.1,43.157   l4.08,7.5l-4.637,7.5V79.17l3.97,7.5l-3.97,7.5v21.014l4.637,7.5l-4.637,7.5v21.014l3.637,7.5l-3.637,7.5v21.013l3.637,7.5   l-3.637,7.5v60.516c-42.433,19.279-71.942,62.03-71.942,111.674C246.751,442.111,301.64,497,369.35,497   s122.599-54.889,122.599-122.599C491.948,324.757,462.439,282.006,420.006,262.727z">
                                                                </path>
                                                                <path style="fill:#FF6E80;"
                                                                    d="M369.349,462.349c-48.494,0-87.947-39.453-87.947-87.947c0-34.427,20.264-65.879,51.625-80.127   l20.318-9.23V50.656c0-8.825,7.18-16.005,16.005-16.005c8.825,0,16.006,7.18,16.006,16.005v234.388l20.318,9.231   c31.361,14.248,51.625,45.7,51.625,80.127C457.297,422.896,417.844,462.349,369.349,462.349z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,187.211v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H318.693   L318.693,187.211z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,151.198v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,151.198z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,115.184v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,115.184z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,50.657v7.5h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-34.095   C318.886,45.604,318.693,48.107,318.693,50.657z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,79.17v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,79.17z">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Punto de<br> rocío</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm3LastData.heatindex}}°C</strong>
                                                        </p>
                                                        <svg style="margin-right: 20px" height="70px"
                                                            viewBox="-78 0 512 512" width="75px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-13.621094-15.976562-21.824218-36.6875-21.824218-59.308593 0-50.550782 40.976562-91.527344 91.527343-91.527344 46.082031 0 84.203125 34.046875 90.585938 78.363281.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fab03c"></path>
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-.621094-4.300781-.9375-8.691406-.9375-13.160156 0-50.554687 40.984375-91.527344 91.527344-91.527344 27.925781 0 52.925781 12.503907 69.699219 32.214844.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <g fill="#f8e98e">
                                                                <path
                                                                    d="m150.769531 34.9375c-4.207031 0-7.613281-3.410156-7.613281-7.613281v-19.714844c0-4.203125 3.410156-7.609375 7.613281-7.609375s7.609375 3.40625 7.609375 7.609375v19.714844c0 4.203125-3.40625 7.613281-7.609375 7.613281zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m89.054688 51.476562c-2.632813 0-5.191407-1.367187-6.601563-3.808593l-9.855469-17.070313c-2.101562-3.640625-.855468-8.296875 2.785156-10.398437 3.640626-2.101563 8.296876-.855469 10.398438 2.785156l9.855469 17.074219c2.101562 3.636718.855469 8.292968-2.785157 10.394531-1.199218.691406-2.507812 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m43.855469 96.660156c-1.289063 0-2.597657-.332031-3.796875-1.023437l-17.074219-9.855469c-3.640625-2.101562-4.886719-6.757812-2.785156-10.398438 2.101562-3.640624 6.753906-4.886718 10.394531-2.785156l17.074219 9.855469c3.640625 2.105469 4.886719 6.757813 2.785156 10.398437-1.410156 2.445313-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m27.324219 158.378906h-19.714844c-4.203125 0-7.609375-3.40625-7.609375-7.609375s3.40625-7.613281 7.609375-7.613281h19.714844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m257.679688 96.660156c-2.628907 0-5.1875-1.367187-6.597657-3.808594-2.101562-3.640624-.855469-8.292968 2.785157-10.398437l17.074218-9.855469c3.636719-2.101562 8.292969-.855468 10.394532 2.785156 2.101562 3.640626.855468 8.296876-2.785157 10.398438l-17.074219 9.855469c-1.195312.691406-2.503906 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m26.796875 229.960938c-2.628906 0-5.1875-1.367188-6.597656-3.808594-2.101563-3.640625-.855469-8.296875 2.785156-10.394532l17.074219-9.859374c3.636718-2.097657 8.292968-.851563 10.394531 2.789062s.855469 8.292969-2.785156 10.394531l-17.074219 9.855469c-1.195312.695312-2.503906 1.023438-3.796875 1.023438zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m212.484375 51.476562c-1.292969 0-2.601563-.332031-3.800781-1.023437-3.640625-2.101563-4.886719-6.757813-2.785156-10.394531l9.855468-17.074219c2.101563-3.640625 6.757813-4.886719 10.398438-2.785156 3.640625 2.101562 4.886718 6.757812 2.785156 10.398437l-9.855469 17.070313c-1.410156 2.441406-3.96875 3.808593-6.597656 3.808593zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m79.183594 282.359375c-1.292969 0-2.601563-.328125-3.800782-1.019531-3.640624-2.105469-4.886718-6.757813-2.785156-10.398438l9.855469-17.074218c2.101563-3.640626 6.757813-4.886719 10.398437-2.785157 3.640626 2.101563 4.886719 6.757813 2.785157 10.398438l-9.855469 17.070312c-1.410156 2.441407-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m329.234375 240.59375c0 10.503906-8.519531 19.019531-19.023437 19.019531-10.503907 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.519531-19.023438 19.023438-19.023438 10.503906 0 19.023437 8.515626 19.023437 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m177.15625 240.59375c0 10.503906-8.515625 19.019531-19.019531 19.019531-10.507813 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.515625-19.023438 19.023438-19.023438 10.503906 0 19.019531 8.515626 19.019531 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m353.683594 439.070312v62.78125c0 5.605469-4.542969 10.148438-10.148438 10.148438h-218.722656c-5.601562 0-10.148438-4.542969-10.148438-10.148438v-62.78125c0-43.292968 23.027344-81.207031 57.492188-102.175781 18.074219-10.988281 39.308594-17.332031 62.019531-17.332031 22.5 0 43.535157 6.21875 61.5 17.03125 8.390625 5.042969 16.125 11.089844 23.007813 17.972656 21.625 21.636719 35 51.511719 35 84.503906zm0 0"
                                                                fill="#f0f5f7"></path>
                                                            <path
                                                                d="m172.503906 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.609375-7.609375 4.203125 0 7.613281 3.398437 7.613281 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m311.171875 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.613281-7.609375 4.199219 0 7.609375 3.398437 7.609375 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m343.539062 511.992188h-47.589843v-67.089844h57.738281v56.945312c0 5.601563-4.542969 10.144532-10.148438 10.144532zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m295.949219 444.90625h15.222656v67.082031h-15.222656zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m172.402344 444.90625v67.082031h-47.589844c-5.601562 0-10.148438-4.542969-10.148438-10.148437v-56.933594zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m157.28125 444.90625h15.121094v67.082031h-15.121094zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m295.679688 336.59375c-13.59375 19.570312-36.234376 32.367188-61.863282 32.367188-25.496094 0-48.042968-12.671876-61.65625-32.066407 18.070313-10.992187 39.304688-17.328125 62.015625-17.328125 22.5 0 43.535157 6.210938 61.503907 17.027344zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m164.230469 174.1875v85.160156c0 38.625 31.316406 69.941406 69.941406 69.941406 38.628906 0 69.941406-31.316406 69.941406-69.941406v-85.160156zm0 0"
                                                                fill="#fbdbb7"></path>
                                                            <path
                                                                d="m304.113281 174.1875v85.15625c0 19.3125-7.828125 36.808594-20.488281 49.460938-12.652344 12.652343-30.140625 20.480468-49.453125 20.480468-3.757813 0-7.445313-.292968-11.050781-.875 14.886718-2.355468 28.207031-9.421875 38.402344-19.605468 12.652343-12.652344 20.480468-30.148438 20.480468-49.460938v-85.15625zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m234.175781 119.171875c-44.792969 0-81.101562 36.308594-81.101562 81.101563v17.472656c0 6.152344 5.417969 10.886718 11.515625 10.054687 20.5-2.789062 62.964844-10.910156 82.292968-31.78125 7.195313 11.8125 23.039063 26.890625 57.433594 29.546875 5.90625.457032 10.957032-4.199218 10.957032-10.121094v-15.171874c0-44.792969-36.308594-81.101563-81.097657-81.101563zm0 0"
                                                                fill="#82807f"></path>
                                                            <path
                                                                d="m315.273438 200.277344v15.164062c0 5.929688-5.066407 10.578125-10.980469 10.121094-4.617188-.359375-8.902344-.941406-12.878907-1.710938v-23.574218c0-40.738282-30.03125-74.460938-69.175781-80.238282 3.898438-.578124 7.875-.871093 11.9375-.871093 44.785157 0 81.097657 36.3125 81.097657 81.109375zm0 0"
                                                                fill="#6d6d6d"></path>
                                                            <path
                                                                d="m201.347656 248.203125c-4.207031 0-7.660156-3.40625-7.660156-7.609375s3.363281-7.613281 7.570312-7.613281h.089844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m267.089844 248.203125c-4.203125 0-7.65625-3.40625-7.65625-7.609375s3.363281-7.613281 7.566406-7.613281h.089844c4.207031 0 7.613281 3.410156 7.613281 7.613281s-3.410156 7.609375-7.613281 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m241.503906 292.371094h-14.65625c-4.207031 0-7.613281-3.40625-7.613281-7.609375v-44.167969c0-4.203125 3.410156-7.613281 7.613281-7.613281s7.609375 3.410156 7.609375 7.613281v36.554688h7.046875c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.410156 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <path
                                                                d="m323.347656 172.90625c0 10.621094-8.609375 19.230469-19.230468 19.230469-10.621094 0-19.230469-8.609375-19.230469-19.230469 0-6.96875 8.28125-20.652344 13.972656-29.230469 2.496094-3.761719 8.015625-3.761719 10.511719 0 5.695312 8.578125 13.976562 22.261719 13.976562 29.230469zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m323.34375 172.90625c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.660156 0-5.199219-.542969-7.507812-1.519531 6.898437-2.921876 11.730469-9.753907 11.730469-17.710938 0-6.09375-6.328126-17.316406-11.722657-25.777344.773438-1.214844 1.527344-2.367187 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.519532-.011719 5.691406 8.582031 13.972656 22.261719 13.972656 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m356.394531 368.648438c0 10.621093-8.613281 19.230468-19.234375 19.230468s-19.230468-8.609375-19.230468-19.230468c0-6.972657 8.285156-20.652344 13.976562-29.234376 2.496094-3.761718 8.015625-3.761718 10.511719 0 5.691406 8.582032 13.976562 22.261719 13.976562 29.234376zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m356.390625 368.648438c0 10.617187-8.605469 19.230468-19.230469 19.230468-2.664062 0-5.199218-.542968-7.507812-1.519531 6.894531-2.921875 11.730468-9.753906 11.730468-17.710937 0-6.09375-6.328124-17.320313-11.722656-25.78125.769532-1.210938 1.523438-2.367188 2.238282-3.441407 2.496093-3.765625 8.019531-3.777343 10.515624-.011719 5.695313 8.582032 13.976563 22.261719 13.976563 29.234376zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.621094-8.609375 19.230469-19.230469 19.230469s-19.230469-8.609375-19.230469-19.230469c0-6.96875 8.28125-20.652344 13.976563-29.234375 2.496093-3.757813 8.015625-3.757813 10.511719 0 5.691406 8.582031 13.972656 22.265625 13.972656 29.234375zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.664063 0-5.199219-.542969-7.507813-1.519531 6.894532-2.921876 11.726563-9.753907 11.726563-17.710938 0-6.09375-6.328125-17.316406-11.71875-25.777344.769531-1.214844 1.523437-2.371094 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.515625-.011719 5.695313 8.582031 13.976563 22.261719 13.976563 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Sensación<br> térmica</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{cmm3LastData.windSpeed}}Km/h</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 -23 511.99037 511" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m238.945312 182.152344h-213.332031c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h213.332031c20.527344.90625 39.605469-10.542969 48.460938-29.082032 9.6875-19.246094 8.308594-42.207031-3.609375-60.160156-9.566406-12.449219-24.515625-17.066406-43.175781-13.320312-14.421875 1.917968-24.910156 14.648437-24.03125 29.167968 1.066406 10.726563 9.738281 22.1875 30.890625 22.1875 2.355469 0 4.265625-1.910156 4.265625-4.269531 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535157-3.820312-8.535157-8.53125 0-4.714844 3.820313-8.535156 8.535157-8.535156 11.78125 0 21.332031 9.550781 21.332031 21.332031 0 11.785156-9.550781 21.335937-21.332031 21.335937-30.5 0-46.019531-18.875-47.871094-37.546874-1.890625-23.285157 14.539063-44.070313 37.632813-47.609376 22.140624-6.042968 45.753906 1.675782 60.054687 19.628907 15.925781 23.097656 17.992187 53.039062 5.386719 78.105469-11.75 24.332031-36.726563 39.472656-63.738282 38.628906zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m269.742188 430.933594c-5.183594-.019532-10.355469-.554688-15.433594-1.597656-23.097656-3.535157-39.527344-24.324219-37.632813-47.613282 1.851563-18.671875 17.375-37.546875 47.871094-37.546875 11.78125 0 21.332031 9.550781 21.332031 21.332031s-9.550781 21.335938-21.332031 21.335938c-4.710937 0-8.53125-3.824219-8.53125-8.535156 0-4.714844 3.820313-8.535156 8.53125-8.535156 2.355469 0 4.265625-1.910157 4.265625-4.265626 0-2.355468-1.910156-4.265624-4.265625-4.265624-21.152344 0-29.867187 11.46875-30.890625 22.1875-.878906 14.535156 9.628906 27.273437 24.0625 29.183593 15.765625 4.65625 32.785156-.597656 43.179688-13.328125 11.917968-17.953125 13.296874-40.914062 3.609374-60.160156-8.859374-18.550781-27.957031-30.003906-48.492187-29.082031h-213.335937c-4.710938 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820312-8.53125 8.53125-8.53125h213.335937c27.015625-.859374 52.003906 14.265626 63.769531 38.601563 12.605469 25.066406 10.539063 55.011719-5.386718 78.109375-10.601563 13.847656-27.21875 21.753906-44.65625 21.246094zm0 0"
                                                                fill="#bdc3c7"></path>
                                                            <path
                                                                d="m375.480469 224.820312h-213.332031c-4.714844 0-8.535157-3.820312-8.535157-8.535156 0-4.710937 3.820313-8.53125 8.535157-8.53125h213.332031c20.523437.90625 39.605469-10.542968 48.460937-29.082031 9.683594-19.246094 8.308594-42.210937-3.609375-60.160156-9.566406-12.460938-24.535156-17.066407-43.179687-13.320313-14.417969 1.914063-24.90625 14.644532-24.03125 29.167969 1.070312 10.726563 9.738281 22.183594 30.894531 22.183594 2.355469 0 4.265625-1.910157 4.265625-4.265625 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535156-3.820313-8.535156-8.535157 0-4.710937 3.820312-8.53125 8.535156-8.53125 11.78125 0 21.332031 9.550782 21.332031 21.332032s-9.550781 21.332031-21.332031 21.332031c-30.5 0-46.023437-18.875-47.875-37.546875-1.890625-23.285156 14.539063-44.070312 37.632813-47.605469 22.144531-6.046875 45.757812 1.671875 60.058593 19.625 15.925781 23.097657 17.988281 53.042969 5.382813 78.105469-11.75 24.335938-36.722656 39.472656-63.734375 38.632812zm0 0"
                                                                fill="#7f8c8d"></path>
                                                            <path
                                                                d="m426.875 453.785156c-7.640625-.035156-15.261719-.824218-22.75-2.355468-33.242188-4.996094-56.972656-34.835938-54.355469-68.351563 2.457031-24.796875 22.050781-53.757813 69.1875-53.757813 15.414063.265626 27.773438 12.839844 27.773438 28.257813s-12.359375 27.988281-27.773438 28.257813c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125 6.0625-.167969 10.886719-5.128906 10.886719-11.191406s-4.824219-11.027344-10.886719-11.191406c-35.6875 0-50.347656 19.820312-52.199219 38.398437-1.625 24.75 16.152344 46.546875 40.722657 49.9375 38.664062 7.722656 60.015625-7.824218 71.097656-22.246094 19.414063-25.257812 21.898437-65.382812 6.042969-97.570312-14.375-30.1875-45.410156-48.851562-78.8125-47.394531h-397.261719c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125h397.261719c39.863281-1.292968 76.75 21.015626 94.121094 56.917969 18.628906 37.792969 15.410156 85.332031-7.816407 115.515625-15.507812 20.191406-39.785156 31.695313-65.238281 30.90625zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m40.34375 434.203125c-3.925781-.128906.757812-21.335937 1.234375-23.628906 2.132813-12.183594 8.472656-23.234375 17.921875-31.214844 26.070312-21.597656 62.0625-20.363281 93.867188-18.484375 2.175781.128906 16.492187 1.28125 17.3125-.621094-14.652344 33.890625-31.324219 75.226563-61.933594 97.890625-8.882813 6.957031-20.480469 9.414063-31.421875 6.65625-7.925781-2.652343-14.917969-7.535156-20.136719-14.0625-4.667969-5.144531-8.765625-16.28125-16.84375-16.535156zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m8.546875 465.988281c-3.59375.003907-6.808594-2.242187-8.035156-5.621093-1.226563-3.378907-.203125-7.164063 2.558593-9.464844 2.164063-1.765625 53.382813-44.160156 97.636719-53.019532 4.585938-.847656 9.007813 2.144532 9.921875 6.71875.914063 4.574219-2.015625 9.035157-6.578125 10.015626-40.035156 8.066406-89.53125 49.023437-90.035156 49.390624-1.535156 1.28125-3.46875 1.980469-5.46875 1.980469zm0 0"
                                                                fill="#805333"></path>
                                                            <path
                                                                d="m132.648438 105.386719c.953124 3.796875-20.753907 5.121093-23.042969 5.324219-12.296875 1.316406-24.664063-1.730469-34.941407-8.609376-27.964843-19.105468-36.710937-54.03125-43.683593-85.128906-.476563-2.132812-3.308594-16.214844-5.367188-16.476562 36.625 4.734375 80.949219 9.386718 111.179688 32.535156 9.136719 6.617188 14.699219 17.085938 15.0625 28.359375-.355469 8.359375-3.113281 16.441406-7.945313 23.277344-3.675781 5.863281-13.242187 12.894531-11.261718 20.71875zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m163.75 138.0625c-1.703125.003906-3.371094-.511719-4.777344-1.476562-2.328125-1.578126-57.171875-39.125-77.890625-79.242188-2.164062-4.1875-.523437-9.335938 3.664063-11.5 4.1875-2.160156 9.335937-.519531 11.496094 3.667969 18.773437 36.25 71.785156 72.601562 72.320312 72.957031 3.101562 2.109375 4.464844 5.992188 3.359375 9.574219s-4.421875 6.023437-8.171875 6.019531zm0 0"
                                                                fill="#805333"></path>
                                                            <g fill="#cb8252">
                                                                <path
                                                                    d="m366.945312 28.441406c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714843 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820313-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m486.414062 11.375c0 4.714844-3.820312 8.535156-8.535156 8.535156-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125 4.714844 0 8.535156 3.820312 8.535156 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m460.8125 207.640625c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m366.945312 310.042969c0 4.710937-3.820312 8.53125-8.53125 8.53125-4.714843 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820313-8.535157 8.535156-8.535157 4.710938 0 8.53125 3.820313 8.53125 8.535157zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m324.28125 455.109375c0 4.710937-3.820312 8.53125-8.535156 8.53125-4.710938 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820312-8.535156 8.53125-8.535156 4.714844 0 8.535156 3.820312 8.535156 8.535156zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m187.746094 412.441406c0 4.714844-3.820313 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710937 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m93.878906 224.707031h-85.332031c-4.710937 0-8.53125-3.820312-8.53125-8.53125 0-4.714843 3.820313-8.535156 8.53125-8.535156h85.332031c4.714844 0 8.535156 3.820313 8.535156 8.535156 0 4.710938-3.820312 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#bdc3c7"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Velocidad del<br> viento</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{obtenerDireccionViento(cmm3LastData.windDir)}}</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-4 0 511 511.99978" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m51.703125 51.097656h17.066406v51.203125h-17.066406zm0 0"
                                                                fill="#5d9aa4"></path>
                                                            <path
                                                                d="m43.167969 272.976562h34.136719v230.414063h-34.136719zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m145.574219 42.5625h-136.539063c-4.714844 0-8.535156-3.820312-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h136.539063c4.714843 0 8.535156 3.820312 8.535156 8.535156 0 4.710938-3.820313 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m128.507812 68.164062c-4.714843 0-8.535156-3.820312-8.53125-8.535156 0-2.261718.898438-4.429687 2.496094-6.03125l19.570313-19.566406-19.570313-19.570312c-3.273437-3.386719-3.179687-8.792969.210938-12.066407 3.308594-3.191406 8.550781-3.191406 11.855468 0l25.601563 25.601563c3.332031 3.332031 3.332031 8.734375 0 12.066406l-25.601563 25.601562c-1.597656 1.601563-3.769531 2.5-6.03125 2.5zm0 0"
                                                                fill="#b9b9b9"></path>
                                                            <path
                                                                d="m34.636719 76.699219h51.203125v213.347656h-51.203125zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m34.636719 162.039062c-4.714844 0-8.535157-3.820312-8.535157-8.535156v-42.667968c0-4.714844 3.820313-8.535157 8.535157-8.535157h128.007812c4.710938.011719 8.523438 3.839844 8.511719 8.554688-.007812 3.660156-2.347656 6.90625-5.816406 8.070312l-128.007813 42.667969c-.867187.296875-1.78125.445312-2.695312.445312zm8.53125-42.671874v22.292968l66.890625-22.292968zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m162.644531 272.976562h-128.007812c-4.714844 0-8.535157-3.820312-8.535157-8.53125v-42.671874c.003907-2.742188 1.324219-5.316407 3.550782-6.917969 2.214844-1.621094 5.082031-2.058594 7.679687-1.171875l128.007813 42.671875c4.46875 1.5 6.875 6.335937 5.375 10.804687-1.164063 3.46875-4.414063 5.808594-8.070313 5.816406zm-119.476562-17.066406h66.890625l-66.890625-22.28125zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m85.839844 34.03125c0 14.140625-11.464844 25.601562-25.601563 25.601562-14.140625 0-25.601562-11.460937-25.601562-25.601562s11.460937-25.601562 25.601562-25.601562c14.136719 0 25.601563 11.460937 25.601563 25.601562zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m290.652344 298.578125c-14.464844.015625-27.355469 9.128906-32.191406 22.761719-1.570313 4.445312.761718 9.320312 5.207031 10.890625 4.441406 1.566406 9.316406-.761719 10.886719-5.207031 3.144531-8.886719 12.898437-13.542969 21.785156-10.398438 8.882812 3.144531 13.539062 12.894531 10.398437 21.78125-2.414062 6.816406-8.859375 11.375-16.085937 11.375h-42.671875c-4.710938 0-8.53125 3.824219-8.53125 8.535156 0 4.714844 3.820312 8.535156 8.53125 8.535156h42.671875c18.851562 0 34.132812-15.285156 34.132812-34.136718 0-18.851563-15.28125-34.136719-34.132812-34.136719zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m444.257812 400.984375h-93.871093c-4.710938 0-8.535157-3.820313-8.535157-8.53125 0-4.714844 3.824219-8.535156 8.535157-8.535156h93.871093c4.714844 0 8.535157 3.820312 8.535157 8.535156 0 4.710937-3.820313 8.53125-8.535157 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m316.253906 400.984375h-25.601562c-4.714844 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h25.601562c4.710938 0 8.53125 3.820312 8.53125 8.535156 0 4.710937-3.820312 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m256.515625 400.984375h-85.339844c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h85.339844c4.710937 0 8.53125 3.820312 8.53125 8.535156.003906 4.710937-3.820313 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m205.3125 435.121094h-34.136719c-4.710937 0-8.53125-3.820313-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125h34.136719c4.710938 0 8.53125 3.820312 8.53125 8.53125.003906 4.714843-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m213.847656 366.851562h-42.671875c-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710937 3.820313-8.535156 8.53125-8.535156h42.671875c4.710938 0 8.53125 3.824219 8.53125 8.535156 0 4.714844-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m273.582031 435.121094h-34.132812c-4.714844 0-8.535157-3.820313-8.535157-8.535156 0-4.710938 3.820313-8.53125 8.535157-8.53125h34.132812c4.714844 0 8.535157 3.820312 8.535157 8.53125 0 4.714843-3.820313 8.535156-8.535157 8.535156zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m407.042969 418.113281c-.101563 0-.195313-.058593-.296875-.058593h-90.492188c-4.714844 0-8.535156 3.820312-8.535156 8.53125 0 4.714843 3.820312 8.535156 8.535156 8.535156h88.222656.167969 1.210938c16.496093.003906 29.867187 13.378906 29.863281 29.875s-13.378906 29.867187-29.875 29.863281c-15.207031-.003906-27.980469-11.429687-29.675781-26.542969-.519531-4.683594-4.738281-8.0625-9.421875-7.542968-4.683594.519531-8.0625 4.738281-7.542969 9.421874 2.835937 25.765626 26.023437 44.355469 51.789063 41.519532 25.765624-2.835938 44.355468-26.023438 41.519531-51.789063-2.570313-23.34375-21.992188-41.195312-45.46875-41.789062zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m503.996094 230.308594-256.015625 39.828125-85.335938 2.839843v-170.675781l85.335938 2.84375 256.015625 39.824219zm0 0"
                                                                fill="#ea6b65"></path>
                                                            <g fill="#d9d9d9">
                                                                <path
                                                                    d="m418.65625 243.585938 85.339844-13.277344v-85.339844l-85.339844-13.277344zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m333.320312 256.859375v-138.4375l-85.339843-13.277344v164.992188zm0 0">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Dirección del<br> viento</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="cmm4" class="col-xl-4"> <!-- -->
                <div class="container py-5 h-100">

                    <div class="row d-flex justify-content-center align-items-center h-100" style="color: #282828;">
                        <div class="col-xl-11 bgc fullh" *ngIf="cmm4Loading" style="min-height: 55vh;">
                            <mat-progress-bar mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                        <div class="col-xl-11 bgc fullh" *ngIf="!cmm4Loading">

                            <div class="card mb-4 gradient-custom" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo1" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <h2 class="display-2"><strong>{{cmm4Name}}</strong></h2>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">calendar_today</mat-icon>
                                                                {{getDia(cmm4LastData.formattedDateTime)}}</strong></span>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">access_time</mat-icon>
                                                                {{getHora(cmm4LastData.formattedDateTime)}}</strong></span>
                                                        <br>
                                                        <button mat-button (click)="isOpenCmmData(4)" class="mt-5 pt-1">
                                                            <i class="fa-solid fa-clock-rotate-left fa-2x text-muted historial-i"
                                                                style="color: #ddd;"></i></button>
                                                    </div>
                                                    <div>
                                                        <div class="ml-4">
                                                            <div class="mat-elevation-z2" style="border-radius: 20px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="120"
                                                                    height="120"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    version="1.1" x="0px" y="0px" viewBox="0 0 512 512"
                                                                    enable-background="new 0 0 512 512"
                                                                    xml:space="preserve">
                                                                    <g id="Ebene_3" display="none">
                                                                        <rect x="-2020" y="-2565" display="inline"
                                                                            fill="#4D4D4E" width="5364" height="3336" />
                                                                    </g>
                                                                    <g id="Grid" display="none">
                                                                        <g display="inline">
                                                                            <g>
                                                                                <path fill="#B3B3B3"
                                                                                    d="M511,1v510H1V1H511 M512,0H0v512h512V0L512,0z" />
                                                                            </g>
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="6" y1="6"
                                                                                x2="506" y2="506" />
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="506" y1="6"
                                                                                x2="6" y2="506" />
                                                                            <circle fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" cx="256" cy="256"
                                                                                r="250" />
                                                                        </g>
                                                                    </g>
                                                                    <g id="Ebene_2">
                                                                        <circle fill="#84abc1" cx="353.935" cy="183.958"
                                                                            r="21.065" />
                                                                        <circle fill="#84abc1" cx="428.935" cy="183.958"
                                                                            r="21.065" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="87.37" x2="255.997" y2="471.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="256.003"
                                                                            y1="331.37" x2="316.874" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="316.874"
                                                                            y1="236.37" x2="390.997" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="390.997"
                                                                            y1="236.37" x2="390.997" y2="198.037" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="361.371" x2="165.871" y2="400.372" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="165.871"
                                                                            y1="400.372" x2="165.871" y2="354.371" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="206.871" y2="324.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="206.871"
                                                                            y1="324.368" x2="206.871" y2="298.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="124.871" y2="298.368" />

                                                                        <polygon fill="none" stroke="#7fed6f"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="   184.997,165.621 154.997,182.364 126.692,165.621 126.692,86.624 184.997,86.624  " />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="202.302"
                                                                            y1="118.364" x2="255.997" y2="172.059" />
                                                                    </g>
                                                                    <g id="txt">
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <br>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card mb-4" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo2" class="">
                                        <!-- Indicators -->
                                        <div>
                                            <div>
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm4LastData.outTemp}}°C</strong>
                                                        </p>
                                                        <svg style="margin-right: 20px" height="60px"
                                                            viewBox="-42 0 512 512" width="60px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m84.144531 38.53125c4.394531 19.445312 27.988281 27.109375 42.96875 13.960938 2.53125-2.222657 6.324219.53125 4.992188 3.625-7.878907 18.3125 6.703125 38.382812 26.554687 36.550781 3.355469-.308594 4.804688 4.144531 1.90625 5.867187-17.136718 10.1875-17.136718 34.996094 0 45.179688 2.898438 1.722656 1.449219 6.175781-1.90625 5.867187-19.851562-1.832031-34.433594 18.238281-26.554687 36.550781 1.332031 3.097657-2.460938 5.847657-4.992188 3.625-14.980469-13.148437-38.578125-5.480468-42.96875 13.960938-.742187 3.289062-5.425781 3.289062-6.167969 0-4.390624-19.445312-27.984374-27.109375-42.96875-13.960938-2.53125 2.222657-6.324218-.527343-4.992187-3.625 7.878906-18.3125-6.703125-38.382812-26.554687-36.550781-3.355469.308594-4.804688-4.144531-1.90625-5.867187 17.136718-10.183594 17.136718-34.992188 0-45.179688-2.894532-1.722656-1.449219-6.175781 1.90625-5.867187 19.851562 1.832031 34.433593-18.238281 26.554687-36.550781-1.332031-3.09375 2.460937-5.847657 4.992187-3.625 14.984376 13.148437 38.578126 5.484374 42.96875-13.960938.742188-3.289062 5.425782-3.289062 6.167969 0zm0 0"
                                                                fill="#fd8f31"></path>
                                                            <path
                                                                d="m160.566406 143.714844c-17.136718-10.183594-17.136718-34.992188 0-45.179688 2.898438-1.71875 1.449219-6.175781-1.90625-5.863281-19.847656 1.832031-34.429687-18.242187-26.554687-36.554687 1.332031-3.09375-2.457031-5.847657-4.992188-3.625-14.980469 13.152343-38.574219 5.484374-42.96875-13.960938-.742187-3.289062-5.425781-3.289062-6.167969 0-1.625 7.191406-5.875 12.761719-11.34375 16.277344 19.398438 15.589844 31.824219 39.496094 31.824219 66.320312 0 26.820313-12.425781 50.730469-31.824219 66.320313 5.46875 3.511719 9.71875 9.085937 11.34375 16.273437.742188 3.289063 5.429688 3.289063 6.167969 0 4.394531-19.445312 27.988281-27.109375 42.96875-13.960937 2.535157 2.222656 6.324219-.527344 4.992188-3.625-7.875-18.3125 6.707031-38.382813 26.554687-36.550781 3.355469.308593 4.804688-4.148438 1.90625-5.871094zm0 0"
                                                                fill="#ff7f1f"></path>
                                                            <path
                                                                d="m115.933594 121.125c0 19.261719-15.613282 34.871094-34.875 34.871094-19.257813 0-34.871094-15.609375-34.871094-34.871094 0-19.257812 15.613281-34.871094 34.871094-34.871094 19.261718 0 34.875 15.613282 34.875 34.871094zm0 0"
                                                                fill="#fccf3f"></path>
                                                            <path
                                                                d="m334.011719 315.355469v-253.195313c0-34.332031-27.832031-62.160156-62.160157-62.160156-34.332031 0-62.160156 27.828125-62.160156 62.160156v253.195313c-27.8125 19.566406-45.984375 51.910156-45.984375 88.5 0 59.726562 48.417969 108.144531 108.144531 108.144531 59.726563 0 108.144532-48.417969 108.144532-108.144531 0-36.589844-18.175782-68.933594-45.984375-88.5zm0 0"
                                                                fill="#d8d8d8"></path>
                                                            <path
                                                                d="m271.851562 476.582031c-41.183593 0-74.6875-33.507812-74.6875-74.6875 0-31.273437 19.433594-59.117187 48.59375-69.980469v-269.753906c0-14.390625 11.703126-26.09375 26.09375-26.09375 14.386719 0 26.09375 11.703125 26.09375 26.09375v269.753906c29.15625 10.859376 48.59375 38.703126 48.59375 69.976563 0 41.183594-33.503906 74.691406-74.6875 74.691406zm0 0"
                                                                fill="#f3e8d7"></path>
                                                            <path
                                                                d="m245.757812 109.871094v222.042968c-29.160156 10.859376-48.59375 38.703126-48.59375 69.976563 0 41.183594 33.503907 74.6875 74.6875 74.6875 41.183594 0 74.6875-33.503906 74.6875-74.6875 0-31.273437-19.4375-59.117187-48.59375-69.976563v-222.042968zm0 0"
                                                                fill="#fc4e51"></path>
                                                            <g fill="#0d6e9a">
                                                                <path
                                                                    d="m420.105469 180.21875h-44.824219c-4.269531 0-7.726562-3.460938-7.726562-7.726562 0-4.265626 3.457031-7.726563 7.726562-7.726563h44.824219c4.265625 0 7.726562 3.460937 7.726562 7.726563 0 4.265624-3.460937 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 117.597656h-20.351562c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726563 7.726562-7.726563h20.351562c4.265626 0 7.726563 3.460938 7.726563 7.726563s-3.460937 7.726562-7.726563 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 54.976562h-27.5625c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726562 7.726562-7.726562h27.5625c4.269531 0 7.726562 3.460937 7.726562 7.726562s-3.457031 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 305.460938h-27.5625c-4.269531 0-7.726562-3.460938-7.726562-7.726563 0-4.269531 3.457031-7.726563 7.726562-7.726563h27.5625c4.269531 0 7.726562 3.457032 7.726562 7.726563 0 4.265625-3.457031 7.726563-7.726562 7.726563zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 242.839844h-20.351562c-4.269531 0-7.726562-3.460938-7.726562-7.726563s3.457031-7.726562 7.726562-7.726562h20.351562c4.265626 0 7.726563 3.460937 7.726563 7.726562s-3.460937 7.726563-7.726563 7.726563zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m297.945312 331.914062v-222.042968h-52.1875v222.042968s36.074219 16.738282 36.074219 69.976563c0 29.601563-17.308593 55.234375-42.335937 67.3125 9.792968 4.726563 20.769531 7.375 32.351562 7.375 41.183594 0 74.691406-33.503906 74.691406-74.6875 0-31.273437-19.4375-59.113281-48.59375-69.976563zm0 0"
                                                                fill="#e5394b"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Temperatura</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm4LastData.outHumidity}}%</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-38 0 420 420.192" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m344.425781 175.695312c.210938 31.570313-16.507812 60.835938-43.816406 76.679688-27.304687 15.847656-61.007813 15.847656-88.3125 0-27.304687-15.84375-44.027344-45.109375-43.8125-76.679688 0-48.582031 87.972656-175.6874995 87.972656-175.6874995s87.96875 127.0976565 87.96875 175.6874995zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m256.453125 0s-5.3125 7.695312-13.3125 19.960938c24.585937 37.734374 74.660156 119.066406 74.660156 155.734374-.011719 43.441407-31.722656 80.375-74.660156 86.960938 25.355469 3.859375 51.136719-3.527344 70.597656-20.238281 19.460938-16.707031 30.664063-41.074219 30.6875-66.722657 0-48.589843-87.972656-175.695312-87.972656-175.695312zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m214.261719 66.601562c-22.570313 38.511719-45.761719 84.136719-45.761719 109.085938.003906 32.640625 18.074219 62.59375 46.949219 77.8125s63.800781 13.199219 90.726562-5.246094c-15.175781-52.742187-58.601562-128.222656-91.914062-181.652344zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m279.007812 280.734375c0 77.019531-62.4375 139.457031-139.457031 139.457031s-139.4531248-62.4375-139.4531248-139.457031 139.4531248-278.511719 139.4531248-278.511719 139.457031 201.472656 139.457031 278.511719zm0 0"
                                                                fill="#26a3b9"></path>
                                                            <g fill="#168aa5">
                                                                <path
                                                                    d="m227.484375 187.15625 19.304687 19.308594-156.308593 156.308594-19.308594-19.308594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m126.574219 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m229.730469 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m139.550781 2.222656s-7.769531 11.230469-19.636719 29.390625c38.402344 58.796875 119.820313 190.277344 119.820313 249.121094-.007813 69.425781-51.082031 128.273437-119.8125 138.054687 40.011719 5.71875 80.535156-6.214843 111.0625-32.703124 30.527344-26.492188 48.050781-64.929688 48.023437-105.351563 0-77.039063-139.457031-278.511719-139.457031-278.511719zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m208.058594 187.15625 19.304687 19.304688-156.308593 156.3125-19.308594-19.308594zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m107.148438 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m210.304688 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Humedad</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm4LastData.pressure}}hPa</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 0 512 512" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m256 512c-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.636719-74.980469-181.019531s26.628906-132.667969 74.980469-181.019531c48.351562-48.351563 112.640625-74.980469 181.019531-74.980469s132.667969 26.628906 181.019531 74.980469c48.351563 48.351562 74.980469 112.636719 74.980469 181.019531s-26.628906 132.667969-74.980469 181.019531c-48.351562 48.351563-112.640625 74.980469-181.019531 74.980469zm0-478.609375c-122.746094 0-222.609375 99.863281-222.609375 222.609375s99.863281 222.609375 222.609375 222.609375 222.609375-99.863281 222.609375-222.609375-99.863281-222.609375-222.609375-222.609375zm0 0"
                                                                fill="#736056"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-36.414063-36.414063-84.097656-54.839844-131.929687-55.308594-.613282-.070312-1.234376-.113281-1.867188-.113281s-1.253906.042969-1.867188.113281c-47.828124.46875-95.507812 18.894531-131.921874 55.300781-.003907.003907-.003907.003907-.007813.007813s-.003906.003906-.007813.007813c-36.410156 36.414062-54.832031 84.09375-55.300781 131.921874-.070312.613282-.113281 1.234376-.113281 1.867188s.042969 1.253906.113281 1.867188c.46875 47.832031 18.894531 95.515624 55.308594 131.929687 3.261719 3.261719 7.53125 4.890625 11.804687 4.890625 4.273438 0 8.546876-1.628906 11.804688-4.890625l23.613281-23.609375c6.519531-6.519531 6.519531-17.089844 0-23.613281-6.523437-6.519531-17.09375-6.519531-23.613281 0l-11.175781 11.175781c-19.242188-23.847656-30.410157-52.097656-33.503907-81.054688h15.738282c9.21875 0 16.695312-7.476562 16.695312-16.695312s-7.476562-16.695312-16.695312-16.695312h-15.726563c3.09375-28.953126 14.261719-57.199219 33.5-81.050782l11.171875 11.171875c3.257813 3.257813 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.546875-1.632812 11.804687-4.890625 6.519531-6.519531 6.519531-17.09375 0-23.613281l-11.171875-11.171875c23.851563-19.238281 52.097656-30.402344 81.050782-33.492187v15.722656c0 9.21875 7.476562 16.695312 16.695312 16.695312 9.222656 0 16.695312-7.476562 16.695312-16.695312v-15.722656c34.171876 3.648437 67.355469 18.53125 93.492188 44.664062 6.519531 6.519531 17.089844 6.519531 23.609375 0s6.519531-17.089844 0-23.609375zm0 0"
                                                                fill="#88cc2a"></path>
                                                            <path
                                                                d="m445.21875 256c0-.632812-.042969-1.253906-.113281-1.867188-.46875-47.832031-18.894531-95.515624-55.308594-131.929687-6.519531-6.519531-17.089844-6.519531-23.609375 0-6.523438 6.519531-6.523438 17.089844 0 23.609375 26.132812 26.136719 41.015625 59.324219 44.667969 93.492188h-15.726563c-9.21875 0-16.695312 7.476562-16.695312 16.695312s7.476562 16.695312 16.695312 16.695312h15.734375c-3.09375 28.957032-14.257812 57.207032-33.5 81.054688l-11.175781-11.175781c-6.519531-6.519531-17.09375-6.519531-23.613281 0s-6.519531 17.089843 0 23.613281l23.613281 23.609375c3.257812 3.261719 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.542968-1.628906 11.804687-4.890625 36.414063-36.414063 54.839844-84.097656 55.308594-131.929687.070312-.613282.113281-1.234376.113281-1.867188zm0 0"
                                                                fill="#ff9f19"></path>
                                                            <path
                                                                d="m272.695312 445.21875h-33.390624c-27.617188 0-50.085938-22.46875-50.085938-50.089844 0-27.617187 22.46875-50.085937 50.085938-50.085937h33.390624c27.617188 0 50.085938 22.46875 50.085938 50.085937 0 27.621094-22.46875 50.089844-50.085938 50.089844zm-33.390624-66.785156c-9.207032 0-16.695313 7.492187-16.695313 16.695312 0 9.207032 7.488281 16.695313 16.695313 16.695313h33.390624c9.207032 0 16.695313-7.488281 16.695313-16.695313 0-9.203125-7.488281-16.695312-16.695313-16.695312zm0 0"
                                                                fill="#bddeff"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-6.519531-6.519531-17.089844-6.519531-23.609375 0l-84.46875 84.46875c-7.699219-4.03125-16.445312-6.324219-25.71875-6.324219-30.6875 0-55.652344 24.964844-55.652344 55.652344s24.964844 55.652344 55.652344 55.652344 55.652344-24.964844 55.652344-55.652344c0-9.273438-2.292969-18.019531-6.324219-25.71875l84.46875-84.46875c6.519531-6.519531 6.519531-17.089844 0-23.609375zm-133.796875 156.058594c-12.273438 0-22.261719-9.988281-22.261719-22.261719s9.988281-22.261719 22.261719-22.261719 22.261719 9.988281 22.261719 22.261719-9.988281 22.261719-22.261719 22.261719zm0 0"
                                                                fill="#e6563a"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Presión</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm4LastData.rain}}mm</strong></p>
                                                        <svg height="45.541px" viewBox="0 -7 512 512" width="47.542px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m21.316406 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.0117188 1.125.03125 1.683594 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578124-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m21.316406 457.257812c-10.378906 0-20.753906-6.703124-21.2734372-20.097656-.0234376-.5625-.0429688-1.125-.0429688-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.570312 7.371094-4.292968 21.140625-4.292968 36.914062 0 .558594 0 1.121094.007812 1.683594.105469 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 438.421875c-12.742187 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574218-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 329.023438c-12.742187 7.371093-21.316406 21.136718-21.316406 36.914062 0 .5625.011719 1.125.03125 1.679688 1.039062 26.800781 41.527344 26.800781 42.566406 0 .023438-.554688.03125-1.117188.03125-1.679688.003906-15.777344-8.574218-29.542969-21.3125-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 387.71875c-10.378906 0-20.757812-6.707031-21.277344-20.101562-.019531-.558594-.039062-1.121094-.039062-1.683594 0-15.773438 8.578125-29.539063 21.316406-36.914063-2.570312 7.375-4.296875 21.140625-4.296875 36.914063 0 .5625 0 1.125.011719 1.683594.105469 13.394531 2.195312 20.101562 4.285156 20.101562zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m208.1875 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m208.1875 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m301.625 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.007812 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574219-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m301.625 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105468 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m489.035156 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.035156-1.117187.035156-1.683593 0-15.777344-8.578124-29.542969-21.316406-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m489.035156 497.117188c-10.378906 0-20.753906-6.703126-21.273437-20.097657-.023438-.5625-.042969-1.125-.042969-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m395.058594 398.5625c-12.738282 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527343 26.796875 42.566406 0 .023437-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m395.058594 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578124-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m408.25 181.570312c0 37.824219-30.664062 68.492188-68.503906 68.492188h-247.886719c-50.296875 0-91.082031-40.773438-91.082031-91.082031 0-50.296875 40.78125-91.082031 91.082031-91.082031 4.511719 0 8.945313.347656 13.292969.976562 20.570312-40.84375 62.871094-68.875 111.742187-68.875 59.128907 0 108.667969 41.054688 121.679688 96.210938 1.300781 5.503906 2.238281 11.144531 2.777343 16.894531v.011719c37.082032.851562 66.898438 31.167968 66.898438 68.453124zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m260.351562 7.769531c-13.539062-5.027343-28.183593-7.76562475-43.457031-7.76562475-48.871093 0-91.160156 28.03124975-111.742187 68.86328175v.011718c-4.347656-.628906-8.78125-.976562-13.292969-.976562-50.3125 0-91.082031 40.785156-91.082031 91.082031 0 50.3125 40.769531 91.082031 91.082031 91.082031h44.019531c-45.332031 0-82.066406-36.746094-82.066406-82.070312 0-40.214844 28.929688-73.683594 67.128906-80.703125 3.777344-.707031 7.648438-1.144531 11.597656-1.300781 1.105469-.035157 2.222657-.0625 3.339844-.0625 14.734375 0 28.570313 3.882812 40.515625 10.699218-1.660156-11.5625-13.613281-22.394531-31.238281-31.125 9.679688-32.726562 42.613281-63.078125 115.195312-57.734375zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m264.6875 245.550781c.496094.089844.789062.089844.835938.089844h-3.792969c.992187 0 1.972656-.03125 2.957031-.089844zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m512 313.621094c0 30.589844-24.800781 55.390625-55.402344 55.390625h-200.476562c-40.675782 0-73.660156-32.972657-73.660156-73.660157 0-40.679687 32.984374-73.660156 73.660156-73.660156 3.648437 0 7.238281.28125 10.75.789063 16.640625-33.035157 50.847656-55.703125 90.371094-55.703125 47.824218 0 87.886718 33.203125 98.410156 77.808594 1.050781 4.453124 1.808594 9.015624 2.246094 13.664062v.011719c29.988281.683593 54.101562 25.203125 54.101562 55.359375zm0 0"
                                                                fill="#d3ddea"></path>
                                                            <path
                                                                d="m392.386719 173.0625c-10.949219-4.066406-22.792969-6.28125-35.144531-6.28125-39.523438 0-73.722657 22.667969-90.371094 55.691406v.011719c-3.515625-.511719-7.101563-.789063-10.75-.789063-40.6875 0-73.660156 32.980469-73.660156 73.660157 0 40.6875 32.972656 73.660156 73.660156 73.660156h35.601562c-36.664062 0-66.371094-29.71875-66.371094-66.371094 0-32.527343 23.394532-59.59375 54.289063-65.269531 3.058594-.574219 6.1875-.925781 9.378906-1.050781.894531-.03125 1.800781-.050781 2.703125-.050781 11.917969 0 23.105469 3.140624 32.765625 8.648437-1.339843-9.351563-11.007812-18.109375-25.261719-25.167969 7.824219-26.46875 34.460938-51.015625 93.160157-46.691406zm0 0"
                                                                fill="#84abc1"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Lluvia</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo3" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm4LastData.dewpoint}}°C</strong>
                                                        </p>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                                            id="Layer_1" x="0px" y="0px" viewBox="0 0 497 497"
                                                            style="enable-background:new 0 0 497 497;"
                                                            xml:space="preserve" width="55px" height="55px">
                                                            <g>
                                                                <path style="fill:#59C2E8;"
                                                                    d="M183.391,58.325c-6.66-6.477-17.246-6.477-23.907,0C-78.787,290.047-14.684,497,171.438,497   S421.663,290.047,183.391,58.325z">
                                                                </path>
                                                                <path style="fill:#0097D1;"
                                                                    d="M201.438,477C19.441,477-45.878,279.117,174.095,53.691c-5.15-0.806-10.593,0.727-14.611,4.634   C-78.787,290.047-14.684,497,171.438,497c48.559,0,88.805-14.094,117.55-39.032C264.453,470.302,234.92,477,201.438,477z">
                                                                </path>
                                                                <g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M99.951,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5s7.5,3.358,7.5,7.5     v12.938C107.451,283.894,104.094,287.251,99.951,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M242.925,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5     s7.5,3.358,7.5,7.5v12.938C250.425,283.894,247.067,287.251,242.925,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M171.438,294.157c-9.041,0-17.53-3.78-23.291-10.371c-2.727-3.119-2.408-7.857,0.711-10.583     c3.117-2.726,7.856-2.408,10.582,0.711c2.912,3.332,7.285,5.242,11.998,5.242c4.712,0,9.085-1.911,11.997-5.242     c2.726-3.119,7.464-3.437,10.583-0.711c3.118,2.726,3.437,7.464,0.71,10.583C188.968,290.378,180.478,294.157,171.438,294.157z">
                                                                        </path>
                                                                    </g>
                                                                </g>
                                                                <path style="fill:#D5EBF6;"
                                                                    d="M420.006,262.727V50.657C420.006,22.68,397.326,0,369.35,0h0c-25.428,0-46.475,18.737-50.1,43.157   l4.08,7.5l-4.637,7.5V79.17l3.97,7.5l-3.97,7.5v21.014l4.637,7.5l-4.637,7.5v21.014l3.637,7.5l-3.637,7.5v21.013l3.637,7.5   l-3.637,7.5v60.516c-42.433,19.279-71.942,62.03-71.942,111.674C246.751,442.111,301.64,497,369.35,497   s122.599-54.889,122.599-122.599C491.948,324.757,462.439,282.006,420.006,262.727z">
                                                                </path>
                                                                <path style="fill:#FF6E80;"
                                                                    d="M369.349,462.349c-48.494,0-87.947-39.453-87.947-87.947c0-34.427,20.264-65.879,51.625-80.127   l20.318-9.23V50.656c0-8.825,7.18-16.005,16.005-16.005c8.825,0,16.006,7.18,16.006,16.005v234.388l20.318,9.231   c31.361,14.248,51.625,45.7,51.625,80.127C457.297,422.896,417.844,462.349,369.349,462.349z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,187.211v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H318.693   L318.693,187.211z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,151.198v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,151.198z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,115.184v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,115.184z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,50.657v7.5h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-34.095   C318.886,45.604,318.693,48.107,318.693,50.657z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,79.17v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,79.17z">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Punto de<br> rocío</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm4LastData.heatindex}}°C</strong>
                                                        </p>
                                                        <svg style="margin-right: 20px" height="70px"
                                                            viewBox="-78 0 512 512" width="75px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-13.621094-15.976562-21.824218-36.6875-21.824218-59.308593 0-50.550782 40.976562-91.527344 91.527343-91.527344 46.082031 0 84.203125 34.046875 90.585938 78.363281.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fab03c"></path>
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-.621094-4.300781-.9375-8.691406-.9375-13.160156 0-50.554687 40.984375-91.527344 91.527344-91.527344 27.925781 0 52.925781 12.503907 69.699219 32.214844.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <g fill="#f8e98e">
                                                                <path
                                                                    d="m150.769531 34.9375c-4.207031 0-7.613281-3.410156-7.613281-7.613281v-19.714844c0-4.203125 3.410156-7.609375 7.613281-7.609375s7.609375 3.40625 7.609375 7.609375v19.714844c0 4.203125-3.40625 7.613281-7.609375 7.613281zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m89.054688 51.476562c-2.632813 0-5.191407-1.367187-6.601563-3.808593l-9.855469-17.070313c-2.101562-3.640625-.855468-8.296875 2.785156-10.398437 3.640626-2.101563 8.296876-.855469 10.398438 2.785156l9.855469 17.074219c2.101562 3.636718.855469 8.292968-2.785157 10.394531-1.199218.691406-2.507812 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m43.855469 96.660156c-1.289063 0-2.597657-.332031-3.796875-1.023437l-17.074219-9.855469c-3.640625-2.101562-4.886719-6.757812-2.785156-10.398438 2.101562-3.640624 6.753906-4.886718 10.394531-2.785156l17.074219 9.855469c3.640625 2.105469 4.886719 6.757813 2.785156 10.398437-1.410156 2.445313-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m27.324219 158.378906h-19.714844c-4.203125 0-7.609375-3.40625-7.609375-7.609375s3.40625-7.613281 7.609375-7.613281h19.714844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m257.679688 96.660156c-2.628907 0-5.1875-1.367187-6.597657-3.808594-2.101562-3.640624-.855469-8.292968 2.785157-10.398437l17.074218-9.855469c3.636719-2.101562 8.292969-.855468 10.394532 2.785156 2.101562 3.640626.855468 8.296876-2.785157 10.398438l-17.074219 9.855469c-1.195312.691406-2.503906 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m26.796875 229.960938c-2.628906 0-5.1875-1.367188-6.597656-3.808594-2.101563-3.640625-.855469-8.296875 2.785156-10.394532l17.074219-9.859374c3.636718-2.097657 8.292968-.851563 10.394531 2.789062s.855469 8.292969-2.785156 10.394531l-17.074219 9.855469c-1.195312.695312-2.503906 1.023438-3.796875 1.023438zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m212.484375 51.476562c-1.292969 0-2.601563-.332031-3.800781-1.023437-3.640625-2.101563-4.886719-6.757813-2.785156-10.394531l9.855468-17.074219c2.101563-3.640625 6.757813-4.886719 10.398438-2.785156 3.640625 2.101562 4.886718 6.757812 2.785156 10.398437l-9.855469 17.070313c-1.410156 2.441406-3.96875 3.808593-6.597656 3.808593zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m79.183594 282.359375c-1.292969 0-2.601563-.328125-3.800782-1.019531-3.640624-2.105469-4.886718-6.757813-2.785156-10.398438l9.855469-17.074218c2.101563-3.640626 6.757813-4.886719 10.398437-2.785157 3.640626 2.101563 4.886719 6.757813 2.785157 10.398438l-9.855469 17.070312c-1.410156 2.441407-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m329.234375 240.59375c0 10.503906-8.519531 19.019531-19.023437 19.019531-10.503907 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.519531-19.023438 19.023438-19.023438 10.503906 0 19.023437 8.515626 19.023437 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m177.15625 240.59375c0 10.503906-8.515625 19.019531-19.019531 19.019531-10.507813 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.515625-19.023438 19.023438-19.023438 10.503906 0 19.019531 8.515626 19.019531 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m353.683594 439.070312v62.78125c0 5.605469-4.542969 10.148438-10.148438 10.148438h-218.722656c-5.601562 0-10.148438-4.542969-10.148438-10.148438v-62.78125c0-43.292968 23.027344-81.207031 57.492188-102.175781 18.074219-10.988281 39.308594-17.332031 62.019531-17.332031 22.5 0 43.535157 6.21875 61.5 17.03125 8.390625 5.042969 16.125 11.089844 23.007813 17.972656 21.625 21.636719 35 51.511719 35 84.503906zm0 0"
                                                                fill="#f0f5f7"></path>
                                                            <path
                                                                d="m172.503906 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.609375-7.609375 4.203125 0 7.613281 3.398437 7.613281 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m311.171875 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.613281-7.609375 4.199219 0 7.609375 3.398437 7.609375 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m343.539062 511.992188h-47.589843v-67.089844h57.738281v56.945312c0 5.601563-4.542969 10.144532-10.148438 10.144532zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m295.949219 444.90625h15.222656v67.082031h-15.222656zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m172.402344 444.90625v67.082031h-47.589844c-5.601562 0-10.148438-4.542969-10.148438-10.148437v-56.933594zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m157.28125 444.90625h15.121094v67.082031h-15.121094zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m295.679688 336.59375c-13.59375 19.570312-36.234376 32.367188-61.863282 32.367188-25.496094 0-48.042968-12.671876-61.65625-32.066407 18.070313-10.992187 39.304688-17.328125 62.015625-17.328125 22.5 0 43.535157 6.210938 61.503907 17.027344zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m164.230469 174.1875v85.160156c0 38.625 31.316406 69.941406 69.941406 69.941406 38.628906 0 69.941406-31.316406 69.941406-69.941406v-85.160156zm0 0"
                                                                fill="#fbdbb7"></path>
                                                            <path
                                                                d="m304.113281 174.1875v85.15625c0 19.3125-7.828125 36.808594-20.488281 49.460938-12.652344 12.652343-30.140625 20.480468-49.453125 20.480468-3.757813 0-7.445313-.292968-11.050781-.875 14.886718-2.355468 28.207031-9.421875 38.402344-19.605468 12.652343-12.652344 20.480468-30.148438 20.480468-49.460938v-85.15625zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m234.175781 119.171875c-44.792969 0-81.101562 36.308594-81.101562 81.101563v17.472656c0 6.152344 5.417969 10.886718 11.515625 10.054687 20.5-2.789062 62.964844-10.910156 82.292968-31.78125 7.195313 11.8125 23.039063 26.890625 57.433594 29.546875 5.90625.457032 10.957032-4.199218 10.957032-10.121094v-15.171874c0-44.792969-36.308594-81.101563-81.097657-81.101563zm0 0"
                                                                fill="#82807f"></path>
                                                            <path
                                                                d="m315.273438 200.277344v15.164062c0 5.929688-5.066407 10.578125-10.980469 10.121094-4.617188-.359375-8.902344-.941406-12.878907-1.710938v-23.574218c0-40.738282-30.03125-74.460938-69.175781-80.238282 3.898438-.578124 7.875-.871093 11.9375-.871093 44.785157 0 81.097657 36.3125 81.097657 81.109375zm0 0"
                                                                fill="#6d6d6d"></path>
                                                            <path
                                                                d="m201.347656 248.203125c-4.207031 0-7.660156-3.40625-7.660156-7.609375s3.363281-7.613281 7.570312-7.613281h.089844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m267.089844 248.203125c-4.203125 0-7.65625-3.40625-7.65625-7.609375s3.363281-7.613281 7.566406-7.613281h.089844c4.207031 0 7.613281 3.410156 7.613281 7.613281s-3.410156 7.609375-7.613281 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m241.503906 292.371094h-14.65625c-4.207031 0-7.613281-3.40625-7.613281-7.609375v-44.167969c0-4.203125 3.410156-7.613281 7.613281-7.613281s7.609375 3.410156 7.609375 7.613281v36.554688h7.046875c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.410156 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <path
                                                                d="m323.347656 172.90625c0 10.621094-8.609375 19.230469-19.230468 19.230469-10.621094 0-19.230469-8.609375-19.230469-19.230469 0-6.96875 8.28125-20.652344 13.972656-29.230469 2.496094-3.761719 8.015625-3.761719 10.511719 0 5.695312 8.578125 13.976562 22.261719 13.976562 29.230469zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m323.34375 172.90625c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.660156 0-5.199219-.542969-7.507812-1.519531 6.898437-2.921876 11.730469-9.753907 11.730469-17.710938 0-6.09375-6.328126-17.316406-11.722657-25.777344.773438-1.214844 1.527344-2.367187 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.519532-.011719 5.691406 8.582031 13.972656 22.261719 13.972656 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m356.394531 368.648438c0 10.621093-8.613281 19.230468-19.234375 19.230468s-19.230468-8.609375-19.230468-19.230468c0-6.972657 8.285156-20.652344 13.976562-29.234376 2.496094-3.761718 8.015625-3.761718 10.511719 0 5.691406 8.582032 13.976562 22.261719 13.976562 29.234376zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m356.390625 368.648438c0 10.617187-8.605469 19.230468-19.230469 19.230468-2.664062 0-5.199218-.542968-7.507812-1.519531 6.894531-2.921875 11.730468-9.753906 11.730468-17.710937 0-6.09375-6.328124-17.320313-11.722656-25.78125.769532-1.210938 1.523438-2.367188 2.238282-3.441407 2.496093-3.765625 8.019531-3.777343 10.515624-.011719 5.695313 8.582032 13.976563 22.261719 13.976563 29.234376zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.621094-8.609375 19.230469-19.230469 19.230469s-19.230469-8.609375-19.230469-19.230469c0-6.96875 8.28125-20.652344 13.976563-29.234375 2.496093-3.757813 8.015625-3.757813 10.511719 0 5.691406 8.582031 13.972656 22.265625 13.972656 29.234375zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.664063 0-5.199219-.542969-7.507813-1.519531 6.894532-2.921876 11.726563-9.753907 11.726563-17.710938 0-6.09375-6.328125-17.316406-11.71875-25.777344.769531-1.214844 1.523437-2.371094 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.515625-.011719 5.695313 8.582031 13.976563 22.261719 13.976563 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Sensación<br> térmica</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{cmm4LastData.windSpeed}}Km/h</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 -23 511.99037 511" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m238.945312 182.152344h-213.332031c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h213.332031c20.527344.90625 39.605469-10.542969 48.460938-29.082032 9.6875-19.246094 8.308594-42.207031-3.609375-60.160156-9.566406-12.449219-24.515625-17.066406-43.175781-13.320312-14.421875 1.917968-24.910156 14.648437-24.03125 29.167968 1.066406 10.726563 9.738281 22.1875 30.890625 22.1875 2.355469 0 4.265625-1.910156 4.265625-4.269531 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535157-3.820312-8.535157-8.53125 0-4.714844 3.820313-8.535156 8.535157-8.535156 11.78125 0 21.332031 9.550781 21.332031 21.332031 0 11.785156-9.550781 21.335937-21.332031 21.335937-30.5 0-46.019531-18.875-47.871094-37.546874-1.890625-23.285157 14.539063-44.070313 37.632813-47.609376 22.140624-6.042968 45.753906 1.675782 60.054687 19.628907 15.925781 23.097656 17.992187 53.039062 5.386719 78.105469-11.75 24.332031-36.726563 39.472656-63.738282 38.628906zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m269.742188 430.933594c-5.183594-.019532-10.355469-.554688-15.433594-1.597656-23.097656-3.535157-39.527344-24.324219-37.632813-47.613282 1.851563-18.671875 17.375-37.546875 47.871094-37.546875 11.78125 0 21.332031 9.550781 21.332031 21.332031s-9.550781 21.335938-21.332031 21.335938c-4.710937 0-8.53125-3.824219-8.53125-8.535156 0-4.714844 3.820313-8.535156 8.53125-8.535156 2.355469 0 4.265625-1.910157 4.265625-4.265626 0-2.355468-1.910156-4.265624-4.265625-4.265624-21.152344 0-29.867187 11.46875-30.890625 22.1875-.878906 14.535156 9.628906 27.273437 24.0625 29.183593 15.765625 4.65625 32.785156-.597656 43.179688-13.328125 11.917968-17.953125 13.296874-40.914062 3.609374-60.160156-8.859374-18.550781-27.957031-30.003906-48.492187-29.082031h-213.335937c-4.710938 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820312-8.53125 8.53125-8.53125h213.335937c27.015625-.859374 52.003906 14.265626 63.769531 38.601563 12.605469 25.066406 10.539063 55.011719-5.386718 78.109375-10.601563 13.847656-27.21875 21.753906-44.65625 21.246094zm0 0"
                                                                fill="#bdc3c7"></path>
                                                            <path
                                                                d="m375.480469 224.820312h-213.332031c-4.714844 0-8.535157-3.820312-8.535157-8.535156 0-4.710937 3.820313-8.53125 8.535157-8.53125h213.332031c20.523437.90625 39.605469-10.542968 48.460937-29.082031 9.683594-19.246094 8.308594-42.210937-3.609375-60.160156-9.566406-12.460938-24.535156-17.066407-43.179687-13.320313-14.417969 1.914063-24.90625 14.644532-24.03125 29.167969 1.070312 10.726563 9.738281 22.183594 30.894531 22.183594 2.355469 0 4.265625-1.910157 4.265625-4.265625 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535156-3.820313-8.535156-8.535157 0-4.710937 3.820312-8.53125 8.535156-8.53125 11.78125 0 21.332031 9.550782 21.332031 21.332032s-9.550781 21.332031-21.332031 21.332031c-30.5 0-46.023437-18.875-47.875-37.546875-1.890625-23.285156 14.539063-44.070312 37.632813-47.605469 22.144531-6.046875 45.757812 1.671875 60.058593 19.625 15.925781 23.097657 17.988281 53.042969 5.382813 78.105469-11.75 24.335938-36.722656 39.472656-63.734375 38.632812zm0 0"
                                                                fill="#7f8c8d"></path>
                                                            <path
                                                                d="m426.875 453.785156c-7.640625-.035156-15.261719-.824218-22.75-2.355468-33.242188-4.996094-56.972656-34.835938-54.355469-68.351563 2.457031-24.796875 22.050781-53.757813 69.1875-53.757813 15.414063.265626 27.773438 12.839844 27.773438 28.257813s-12.359375 27.988281-27.773438 28.257813c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125 6.0625-.167969 10.886719-5.128906 10.886719-11.191406s-4.824219-11.027344-10.886719-11.191406c-35.6875 0-50.347656 19.820312-52.199219 38.398437-1.625 24.75 16.152344 46.546875 40.722657 49.9375 38.664062 7.722656 60.015625-7.824218 71.097656-22.246094 19.414063-25.257812 21.898437-65.382812 6.042969-97.570312-14.375-30.1875-45.410156-48.851562-78.8125-47.394531h-397.261719c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125h397.261719c39.863281-1.292968 76.75 21.015626 94.121094 56.917969 18.628906 37.792969 15.410156 85.332031-7.816407 115.515625-15.507812 20.191406-39.785156 31.695313-65.238281 30.90625zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m40.34375 434.203125c-3.925781-.128906.757812-21.335937 1.234375-23.628906 2.132813-12.183594 8.472656-23.234375 17.921875-31.214844 26.070312-21.597656 62.0625-20.363281 93.867188-18.484375 2.175781.128906 16.492187 1.28125 17.3125-.621094-14.652344 33.890625-31.324219 75.226563-61.933594 97.890625-8.882813 6.957031-20.480469 9.414063-31.421875 6.65625-7.925781-2.652343-14.917969-7.535156-20.136719-14.0625-4.667969-5.144531-8.765625-16.28125-16.84375-16.535156zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m8.546875 465.988281c-3.59375.003907-6.808594-2.242187-8.035156-5.621093-1.226563-3.378907-.203125-7.164063 2.558593-9.464844 2.164063-1.765625 53.382813-44.160156 97.636719-53.019532 4.585938-.847656 9.007813 2.144532 9.921875 6.71875.914063 4.574219-2.015625 9.035157-6.578125 10.015626-40.035156 8.066406-89.53125 49.023437-90.035156 49.390624-1.535156 1.28125-3.46875 1.980469-5.46875 1.980469zm0 0"
                                                                fill="#805333"></path>
                                                            <path
                                                                d="m132.648438 105.386719c.953124 3.796875-20.753907 5.121093-23.042969 5.324219-12.296875 1.316406-24.664063-1.730469-34.941407-8.609376-27.964843-19.105468-36.710937-54.03125-43.683593-85.128906-.476563-2.132812-3.308594-16.214844-5.367188-16.476562 36.625 4.734375 80.949219 9.386718 111.179688 32.535156 9.136719 6.617188 14.699219 17.085938 15.0625 28.359375-.355469 8.359375-3.113281 16.441406-7.945313 23.277344-3.675781 5.863281-13.242187 12.894531-11.261718 20.71875zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m163.75 138.0625c-1.703125.003906-3.371094-.511719-4.777344-1.476562-2.328125-1.578126-57.171875-39.125-77.890625-79.242188-2.164062-4.1875-.523437-9.335938 3.664063-11.5 4.1875-2.160156 9.335937-.519531 11.496094 3.667969 18.773437 36.25 71.785156 72.601562 72.320312 72.957031 3.101562 2.109375 4.464844 5.992188 3.359375 9.574219s-4.421875 6.023437-8.171875 6.019531zm0 0"
                                                                fill="#805333"></path>
                                                            <g fill="#cb8252">
                                                                <path
                                                                    d="m366.945312 28.441406c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714843 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820313-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m486.414062 11.375c0 4.714844-3.820312 8.535156-8.535156 8.535156-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125 4.714844 0 8.535156 3.820312 8.535156 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m460.8125 207.640625c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m366.945312 310.042969c0 4.710937-3.820312 8.53125-8.53125 8.53125-4.714843 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820313-8.535157 8.535156-8.535157 4.710938 0 8.53125 3.820313 8.53125 8.535157zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m324.28125 455.109375c0 4.710937-3.820312 8.53125-8.535156 8.53125-4.710938 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820312-8.535156 8.53125-8.535156 4.714844 0 8.535156 3.820312 8.535156 8.535156zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m187.746094 412.441406c0 4.714844-3.820313 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710937 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m93.878906 224.707031h-85.332031c-4.710937 0-8.53125-3.820312-8.53125-8.53125 0-4.714843 3.820313-8.535156 8.53125-8.535156h85.332031c4.714844 0 8.535156 3.820313 8.535156 8.535156 0 4.710938-3.820312 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#bdc3c7"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Velocidad del<br> viento</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{obtenerDireccionViento(cmm4LastData.windDir)}}</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-4 0 511 511.99978" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m51.703125 51.097656h17.066406v51.203125h-17.066406zm0 0"
                                                                fill="#5d9aa4"></path>
                                                            <path
                                                                d="m43.167969 272.976562h34.136719v230.414063h-34.136719zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m145.574219 42.5625h-136.539063c-4.714844 0-8.535156-3.820312-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h136.539063c4.714843 0 8.535156 3.820312 8.535156 8.535156 0 4.710938-3.820313 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m128.507812 68.164062c-4.714843 0-8.535156-3.820312-8.53125-8.535156 0-2.261718.898438-4.429687 2.496094-6.03125l19.570313-19.566406-19.570313-19.570312c-3.273437-3.386719-3.179687-8.792969.210938-12.066407 3.308594-3.191406 8.550781-3.191406 11.855468 0l25.601563 25.601563c3.332031 3.332031 3.332031 8.734375 0 12.066406l-25.601563 25.601562c-1.597656 1.601563-3.769531 2.5-6.03125 2.5zm0 0"
                                                                fill="#b9b9b9"></path>
                                                            <path
                                                                d="m34.636719 76.699219h51.203125v213.347656h-51.203125zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m34.636719 162.039062c-4.714844 0-8.535157-3.820312-8.535157-8.535156v-42.667968c0-4.714844 3.820313-8.535157 8.535157-8.535157h128.007812c4.710938.011719 8.523438 3.839844 8.511719 8.554688-.007812 3.660156-2.347656 6.90625-5.816406 8.070312l-128.007813 42.667969c-.867187.296875-1.78125.445312-2.695312.445312zm8.53125-42.671874v22.292968l66.890625-22.292968zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m162.644531 272.976562h-128.007812c-4.714844 0-8.535157-3.820312-8.535157-8.53125v-42.671874c.003907-2.742188 1.324219-5.316407 3.550782-6.917969 2.214844-1.621094 5.082031-2.058594 7.679687-1.171875l128.007813 42.671875c4.46875 1.5 6.875 6.335937 5.375 10.804687-1.164063 3.46875-4.414063 5.808594-8.070313 5.816406zm-119.476562-17.066406h66.890625l-66.890625-22.28125zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m85.839844 34.03125c0 14.140625-11.464844 25.601562-25.601563 25.601562-14.140625 0-25.601562-11.460937-25.601562-25.601562s11.460937-25.601562 25.601562-25.601562c14.136719 0 25.601563 11.460937 25.601563 25.601562zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m290.652344 298.578125c-14.464844.015625-27.355469 9.128906-32.191406 22.761719-1.570313 4.445312.761718 9.320312 5.207031 10.890625 4.441406 1.566406 9.316406-.761719 10.886719-5.207031 3.144531-8.886719 12.898437-13.542969 21.785156-10.398438 8.882812 3.144531 13.539062 12.894531 10.398437 21.78125-2.414062 6.816406-8.859375 11.375-16.085937 11.375h-42.671875c-4.710938 0-8.53125 3.824219-8.53125 8.535156 0 4.714844 3.820312 8.535156 8.53125 8.535156h42.671875c18.851562 0 34.132812-15.285156 34.132812-34.136718 0-18.851563-15.28125-34.136719-34.132812-34.136719zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m444.257812 400.984375h-93.871093c-4.710938 0-8.535157-3.820313-8.535157-8.53125 0-4.714844 3.824219-8.535156 8.535157-8.535156h93.871093c4.714844 0 8.535157 3.820312 8.535157 8.535156 0 4.710937-3.820313 8.53125-8.535157 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m316.253906 400.984375h-25.601562c-4.714844 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h25.601562c4.710938 0 8.53125 3.820312 8.53125 8.535156 0 4.710937-3.820312 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m256.515625 400.984375h-85.339844c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h85.339844c4.710937 0 8.53125 3.820312 8.53125 8.535156.003906 4.710937-3.820313 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m205.3125 435.121094h-34.136719c-4.710937 0-8.53125-3.820313-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125h34.136719c4.710938 0 8.53125 3.820312 8.53125 8.53125.003906 4.714843-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m213.847656 366.851562h-42.671875c-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710937 3.820313-8.535156 8.53125-8.535156h42.671875c4.710938 0 8.53125 3.824219 8.53125 8.535156 0 4.714844-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m273.582031 435.121094h-34.132812c-4.714844 0-8.535157-3.820313-8.535157-8.535156 0-4.710938 3.820313-8.53125 8.535157-8.53125h34.132812c4.714844 0 8.535157 3.820312 8.535157 8.53125 0 4.714843-3.820313 8.535156-8.535157 8.535156zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m407.042969 418.113281c-.101563 0-.195313-.058593-.296875-.058593h-90.492188c-4.714844 0-8.535156 3.820312-8.535156 8.53125 0 4.714843 3.820312 8.535156 8.535156 8.535156h88.222656.167969 1.210938c16.496093.003906 29.867187 13.378906 29.863281 29.875s-13.378906 29.867187-29.875 29.863281c-15.207031-.003906-27.980469-11.429687-29.675781-26.542969-.519531-4.683594-4.738281-8.0625-9.421875-7.542968-4.683594.519531-8.0625 4.738281-7.542969 9.421874 2.835937 25.765626 26.023437 44.355469 51.789063 41.519532 25.765624-2.835938 44.355468-26.023438 41.519531-51.789063-2.570313-23.34375-21.992188-41.195312-45.46875-41.789062zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m503.996094 230.308594-256.015625 39.828125-85.335938 2.839843v-170.675781l85.335938 2.84375 256.015625 39.824219zm0 0"
                                                                fill="#ea6b65"></path>
                                                            <g fill="#d9d9d9">
                                                                <path
                                                                    d="m418.65625 243.585938 85.339844-13.277344v-85.339844l-85.339844-13.277344zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m333.320312 256.859375v-138.4375l-85.339843-13.277344v164.992188zm0 0">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Dirección del<br> viento</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="cmm5" class="col-xl-4"> <!-- -->
                <div class="container py-5 h-100">

                    <div class="row d-flex justify-content-center align-items-center h-100" style="color: #282828;">
                        <div class="col-xl-11 bgc fullh" *ngIf="cmm5Loading" style="min-height: 55vh;">
                            <mat-progress-bar mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                        <div class="col-xl-11 bgc fullh" *ngIf="!cmm5Loading">

                            <div class="card mb-4 gradient-custom" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo1" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <h2 class="display-2"><strong>{{cmm5Name}}</strong></h2>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">calendar_today</mat-icon>
                                                                {{getDia(cmm5LastData.formattedDateTime)}}</strong></span>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">access_time</mat-icon>
                                                                {{getHora(cmm5LastData.formattedDateTime)}}</strong></span>
                                                        <br>
                                                        <button mat-button (click)="isOpenCmmData(5)" class="mt-5 pt-1">
                                                            <i class="fa-solid fa-clock-rotate-left fa-2x text-muted historial-i"
                                                                style="color: #ddd;"></i></button>
                                                    </div>
                                                    <div>
                                                        <div class="ml-4">
                                                            <div class="mat-elevation-z2" style="border-radius: 20px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="120"
                                                                    height="120"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    version="1.1" x="0px" y="0px" viewBox="0 0 512 512"
                                                                    enable-background="new 0 0 512 512"
                                                                    xml:space="preserve">
                                                                    <g id="Ebene_3" display="none">
                                                                        <rect x="-2020" y="-2565" display="inline"
                                                                            fill="#4D4D4E" width="5364" height="3336" />
                                                                    </g>
                                                                    <g id="Grid" display="none">
                                                                        <g display="inline">
                                                                            <g>
                                                                                <path fill="#B3B3B3"
                                                                                    d="M511,1v510H1V1H511 M512,0H0v512h512V0L512,0z" />
                                                                            </g>
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="6" y1="6"
                                                                                x2="506" y2="506" />
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="506" y1="6"
                                                                                x2="6" y2="506" />
                                                                            <circle fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" cx="256" cy="256"
                                                                                r="250" />
                                                                        </g>
                                                                    </g>
                                                                    <g id="Ebene_2">
                                                                        <circle fill="#84abc1" cx="353.935" cy="183.958"
                                                                            r="21.065" />
                                                                        <circle fill="#84abc1" cx="428.935" cy="183.958"
                                                                            r="21.065" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="87.37" x2="255.997" y2="471.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="256.003"
                                                                            y1="331.37" x2="316.874" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="316.874"
                                                                            y1="236.37" x2="390.997" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="390.997"
                                                                            y1="236.37" x2="390.997" y2="198.037" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="361.371" x2="165.871" y2="400.372" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="165.871"
                                                                            y1="400.372" x2="165.871" y2="354.371" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="206.871" y2="324.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="206.871"
                                                                            y1="324.368" x2="206.871" y2="298.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="124.871" y2="298.368" />

                                                                        <polygon fill="none" stroke="#7fed6f"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="   184.997,165.621 154.997,182.364 126.692,165.621 126.692,86.624 184.997,86.624  " />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="202.302"
                                                                            y1="118.364" x2="255.997" y2="172.059" />
                                                                    </g>
                                                                    <g id="txt">
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <br>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card mb-4" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo2" class="">
                                        <!-- Indicators -->
                                        <div>
                                            <div>
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm5LastData.outTemp}}°C</strong>
                                                        </p>
                                                        <svg style="margin-right: 20px" height="60px"
                                                            viewBox="-42 0 512 512" width="60px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m84.144531 38.53125c4.394531 19.445312 27.988281 27.109375 42.96875 13.960938 2.53125-2.222657 6.324219.53125 4.992188 3.625-7.878907 18.3125 6.703125 38.382812 26.554687 36.550781 3.355469-.308594 4.804688 4.144531 1.90625 5.867187-17.136718 10.1875-17.136718 34.996094 0 45.179688 2.898438 1.722656 1.449219 6.175781-1.90625 5.867187-19.851562-1.832031-34.433594 18.238281-26.554687 36.550781 1.332031 3.097657-2.460938 5.847657-4.992188 3.625-14.980469-13.148437-38.578125-5.480468-42.96875 13.960938-.742187 3.289062-5.425781 3.289062-6.167969 0-4.390624-19.445312-27.984374-27.109375-42.96875-13.960938-2.53125 2.222657-6.324218-.527343-4.992187-3.625 7.878906-18.3125-6.703125-38.382812-26.554687-36.550781-3.355469.308594-4.804688-4.144531-1.90625-5.867187 17.136718-10.183594 17.136718-34.992188 0-45.179688-2.894532-1.722656-1.449219-6.175781 1.90625-5.867187 19.851562 1.832031 34.433593-18.238281 26.554687-36.550781-1.332031-3.09375 2.460937-5.847657 4.992187-3.625 14.984376 13.148437 38.578126 5.484374 42.96875-13.960938.742188-3.289062 5.425782-3.289062 6.167969 0zm0 0"
                                                                fill="#fd8f31"></path>
                                                            <path
                                                                d="m160.566406 143.714844c-17.136718-10.183594-17.136718-34.992188 0-45.179688 2.898438-1.71875 1.449219-6.175781-1.90625-5.863281-19.847656 1.832031-34.429687-18.242187-26.554687-36.554687 1.332031-3.09375-2.457031-5.847657-4.992188-3.625-14.980469 13.152343-38.574219 5.484374-42.96875-13.960938-.742187-3.289062-5.425781-3.289062-6.167969 0-1.625 7.191406-5.875 12.761719-11.34375 16.277344 19.398438 15.589844 31.824219 39.496094 31.824219 66.320312 0 26.820313-12.425781 50.730469-31.824219 66.320313 5.46875 3.511719 9.71875 9.085937 11.34375 16.273437.742188 3.289063 5.429688 3.289063 6.167969 0 4.394531-19.445312 27.988281-27.109375 42.96875-13.960937 2.535157 2.222656 6.324219-.527344 4.992188-3.625-7.875-18.3125 6.707031-38.382813 26.554687-36.550781 3.355469.308593 4.804688-4.148438 1.90625-5.871094zm0 0"
                                                                fill="#ff7f1f"></path>
                                                            <path
                                                                d="m115.933594 121.125c0 19.261719-15.613282 34.871094-34.875 34.871094-19.257813 0-34.871094-15.609375-34.871094-34.871094 0-19.257812 15.613281-34.871094 34.871094-34.871094 19.261718 0 34.875 15.613282 34.875 34.871094zm0 0"
                                                                fill="#fccf3f"></path>
                                                            <path
                                                                d="m334.011719 315.355469v-253.195313c0-34.332031-27.832031-62.160156-62.160157-62.160156-34.332031 0-62.160156 27.828125-62.160156 62.160156v253.195313c-27.8125 19.566406-45.984375 51.910156-45.984375 88.5 0 59.726562 48.417969 108.144531 108.144531 108.144531 59.726563 0 108.144532-48.417969 108.144532-108.144531 0-36.589844-18.175782-68.933594-45.984375-88.5zm0 0"
                                                                fill="#d8d8d8"></path>
                                                            <path
                                                                d="m271.851562 476.582031c-41.183593 0-74.6875-33.507812-74.6875-74.6875 0-31.273437 19.433594-59.117187 48.59375-69.980469v-269.753906c0-14.390625 11.703126-26.09375 26.09375-26.09375 14.386719 0 26.09375 11.703125 26.09375 26.09375v269.753906c29.15625 10.859376 48.59375 38.703126 48.59375 69.976563 0 41.183594-33.503906 74.691406-74.6875 74.691406zm0 0"
                                                                fill="#f3e8d7"></path>
                                                            <path
                                                                d="m245.757812 109.871094v222.042968c-29.160156 10.859376-48.59375 38.703126-48.59375 69.976563 0 41.183594 33.503907 74.6875 74.6875 74.6875 41.183594 0 74.6875-33.503906 74.6875-74.6875 0-31.273437-19.4375-59.117187-48.59375-69.976563v-222.042968zm0 0"
                                                                fill="#fc4e51"></path>
                                                            <g fill="#0d6e9a">
                                                                <path
                                                                    d="m420.105469 180.21875h-44.824219c-4.269531 0-7.726562-3.460938-7.726562-7.726562 0-4.265626 3.457031-7.726563 7.726562-7.726563h44.824219c4.265625 0 7.726562 3.460937 7.726562 7.726563 0 4.265624-3.460937 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 117.597656h-20.351562c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726563 7.726562-7.726563h20.351562c4.265626 0 7.726563 3.460938 7.726563 7.726563s-3.460937 7.726562-7.726563 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 54.976562h-27.5625c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726562 7.726562-7.726562h27.5625c4.269531 0 7.726562 3.460937 7.726562 7.726562s-3.457031 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 305.460938h-27.5625c-4.269531 0-7.726562-3.460938-7.726562-7.726563 0-4.269531 3.457031-7.726563 7.726562-7.726563h27.5625c4.269531 0 7.726562 3.457032 7.726562 7.726563 0 4.265625-3.457031 7.726563-7.726562 7.726563zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 242.839844h-20.351562c-4.269531 0-7.726562-3.460938-7.726562-7.726563s3.457031-7.726562 7.726562-7.726562h20.351562c4.265626 0 7.726563 3.460937 7.726563 7.726562s-3.460937 7.726563-7.726563 7.726563zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m297.945312 331.914062v-222.042968h-52.1875v222.042968s36.074219 16.738282 36.074219 69.976563c0 29.601563-17.308593 55.234375-42.335937 67.3125 9.792968 4.726563 20.769531 7.375 32.351562 7.375 41.183594 0 74.691406-33.503906 74.691406-74.6875 0-31.273437-19.4375-59.113281-48.59375-69.976563zm0 0"
                                                                fill="#e5394b"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Temperatura</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm5LastData.outHumidity}}%</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-38 0 420 420.192" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m344.425781 175.695312c.210938 31.570313-16.507812 60.835938-43.816406 76.679688-27.304687 15.847656-61.007813 15.847656-88.3125 0-27.304687-15.84375-44.027344-45.109375-43.8125-76.679688 0-48.582031 87.972656-175.6874995 87.972656-175.6874995s87.96875 127.0976565 87.96875 175.6874995zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m256.453125 0s-5.3125 7.695312-13.3125 19.960938c24.585937 37.734374 74.660156 119.066406 74.660156 155.734374-.011719 43.441407-31.722656 80.375-74.660156 86.960938 25.355469 3.859375 51.136719-3.527344 70.597656-20.238281 19.460938-16.707031 30.664063-41.074219 30.6875-66.722657 0-48.589843-87.972656-175.695312-87.972656-175.695312zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m214.261719 66.601562c-22.570313 38.511719-45.761719 84.136719-45.761719 109.085938.003906 32.640625 18.074219 62.59375 46.949219 77.8125s63.800781 13.199219 90.726562-5.246094c-15.175781-52.742187-58.601562-128.222656-91.914062-181.652344zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m279.007812 280.734375c0 77.019531-62.4375 139.457031-139.457031 139.457031s-139.4531248-62.4375-139.4531248-139.457031 139.4531248-278.511719 139.4531248-278.511719 139.457031 201.472656 139.457031 278.511719zm0 0"
                                                                fill="#26a3b9"></path>
                                                            <g fill="#168aa5">
                                                                <path
                                                                    d="m227.484375 187.15625 19.304687 19.308594-156.308593 156.308594-19.308594-19.308594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m126.574219 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m229.730469 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m139.550781 2.222656s-7.769531 11.230469-19.636719 29.390625c38.402344 58.796875 119.820313 190.277344 119.820313 249.121094-.007813 69.425781-51.082031 128.273437-119.8125 138.054687 40.011719 5.71875 80.535156-6.214843 111.0625-32.703124 30.527344-26.492188 48.050781-64.929688 48.023437-105.351563 0-77.039063-139.457031-278.511719-139.457031-278.511719zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m208.058594 187.15625 19.304687 19.304688-156.308593 156.3125-19.308594-19.308594zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m107.148438 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m210.304688 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Humedad</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm5LastData.pressure}}hPa</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 0 512 512" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m256 512c-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.636719-74.980469-181.019531s26.628906-132.667969 74.980469-181.019531c48.351562-48.351563 112.640625-74.980469 181.019531-74.980469s132.667969 26.628906 181.019531 74.980469c48.351563 48.351562 74.980469 112.636719 74.980469 181.019531s-26.628906 132.667969-74.980469 181.019531c-48.351562 48.351563-112.640625 74.980469-181.019531 74.980469zm0-478.609375c-122.746094 0-222.609375 99.863281-222.609375 222.609375s99.863281 222.609375 222.609375 222.609375 222.609375-99.863281 222.609375-222.609375-99.863281-222.609375-222.609375-222.609375zm0 0"
                                                                fill="#736056"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-36.414063-36.414063-84.097656-54.839844-131.929687-55.308594-.613282-.070312-1.234376-.113281-1.867188-.113281s-1.253906.042969-1.867188.113281c-47.828124.46875-95.507812 18.894531-131.921874 55.300781-.003907.003907-.003907.003907-.007813.007813s-.003906.003906-.007813.007813c-36.410156 36.414062-54.832031 84.09375-55.300781 131.921874-.070312.613282-.113281 1.234376-.113281 1.867188s.042969 1.253906.113281 1.867188c.46875 47.832031 18.894531 95.515624 55.308594 131.929687 3.261719 3.261719 7.53125 4.890625 11.804687 4.890625 4.273438 0 8.546876-1.628906 11.804688-4.890625l23.613281-23.609375c6.519531-6.519531 6.519531-17.089844 0-23.613281-6.523437-6.519531-17.09375-6.519531-23.613281 0l-11.175781 11.175781c-19.242188-23.847656-30.410157-52.097656-33.503907-81.054688h15.738282c9.21875 0 16.695312-7.476562 16.695312-16.695312s-7.476562-16.695312-16.695312-16.695312h-15.726563c3.09375-28.953126 14.261719-57.199219 33.5-81.050782l11.171875 11.171875c3.257813 3.257813 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.546875-1.632812 11.804687-4.890625 6.519531-6.519531 6.519531-17.09375 0-23.613281l-11.171875-11.171875c23.851563-19.238281 52.097656-30.402344 81.050782-33.492187v15.722656c0 9.21875 7.476562 16.695312 16.695312 16.695312 9.222656 0 16.695312-7.476562 16.695312-16.695312v-15.722656c34.171876 3.648437 67.355469 18.53125 93.492188 44.664062 6.519531 6.519531 17.089844 6.519531 23.609375 0s6.519531-17.089844 0-23.609375zm0 0"
                                                                fill="#88cc2a"></path>
                                                            <path
                                                                d="m445.21875 256c0-.632812-.042969-1.253906-.113281-1.867188-.46875-47.832031-18.894531-95.515624-55.308594-131.929687-6.519531-6.519531-17.089844-6.519531-23.609375 0-6.523438 6.519531-6.523438 17.089844 0 23.609375 26.132812 26.136719 41.015625 59.324219 44.667969 93.492188h-15.726563c-9.21875 0-16.695312 7.476562-16.695312 16.695312s7.476562 16.695312 16.695312 16.695312h15.734375c-3.09375 28.957032-14.257812 57.207032-33.5 81.054688l-11.175781-11.175781c-6.519531-6.519531-17.09375-6.519531-23.613281 0s-6.519531 17.089843 0 23.613281l23.613281 23.609375c3.257812 3.261719 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.542968-1.628906 11.804687-4.890625 36.414063-36.414063 54.839844-84.097656 55.308594-131.929687.070312-.613282.113281-1.234376.113281-1.867188zm0 0"
                                                                fill="#ff9f19"></path>
                                                            <path
                                                                d="m272.695312 445.21875h-33.390624c-27.617188 0-50.085938-22.46875-50.085938-50.089844 0-27.617187 22.46875-50.085937 50.085938-50.085937h33.390624c27.617188 0 50.085938 22.46875 50.085938 50.085937 0 27.621094-22.46875 50.089844-50.085938 50.089844zm-33.390624-66.785156c-9.207032 0-16.695313 7.492187-16.695313 16.695312 0 9.207032 7.488281 16.695313 16.695313 16.695313h33.390624c9.207032 0 16.695313-7.488281 16.695313-16.695313 0-9.203125-7.488281-16.695312-16.695313-16.695312zm0 0"
                                                                fill="#bddeff"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-6.519531-6.519531-17.089844-6.519531-23.609375 0l-84.46875 84.46875c-7.699219-4.03125-16.445312-6.324219-25.71875-6.324219-30.6875 0-55.652344 24.964844-55.652344 55.652344s24.964844 55.652344 55.652344 55.652344 55.652344-24.964844 55.652344-55.652344c0-9.273438-2.292969-18.019531-6.324219-25.71875l84.46875-84.46875c6.519531-6.519531 6.519531-17.089844 0-23.609375zm-133.796875 156.058594c-12.273438 0-22.261719-9.988281-22.261719-22.261719s9.988281-22.261719 22.261719-22.261719 22.261719 9.988281 22.261719 22.261719-9.988281 22.261719-22.261719 22.261719zm0 0"
                                                                fill="#e6563a"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Presión</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm5LastData.rain}}mm</strong></p>
                                                        <svg height="45.541px" viewBox="0 -7 512 512" width="47.542px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m21.316406 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.0117188 1.125.03125 1.683594 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578124-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m21.316406 457.257812c-10.378906 0-20.753906-6.703124-21.2734372-20.097656-.0234376-.5625-.0429688-1.125-.0429688-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.570312 7.371094-4.292968 21.140625-4.292968 36.914062 0 .558594 0 1.121094.007812 1.683594.105469 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 438.421875c-12.742187 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574218-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 329.023438c-12.742187 7.371093-21.316406 21.136718-21.316406 36.914062 0 .5625.011719 1.125.03125 1.679688 1.039062 26.800781 41.527344 26.800781 42.566406 0 .023438-.554688.03125-1.117188.03125-1.679688.003906-15.777344-8.574218-29.542969-21.3125-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 387.71875c-10.378906 0-20.757812-6.707031-21.277344-20.101562-.019531-.558594-.039062-1.121094-.039062-1.683594 0-15.773438 8.578125-29.539063 21.316406-36.914063-2.570312 7.375-4.296875 21.140625-4.296875 36.914063 0 .5625 0 1.125.011719 1.683594.105469 13.394531 2.195312 20.101562 4.285156 20.101562zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m208.1875 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m208.1875 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m301.625 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.007812 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574219-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m301.625 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105468 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m489.035156 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.035156-1.117187.035156-1.683593 0-15.777344-8.578124-29.542969-21.316406-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m489.035156 497.117188c-10.378906 0-20.753906-6.703126-21.273437-20.097657-.023438-.5625-.042969-1.125-.042969-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m395.058594 398.5625c-12.738282 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527343 26.796875 42.566406 0 .023437-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m395.058594 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578124-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m408.25 181.570312c0 37.824219-30.664062 68.492188-68.503906 68.492188h-247.886719c-50.296875 0-91.082031-40.773438-91.082031-91.082031 0-50.296875 40.78125-91.082031 91.082031-91.082031 4.511719 0 8.945313.347656 13.292969.976562 20.570312-40.84375 62.871094-68.875 111.742187-68.875 59.128907 0 108.667969 41.054688 121.679688 96.210938 1.300781 5.503906 2.238281 11.144531 2.777343 16.894531v.011719c37.082032.851562 66.898438 31.167968 66.898438 68.453124zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m260.351562 7.769531c-13.539062-5.027343-28.183593-7.76562475-43.457031-7.76562475-48.871093 0-91.160156 28.03124975-111.742187 68.86328175v.011718c-4.347656-.628906-8.78125-.976562-13.292969-.976562-50.3125 0-91.082031 40.785156-91.082031 91.082031 0 50.3125 40.769531 91.082031 91.082031 91.082031h44.019531c-45.332031 0-82.066406-36.746094-82.066406-82.070312 0-40.214844 28.929688-73.683594 67.128906-80.703125 3.777344-.707031 7.648438-1.144531 11.597656-1.300781 1.105469-.035157 2.222657-.0625 3.339844-.0625 14.734375 0 28.570313 3.882812 40.515625 10.699218-1.660156-11.5625-13.613281-22.394531-31.238281-31.125 9.679688-32.726562 42.613281-63.078125 115.195312-57.734375zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m264.6875 245.550781c.496094.089844.789062.089844.835938.089844h-3.792969c.992187 0 1.972656-.03125 2.957031-.089844zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m512 313.621094c0 30.589844-24.800781 55.390625-55.402344 55.390625h-200.476562c-40.675782 0-73.660156-32.972657-73.660156-73.660157 0-40.679687 32.984374-73.660156 73.660156-73.660156 3.648437 0 7.238281.28125 10.75.789063 16.640625-33.035157 50.847656-55.703125 90.371094-55.703125 47.824218 0 87.886718 33.203125 98.410156 77.808594 1.050781 4.453124 1.808594 9.015624 2.246094 13.664062v.011719c29.988281.683593 54.101562 25.203125 54.101562 55.359375zm0 0"
                                                                fill="#d3ddea"></path>
                                                            <path
                                                                d="m392.386719 173.0625c-10.949219-4.066406-22.792969-6.28125-35.144531-6.28125-39.523438 0-73.722657 22.667969-90.371094 55.691406v.011719c-3.515625-.511719-7.101563-.789063-10.75-.789063-40.6875 0-73.660156 32.980469-73.660156 73.660157 0 40.6875 32.972656 73.660156 73.660156 73.660156h35.601562c-36.664062 0-66.371094-29.71875-66.371094-66.371094 0-32.527343 23.394532-59.59375 54.289063-65.269531 3.058594-.574219 6.1875-.925781 9.378906-1.050781.894531-.03125 1.800781-.050781 2.703125-.050781 11.917969 0 23.105469 3.140624 32.765625 8.648437-1.339843-9.351563-11.007812-18.109375-25.261719-25.167969 7.824219-26.46875 34.460938-51.015625 93.160157-46.691406zm0 0"
                                                                fill="#84abc1"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Lluvia</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo3" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm5LastData.dewpoint}}°C</strong>
                                                        </p>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                                            id="Layer_1" x="0px" y="0px" viewBox="0 0 497 497"
                                                            style="enable-background:new 0 0 497 497;"
                                                            xml:space="preserve" width="55px" height="55px">
                                                            <g>
                                                                <path style="fill:#59C2E8;"
                                                                    d="M183.391,58.325c-6.66-6.477-17.246-6.477-23.907,0C-78.787,290.047-14.684,497,171.438,497   S421.663,290.047,183.391,58.325z">
                                                                </path>
                                                                <path style="fill:#0097D1;"
                                                                    d="M201.438,477C19.441,477-45.878,279.117,174.095,53.691c-5.15-0.806-10.593,0.727-14.611,4.634   C-78.787,290.047-14.684,497,171.438,497c48.559,0,88.805-14.094,117.55-39.032C264.453,470.302,234.92,477,201.438,477z">
                                                                </path>
                                                                <g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M99.951,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5s7.5,3.358,7.5,7.5     v12.938C107.451,283.894,104.094,287.251,99.951,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M242.925,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5     s7.5,3.358,7.5,7.5v12.938C250.425,283.894,247.067,287.251,242.925,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M171.438,294.157c-9.041,0-17.53-3.78-23.291-10.371c-2.727-3.119-2.408-7.857,0.711-10.583     c3.117-2.726,7.856-2.408,10.582,0.711c2.912,3.332,7.285,5.242,11.998,5.242c4.712,0,9.085-1.911,11.997-5.242     c2.726-3.119,7.464-3.437,10.583-0.711c3.118,2.726,3.437,7.464,0.71,10.583C188.968,290.378,180.478,294.157,171.438,294.157z">
                                                                        </path>
                                                                    </g>
                                                                </g>
                                                                <path style="fill:#D5EBF6;"
                                                                    d="M420.006,262.727V50.657C420.006,22.68,397.326,0,369.35,0h0c-25.428,0-46.475,18.737-50.1,43.157   l4.08,7.5l-4.637,7.5V79.17l3.97,7.5l-3.97,7.5v21.014l4.637,7.5l-4.637,7.5v21.014l3.637,7.5l-3.637,7.5v21.013l3.637,7.5   l-3.637,7.5v60.516c-42.433,19.279-71.942,62.03-71.942,111.674C246.751,442.111,301.64,497,369.35,497   s122.599-54.889,122.599-122.599C491.948,324.757,462.439,282.006,420.006,262.727z">
                                                                </path>
                                                                <path style="fill:#FF6E80;"
                                                                    d="M369.349,462.349c-48.494,0-87.947-39.453-87.947-87.947c0-34.427,20.264-65.879,51.625-80.127   l20.318-9.23V50.656c0-8.825,7.18-16.005,16.005-16.005c8.825,0,16.006,7.18,16.006,16.005v234.388l20.318,9.231   c31.361,14.248,51.625,45.7,51.625,80.127C457.297,422.896,417.844,462.349,369.349,462.349z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,187.211v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H318.693   L318.693,187.211z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,151.198v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,151.198z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,115.184v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,115.184z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,50.657v7.5h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-34.095   C318.886,45.604,318.693,48.107,318.693,50.657z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,79.17v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,79.17z">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Punto de<br> rocío</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm5LastData.heatindex}}°C</strong>
                                                        </p>
                                                        <svg style="margin-right: 20px" height="70px"
                                                            viewBox="-78 0 512 512" width="75px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-13.621094-15.976562-21.824218-36.6875-21.824218-59.308593 0-50.550782 40.976562-91.527344 91.527343-91.527344 46.082031 0 84.203125 34.046875 90.585938 78.363281.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fab03c"></path>
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-.621094-4.300781-.9375-8.691406-.9375-13.160156 0-50.554687 40.984375-91.527344 91.527344-91.527344 27.925781 0 52.925781 12.503907 69.699219 32.214844.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <g fill="#f8e98e">
                                                                <path
                                                                    d="m150.769531 34.9375c-4.207031 0-7.613281-3.410156-7.613281-7.613281v-19.714844c0-4.203125 3.410156-7.609375 7.613281-7.609375s7.609375 3.40625 7.609375 7.609375v19.714844c0 4.203125-3.40625 7.613281-7.609375 7.613281zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m89.054688 51.476562c-2.632813 0-5.191407-1.367187-6.601563-3.808593l-9.855469-17.070313c-2.101562-3.640625-.855468-8.296875 2.785156-10.398437 3.640626-2.101563 8.296876-.855469 10.398438 2.785156l9.855469 17.074219c2.101562 3.636718.855469 8.292968-2.785157 10.394531-1.199218.691406-2.507812 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m43.855469 96.660156c-1.289063 0-2.597657-.332031-3.796875-1.023437l-17.074219-9.855469c-3.640625-2.101562-4.886719-6.757812-2.785156-10.398438 2.101562-3.640624 6.753906-4.886718 10.394531-2.785156l17.074219 9.855469c3.640625 2.105469 4.886719 6.757813 2.785156 10.398437-1.410156 2.445313-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m27.324219 158.378906h-19.714844c-4.203125 0-7.609375-3.40625-7.609375-7.609375s3.40625-7.613281 7.609375-7.613281h19.714844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m257.679688 96.660156c-2.628907 0-5.1875-1.367187-6.597657-3.808594-2.101562-3.640624-.855469-8.292968 2.785157-10.398437l17.074218-9.855469c3.636719-2.101562 8.292969-.855468 10.394532 2.785156 2.101562 3.640626.855468 8.296876-2.785157 10.398438l-17.074219 9.855469c-1.195312.691406-2.503906 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m26.796875 229.960938c-2.628906 0-5.1875-1.367188-6.597656-3.808594-2.101563-3.640625-.855469-8.296875 2.785156-10.394532l17.074219-9.859374c3.636718-2.097657 8.292968-.851563 10.394531 2.789062s.855469 8.292969-2.785156 10.394531l-17.074219 9.855469c-1.195312.695312-2.503906 1.023438-3.796875 1.023438zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m212.484375 51.476562c-1.292969 0-2.601563-.332031-3.800781-1.023437-3.640625-2.101563-4.886719-6.757813-2.785156-10.394531l9.855468-17.074219c2.101563-3.640625 6.757813-4.886719 10.398438-2.785156 3.640625 2.101562 4.886718 6.757812 2.785156 10.398437l-9.855469 17.070313c-1.410156 2.441406-3.96875 3.808593-6.597656 3.808593zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m79.183594 282.359375c-1.292969 0-2.601563-.328125-3.800782-1.019531-3.640624-2.105469-4.886718-6.757813-2.785156-10.398438l9.855469-17.074218c2.101563-3.640626 6.757813-4.886719 10.398437-2.785157 3.640626 2.101563 4.886719 6.757813 2.785157 10.398438l-9.855469 17.070312c-1.410156 2.441407-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m329.234375 240.59375c0 10.503906-8.519531 19.019531-19.023437 19.019531-10.503907 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.519531-19.023438 19.023438-19.023438 10.503906 0 19.023437 8.515626 19.023437 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m177.15625 240.59375c0 10.503906-8.515625 19.019531-19.019531 19.019531-10.507813 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.515625-19.023438 19.023438-19.023438 10.503906 0 19.019531 8.515626 19.019531 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m353.683594 439.070312v62.78125c0 5.605469-4.542969 10.148438-10.148438 10.148438h-218.722656c-5.601562 0-10.148438-4.542969-10.148438-10.148438v-62.78125c0-43.292968 23.027344-81.207031 57.492188-102.175781 18.074219-10.988281 39.308594-17.332031 62.019531-17.332031 22.5 0 43.535157 6.21875 61.5 17.03125 8.390625 5.042969 16.125 11.089844 23.007813 17.972656 21.625 21.636719 35 51.511719 35 84.503906zm0 0"
                                                                fill="#f0f5f7"></path>
                                                            <path
                                                                d="m172.503906 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.609375-7.609375 4.203125 0 7.613281 3.398437 7.613281 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m311.171875 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.613281-7.609375 4.199219 0 7.609375 3.398437 7.609375 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m343.539062 511.992188h-47.589843v-67.089844h57.738281v56.945312c0 5.601563-4.542969 10.144532-10.148438 10.144532zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m295.949219 444.90625h15.222656v67.082031h-15.222656zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m172.402344 444.90625v67.082031h-47.589844c-5.601562 0-10.148438-4.542969-10.148438-10.148437v-56.933594zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m157.28125 444.90625h15.121094v67.082031h-15.121094zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m295.679688 336.59375c-13.59375 19.570312-36.234376 32.367188-61.863282 32.367188-25.496094 0-48.042968-12.671876-61.65625-32.066407 18.070313-10.992187 39.304688-17.328125 62.015625-17.328125 22.5 0 43.535157 6.210938 61.503907 17.027344zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m164.230469 174.1875v85.160156c0 38.625 31.316406 69.941406 69.941406 69.941406 38.628906 0 69.941406-31.316406 69.941406-69.941406v-85.160156zm0 0"
                                                                fill="#fbdbb7"></path>
                                                            <path
                                                                d="m304.113281 174.1875v85.15625c0 19.3125-7.828125 36.808594-20.488281 49.460938-12.652344 12.652343-30.140625 20.480468-49.453125 20.480468-3.757813 0-7.445313-.292968-11.050781-.875 14.886718-2.355468 28.207031-9.421875 38.402344-19.605468 12.652343-12.652344 20.480468-30.148438 20.480468-49.460938v-85.15625zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m234.175781 119.171875c-44.792969 0-81.101562 36.308594-81.101562 81.101563v17.472656c0 6.152344 5.417969 10.886718 11.515625 10.054687 20.5-2.789062 62.964844-10.910156 82.292968-31.78125 7.195313 11.8125 23.039063 26.890625 57.433594 29.546875 5.90625.457032 10.957032-4.199218 10.957032-10.121094v-15.171874c0-44.792969-36.308594-81.101563-81.097657-81.101563zm0 0"
                                                                fill="#82807f"></path>
                                                            <path
                                                                d="m315.273438 200.277344v15.164062c0 5.929688-5.066407 10.578125-10.980469 10.121094-4.617188-.359375-8.902344-.941406-12.878907-1.710938v-23.574218c0-40.738282-30.03125-74.460938-69.175781-80.238282 3.898438-.578124 7.875-.871093 11.9375-.871093 44.785157 0 81.097657 36.3125 81.097657 81.109375zm0 0"
                                                                fill="#6d6d6d"></path>
                                                            <path
                                                                d="m201.347656 248.203125c-4.207031 0-7.660156-3.40625-7.660156-7.609375s3.363281-7.613281 7.570312-7.613281h.089844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m267.089844 248.203125c-4.203125 0-7.65625-3.40625-7.65625-7.609375s3.363281-7.613281 7.566406-7.613281h.089844c4.207031 0 7.613281 3.410156 7.613281 7.613281s-3.410156 7.609375-7.613281 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m241.503906 292.371094h-14.65625c-4.207031 0-7.613281-3.40625-7.613281-7.609375v-44.167969c0-4.203125 3.410156-7.613281 7.613281-7.613281s7.609375 3.410156 7.609375 7.613281v36.554688h7.046875c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.410156 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <path
                                                                d="m323.347656 172.90625c0 10.621094-8.609375 19.230469-19.230468 19.230469-10.621094 0-19.230469-8.609375-19.230469-19.230469 0-6.96875 8.28125-20.652344 13.972656-29.230469 2.496094-3.761719 8.015625-3.761719 10.511719 0 5.695312 8.578125 13.976562 22.261719 13.976562 29.230469zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m323.34375 172.90625c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.660156 0-5.199219-.542969-7.507812-1.519531 6.898437-2.921876 11.730469-9.753907 11.730469-17.710938 0-6.09375-6.328126-17.316406-11.722657-25.777344.773438-1.214844 1.527344-2.367187 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.519532-.011719 5.691406 8.582031 13.972656 22.261719 13.972656 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m356.394531 368.648438c0 10.621093-8.613281 19.230468-19.234375 19.230468s-19.230468-8.609375-19.230468-19.230468c0-6.972657 8.285156-20.652344 13.976562-29.234376 2.496094-3.761718 8.015625-3.761718 10.511719 0 5.691406 8.582032 13.976562 22.261719 13.976562 29.234376zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m356.390625 368.648438c0 10.617187-8.605469 19.230468-19.230469 19.230468-2.664062 0-5.199218-.542968-7.507812-1.519531 6.894531-2.921875 11.730468-9.753906 11.730468-17.710937 0-6.09375-6.328124-17.320313-11.722656-25.78125.769532-1.210938 1.523438-2.367188 2.238282-3.441407 2.496093-3.765625 8.019531-3.777343 10.515624-.011719 5.695313 8.582032 13.976563 22.261719 13.976563 29.234376zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.621094-8.609375 19.230469-19.230469 19.230469s-19.230469-8.609375-19.230469-19.230469c0-6.96875 8.28125-20.652344 13.976563-29.234375 2.496093-3.757813 8.015625-3.757813 10.511719 0 5.691406 8.582031 13.972656 22.265625 13.972656 29.234375zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.664063 0-5.199219-.542969-7.507813-1.519531 6.894532-2.921876 11.726563-9.753907 11.726563-17.710938 0-6.09375-6.328125-17.316406-11.71875-25.777344.769531-1.214844 1.523437-2.371094 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.515625-.011719 5.695313 8.582031 13.976563 22.261719 13.976563 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Sensación<br> térmica</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{cmm5LastData.windSpeed}}Km/h</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 -23 511.99037 511" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m238.945312 182.152344h-213.332031c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h213.332031c20.527344.90625 39.605469-10.542969 48.460938-29.082032 9.6875-19.246094 8.308594-42.207031-3.609375-60.160156-9.566406-12.449219-24.515625-17.066406-43.175781-13.320312-14.421875 1.917968-24.910156 14.648437-24.03125 29.167968 1.066406 10.726563 9.738281 22.1875 30.890625 22.1875 2.355469 0 4.265625-1.910156 4.265625-4.269531 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535157-3.820312-8.535157-8.53125 0-4.714844 3.820313-8.535156 8.535157-8.535156 11.78125 0 21.332031 9.550781 21.332031 21.332031 0 11.785156-9.550781 21.335937-21.332031 21.335937-30.5 0-46.019531-18.875-47.871094-37.546874-1.890625-23.285157 14.539063-44.070313 37.632813-47.609376 22.140624-6.042968 45.753906 1.675782 60.054687 19.628907 15.925781 23.097656 17.992187 53.039062 5.386719 78.105469-11.75 24.332031-36.726563 39.472656-63.738282 38.628906zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m269.742188 430.933594c-5.183594-.019532-10.355469-.554688-15.433594-1.597656-23.097656-3.535157-39.527344-24.324219-37.632813-47.613282 1.851563-18.671875 17.375-37.546875 47.871094-37.546875 11.78125 0 21.332031 9.550781 21.332031 21.332031s-9.550781 21.335938-21.332031 21.335938c-4.710937 0-8.53125-3.824219-8.53125-8.535156 0-4.714844 3.820313-8.535156 8.53125-8.535156 2.355469 0 4.265625-1.910157 4.265625-4.265626 0-2.355468-1.910156-4.265624-4.265625-4.265624-21.152344 0-29.867187 11.46875-30.890625 22.1875-.878906 14.535156 9.628906 27.273437 24.0625 29.183593 15.765625 4.65625 32.785156-.597656 43.179688-13.328125 11.917968-17.953125 13.296874-40.914062 3.609374-60.160156-8.859374-18.550781-27.957031-30.003906-48.492187-29.082031h-213.335937c-4.710938 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820312-8.53125 8.53125-8.53125h213.335937c27.015625-.859374 52.003906 14.265626 63.769531 38.601563 12.605469 25.066406 10.539063 55.011719-5.386718 78.109375-10.601563 13.847656-27.21875 21.753906-44.65625 21.246094zm0 0"
                                                                fill="#bdc3c7"></path>
                                                            <path
                                                                d="m375.480469 224.820312h-213.332031c-4.714844 0-8.535157-3.820312-8.535157-8.535156 0-4.710937 3.820313-8.53125 8.535157-8.53125h213.332031c20.523437.90625 39.605469-10.542968 48.460937-29.082031 9.683594-19.246094 8.308594-42.210937-3.609375-60.160156-9.566406-12.460938-24.535156-17.066407-43.179687-13.320313-14.417969 1.914063-24.90625 14.644532-24.03125 29.167969 1.070312 10.726563 9.738281 22.183594 30.894531 22.183594 2.355469 0 4.265625-1.910157 4.265625-4.265625 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535156-3.820313-8.535156-8.535157 0-4.710937 3.820312-8.53125 8.535156-8.53125 11.78125 0 21.332031 9.550782 21.332031 21.332032s-9.550781 21.332031-21.332031 21.332031c-30.5 0-46.023437-18.875-47.875-37.546875-1.890625-23.285156 14.539063-44.070312 37.632813-47.605469 22.144531-6.046875 45.757812 1.671875 60.058593 19.625 15.925781 23.097657 17.988281 53.042969 5.382813 78.105469-11.75 24.335938-36.722656 39.472656-63.734375 38.632812zm0 0"
                                                                fill="#7f8c8d"></path>
                                                            <path
                                                                d="m426.875 453.785156c-7.640625-.035156-15.261719-.824218-22.75-2.355468-33.242188-4.996094-56.972656-34.835938-54.355469-68.351563 2.457031-24.796875 22.050781-53.757813 69.1875-53.757813 15.414063.265626 27.773438 12.839844 27.773438 28.257813s-12.359375 27.988281-27.773438 28.257813c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125 6.0625-.167969 10.886719-5.128906 10.886719-11.191406s-4.824219-11.027344-10.886719-11.191406c-35.6875 0-50.347656 19.820312-52.199219 38.398437-1.625 24.75 16.152344 46.546875 40.722657 49.9375 38.664062 7.722656 60.015625-7.824218 71.097656-22.246094 19.414063-25.257812 21.898437-65.382812 6.042969-97.570312-14.375-30.1875-45.410156-48.851562-78.8125-47.394531h-397.261719c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125h397.261719c39.863281-1.292968 76.75 21.015626 94.121094 56.917969 18.628906 37.792969 15.410156 85.332031-7.816407 115.515625-15.507812 20.191406-39.785156 31.695313-65.238281 30.90625zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m40.34375 434.203125c-3.925781-.128906.757812-21.335937 1.234375-23.628906 2.132813-12.183594 8.472656-23.234375 17.921875-31.214844 26.070312-21.597656 62.0625-20.363281 93.867188-18.484375 2.175781.128906 16.492187 1.28125 17.3125-.621094-14.652344 33.890625-31.324219 75.226563-61.933594 97.890625-8.882813 6.957031-20.480469 9.414063-31.421875 6.65625-7.925781-2.652343-14.917969-7.535156-20.136719-14.0625-4.667969-5.144531-8.765625-16.28125-16.84375-16.535156zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m8.546875 465.988281c-3.59375.003907-6.808594-2.242187-8.035156-5.621093-1.226563-3.378907-.203125-7.164063 2.558593-9.464844 2.164063-1.765625 53.382813-44.160156 97.636719-53.019532 4.585938-.847656 9.007813 2.144532 9.921875 6.71875.914063 4.574219-2.015625 9.035157-6.578125 10.015626-40.035156 8.066406-89.53125 49.023437-90.035156 49.390624-1.535156 1.28125-3.46875 1.980469-5.46875 1.980469zm0 0"
                                                                fill="#805333"></path>
                                                            <path
                                                                d="m132.648438 105.386719c.953124 3.796875-20.753907 5.121093-23.042969 5.324219-12.296875 1.316406-24.664063-1.730469-34.941407-8.609376-27.964843-19.105468-36.710937-54.03125-43.683593-85.128906-.476563-2.132812-3.308594-16.214844-5.367188-16.476562 36.625 4.734375 80.949219 9.386718 111.179688 32.535156 9.136719 6.617188 14.699219 17.085938 15.0625 28.359375-.355469 8.359375-3.113281 16.441406-7.945313 23.277344-3.675781 5.863281-13.242187 12.894531-11.261718 20.71875zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m163.75 138.0625c-1.703125.003906-3.371094-.511719-4.777344-1.476562-2.328125-1.578126-57.171875-39.125-77.890625-79.242188-2.164062-4.1875-.523437-9.335938 3.664063-11.5 4.1875-2.160156 9.335937-.519531 11.496094 3.667969 18.773437 36.25 71.785156 72.601562 72.320312 72.957031 3.101562 2.109375 4.464844 5.992188 3.359375 9.574219s-4.421875 6.023437-8.171875 6.019531zm0 0"
                                                                fill="#805333"></path>
                                                            <g fill="#cb8252">
                                                                <path
                                                                    d="m366.945312 28.441406c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714843 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820313-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m486.414062 11.375c0 4.714844-3.820312 8.535156-8.535156 8.535156-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125 4.714844 0 8.535156 3.820312 8.535156 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m460.8125 207.640625c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m366.945312 310.042969c0 4.710937-3.820312 8.53125-8.53125 8.53125-4.714843 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820313-8.535157 8.535156-8.535157 4.710938 0 8.53125 3.820313 8.53125 8.535157zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m324.28125 455.109375c0 4.710937-3.820312 8.53125-8.535156 8.53125-4.710938 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820312-8.535156 8.53125-8.535156 4.714844 0 8.535156 3.820312 8.535156 8.535156zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m187.746094 412.441406c0 4.714844-3.820313 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710937 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m93.878906 224.707031h-85.332031c-4.710937 0-8.53125-3.820312-8.53125-8.53125 0-4.714843 3.820313-8.535156 8.53125-8.535156h85.332031c4.714844 0 8.535156 3.820313 8.535156 8.535156 0 4.710938-3.820312 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#bdc3c7"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Velocidad del<br> viento</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{obtenerDireccionViento(cmm5LastData.windDir)}}</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-4 0 511 511.99978" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m51.703125 51.097656h17.066406v51.203125h-17.066406zm0 0"
                                                                fill="#5d9aa4"></path>
                                                            <path
                                                                d="m43.167969 272.976562h34.136719v230.414063h-34.136719zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m145.574219 42.5625h-136.539063c-4.714844 0-8.535156-3.820312-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h136.539063c4.714843 0 8.535156 3.820312 8.535156 8.535156 0 4.710938-3.820313 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m128.507812 68.164062c-4.714843 0-8.535156-3.820312-8.53125-8.535156 0-2.261718.898438-4.429687 2.496094-6.03125l19.570313-19.566406-19.570313-19.570312c-3.273437-3.386719-3.179687-8.792969.210938-12.066407 3.308594-3.191406 8.550781-3.191406 11.855468 0l25.601563 25.601563c3.332031 3.332031 3.332031 8.734375 0 12.066406l-25.601563 25.601562c-1.597656 1.601563-3.769531 2.5-6.03125 2.5zm0 0"
                                                                fill="#b9b9b9"></path>
                                                            <path
                                                                d="m34.636719 76.699219h51.203125v213.347656h-51.203125zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m34.636719 162.039062c-4.714844 0-8.535157-3.820312-8.535157-8.535156v-42.667968c0-4.714844 3.820313-8.535157 8.535157-8.535157h128.007812c4.710938.011719 8.523438 3.839844 8.511719 8.554688-.007812 3.660156-2.347656 6.90625-5.816406 8.070312l-128.007813 42.667969c-.867187.296875-1.78125.445312-2.695312.445312zm8.53125-42.671874v22.292968l66.890625-22.292968zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m162.644531 272.976562h-128.007812c-4.714844 0-8.535157-3.820312-8.535157-8.53125v-42.671874c.003907-2.742188 1.324219-5.316407 3.550782-6.917969 2.214844-1.621094 5.082031-2.058594 7.679687-1.171875l128.007813 42.671875c4.46875 1.5 6.875 6.335937 5.375 10.804687-1.164063 3.46875-4.414063 5.808594-8.070313 5.816406zm-119.476562-17.066406h66.890625l-66.890625-22.28125zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m85.839844 34.03125c0 14.140625-11.464844 25.601562-25.601563 25.601562-14.140625 0-25.601562-11.460937-25.601562-25.601562s11.460937-25.601562 25.601562-25.601562c14.136719 0 25.601563 11.460937 25.601563 25.601562zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m290.652344 298.578125c-14.464844.015625-27.355469 9.128906-32.191406 22.761719-1.570313 4.445312.761718 9.320312 5.207031 10.890625 4.441406 1.566406 9.316406-.761719 10.886719-5.207031 3.144531-8.886719 12.898437-13.542969 21.785156-10.398438 8.882812 3.144531 13.539062 12.894531 10.398437 21.78125-2.414062 6.816406-8.859375 11.375-16.085937 11.375h-42.671875c-4.710938 0-8.53125 3.824219-8.53125 8.535156 0 4.714844 3.820312 8.535156 8.53125 8.535156h42.671875c18.851562 0 34.132812-15.285156 34.132812-34.136718 0-18.851563-15.28125-34.136719-34.132812-34.136719zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m444.257812 400.984375h-93.871093c-4.710938 0-8.535157-3.820313-8.535157-8.53125 0-4.714844 3.824219-8.535156 8.535157-8.535156h93.871093c4.714844 0 8.535157 3.820312 8.535157 8.535156 0 4.710937-3.820313 8.53125-8.535157 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m316.253906 400.984375h-25.601562c-4.714844 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h25.601562c4.710938 0 8.53125 3.820312 8.53125 8.535156 0 4.710937-3.820312 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m256.515625 400.984375h-85.339844c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h85.339844c4.710937 0 8.53125 3.820312 8.53125 8.535156.003906 4.710937-3.820313 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m205.3125 435.121094h-34.136719c-4.710937 0-8.53125-3.820313-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125h34.136719c4.710938 0 8.53125 3.820312 8.53125 8.53125.003906 4.714843-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m213.847656 366.851562h-42.671875c-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710937 3.820313-8.535156 8.53125-8.535156h42.671875c4.710938 0 8.53125 3.824219 8.53125 8.535156 0 4.714844-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m273.582031 435.121094h-34.132812c-4.714844 0-8.535157-3.820313-8.535157-8.535156 0-4.710938 3.820313-8.53125 8.535157-8.53125h34.132812c4.714844 0 8.535157 3.820312 8.535157 8.53125 0 4.714843-3.820313 8.535156-8.535157 8.535156zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m407.042969 418.113281c-.101563 0-.195313-.058593-.296875-.058593h-90.492188c-4.714844 0-8.535156 3.820312-8.535156 8.53125 0 4.714843 3.820312 8.535156 8.535156 8.535156h88.222656.167969 1.210938c16.496093.003906 29.867187 13.378906 29.863281 29.875s-13.378906 29.867187-29.875 29.863281c-15.207031-.003906-27.980469-11.429687-29.675781-26.542969-.519531-4.683594-4.738281-8.0625-9.421875-7.542968-4.683594.519531-8.0625 4.738281-7.542969 9.421874 2.835937 25.765626 26.023437 44.355469 51.789063 41.519532 25.765624-2.835938 44.355468-26.023438 41.519531-51.789063-2.570313-23.34375-21.992188-41.195312-45.46875-41.789062zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m503.996094 230.308594-256.015625 39.828125-85.335938 2.839843v-170.675781l85.335938 2.84375 256.015625 39.824219zm0 0"
                                                                fill="#ea6b65"></path>
                                                            <g fill="#d9d9d9">
                                                                <path
                                                                    d="m418.65625 243.585938 85.339844-13.277344v-85.339844l-85.339844-13.277344zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m333.320312 256.859375v-138.4375l-85.339843-13.277344v164.992188zm0 0">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Dirección del<br> viento</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngIf="cmm6" class="col-xl-4"> <!-- -->
                <div class="container py-5 h-100">

                    <div class="row d-flex justify-content-center align-items-center h-100" style="color: #282828;">
                        <div class="col-xl-11 bgc fullh" *ngIf="cmm6Loading" style="min-height: 55vh;">
                            <mat-progress-bar mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                        <div class="col-xl-11 bgc fullh" *ngIf="!cmm6Loading">

                            <div class="card mb-4 gradient-custom" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo1" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <h2 class="display-2"><strong>{{cmm6Name}}</strong></h2>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">calendar_today</mat-icon>
                                                                {{getDia(cmm6LastData.formattedDateTime)}}</strong></span>
                                                        <span class="text-muted m-2"><strong><mat-icon
                                                                    style="font-size: 17px;">access_time</mat-icon>
                                                                {{getHora(cmm6LastData.formattedDateTime)}}</strong></span>
                                                        <br>
                                                        <button mat-button (click)="isOpenCmmData(6)" class="mt-5 pt-1">
                                                            <i class="fa-solid fa-clock-rotate-left fa-2x text-muted historial-i"
                                                                style="color: #ddd;"></i></button>
                                                    </div>
                                                    <div>
                                                        <div class="ml-4">
                                                            <div class="mat-elevation-z2" style="border-radius: 20px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="120"
                                                                    height="120"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    version="1.1" x="0px" y="0px" viewBox="0 0 512 512"
                                                                    enable-background="new 0 0 512 512"
                                                                    xml:space="preserve">
                                                                    <g id="Ebene_3" display="none">
                                                                        <rect x="-2020" y="-2565" display="inline"
                                                                            fill="#4D4D4E" width="5364" height="3336" />
                                                                    </g>
                                                                    <g id="Grid" display="none">
                                                                        <g display="inline">
                                                                            <g>
                                                                                <path fill="#B3B3B3"
                                                                                    d="M511,1v510H1V1H511 M512,0H0v512h512V0L512,0z" />
                                                                            </g>
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="6" y1="6"
                                                                                x2="506" y2="506" />
                                                                            <line fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" x1="506" y1="6"
                                                                                x2="6" y2="506" />
                                                                            <circle fill="none" stroke="#B3B3B3"
                                                                                stroke-miterlimit="10" cx="256" cy="256"
                                                                                r="250" />
                                                                        </g>
                                                                    </g>
                                                                    <g id="Ebene_2">
                                                                        <circle fill="#84abc1" cx="353.935" cy="183.958"
                                                                            r="21.065" />
                                                                        <circle fill="#84abc1" cx="428.935" cy="183.958"
                                                                            r="21.065" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="87.37" x2="255.997" y2="471.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="256.003"
                                                                            y1="331.37" x2="316.874" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="316.874"
                                                                            y1="236.37" x2="390.997" y2="236.37" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="390.997"
                                                                            y1="236.37" x2="390.997" y2="198.037" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="255.997"
                                                                            y1="361.371" x2="165.871" y2="400.372" />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="165.871"
                                                                            y1="400.372" x2="165.871" y2="354.371" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="206.871" y2="324.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="206.871"
                                                                            y1="324.368" x2="206.871" y2="298.368" />

                                                                        <line fill="none" stroke="#e69138"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="124.871"
                                                                            y1="324.368" x2="124.871" y2="298.368" />

                                                                        <polygon fill="none" stroke="#7fed6f"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="   184.997,165.621 154.997,182.364 126.692,165.621 126.692,86.624 184.997,86.624  " />

                                                                        <line fill="none" stroke="#442402"
                                                                            stroke-width="17" stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10" x1="202.302"
                                                                            y1="118.364" x2="255.997" y2="172.059" />
                                                                    </g>
                                                                    <g id="txt">
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <br>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card mb-4" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo2" class="">
                                        <!-- Indicators -->
                                        <div>
                                            <div>
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm6LastData.outTemp}}°C</strong>
                                                        </p>
                                                        <svg style="margin-right: 20px" height="60px"
                                                            viewBox="-42 0 512 512" width="60px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m84.144531 38.53125c4.394531 19.445312 27.988281 27.109375 42.96875 13.960938 2.53125-2.222657 6.324219.53125 4.992188 3.625-7.878907 18.3125 6.703125 38.382812 26.554687 36.550781 3.355469-.308594 4.804688 4.144531 1.90625 5.867187-17.136718 10.1875-17.136718 34.996094 0 45.179688 2.898438 1.722656 1.449219 6.175781-1.90625 5.867187-19.851562-1.832031-34.433594 18.238281-26.554687 36.550781 1.332031 3.097657-2.460938 5.847657-4.992188 3.625-14.980469-13.148437-38.578125-5.480468-42.96875 13.960938-.742187 3.289062-5.425781 3.289062-6.167969 0-4.390624-19.445312-27.984374-27.109375-42.96875-13.960938-2.53125 2.222657-6.324218-.527343-4.992187-3.625 7.878906-18.3125-6.703125-38.382812-26.554687-36.550781-3.355469.308594-4.804688-4.144531-1.90625-5.867187 17.136718-10.183594 17.136718-34.992188 0-45.179688-2.894532-1.722656-1.449219-6.175781 1.90625-5.867187 19.851562 1.832031 34.433593-18.238281 26.554687-36.550781-1.332031-3.09375 2.460937-5.847657 4.992187-3.625 14.984376 13.148437 38.578126 5.484374 42.96875-13.960938.742188-3.289062 5.425782-3.289062 6.167969 0zm0 0"
                                                                fill="#fd8f31"></path>
                                                            <path
                                                                d="m160.566406 143.714844c-17.136718-10.183594-17.136718-34.992188 0-45.179688 2.898438-1.71875 1.449219-6.175781-1.90625-5.863281-19.847656 1.832031-34.429687-18.242187-26.554687-36.554687 1.332031-3.09375-2.457031-5.847657-4.992188-3.625-14.980469 13.152343-38.574219 5.484374-42.96875-13.960938-.742187-3.289062-5.425781-3.289062-6.167969 0-1.625 7.191406-5.875 12.761719-11.34375 16.277344 19.398438 15.589844 31.824219 39.496094 31.824219 66.320312 0 26.820313-12.425781 50.730469-31.824219 66.320313 5.46875 3.511719 9.71875 9.085937 11.34375 16.273437.742188 3.289063 5.429688 3.289063 6.167969 0 4.394531-19.445312 27.988281-27.109375 42.96875-13.960937 2.535157 2.222656 6.324219-.527344 4.992188-3.625-7.875-18.3125 6.707031-38.382813 26.554687-36.550781 3.355469.308593 4.804688-4.148438 1.90625-5.871094zm0 0"
                                                                fill="#ff7f1f"></path>
                                                            <path
                                                                d="m115.933594 121.125c0 19.261719-15.613282 34.871094-34.875 34.871094-19.257813 0-34.871094-15.609375-34.871094-34.871094 0-19.257812 15.613281-34.871094 34.871094-34.871094 19.261718 0 34.875 15.613282 34.875 34.871094zm0 0"
                                                                fill="#fccf3f"></path>
                                                            <path
                                                                d="m334.011719 315.355469v-253.195313c0-34.332031-27.832031-62.160156-62.160157-62.160156-34.332031 0-62.160156 27.828125-62.160156 62.160156v253.195313c-27.8125 19.566406-45.984375 51.910156-45.984375 88.5 0 59.726562 48.417969 108.144531 108.144531 108.144531 59.726563 0 108.144532-48.417969 108.144532-108.144531 0-36.589844-18.175782-68.933594-45.984375-88.5zm0 0"
                                                                fill="#d8d8d8"></path>
                                                            <path
                                                                d="m271.851562 476.582031c-41.183593 0-74.6875-33.507812-74.6875-74.6875 0-31.273437 19.433594-59.117187 48.59375-69.980469v-269.753906c0-14.390625 11.703126-26.09375 26.09375-26.09375 14.386719 0 26.09375 11.703125 26.09375 26.09375v269.753906c29.15625 10.859376 48.59375 38.703126 48.59375 69.976563 0 41.183594-33.503906 74.691406-74.6875 74.691406zm0 0"
                                                                fill="#f3e8d7"></path>
                                                            <path
                                                                d="m245.757812 109.871094v222.042968c-29.160156 10.859376-48.59375 38.703126-48.59375 69.976563 0 41.183594 33.503907 74.6875 74.6875 74.6875 41.183594 0 74.6875-33.503906 74.6875-74.6875 0-31.273437-19.4375-59.117187-48.59375-69.976563v-222.042968zm0 0"
                                                                fill="#fc4e51"></path>
                                                            <g fill="#0d6e9a">
                                                                <path
                                                                    d="m420.105469 180.21875h-44.824219c-4.269531 0-7.726562-3.460938-7.726562-7.726562 0-4.265626 3.457031-7.726563 7.726562-7.726563h44.824219c4.265625 0 7.726562 3.460937 7.726562 7.726563 0 4.265624-3.460937 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 117.597656h-20.351562c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726563 7.726562-7.726563h20.351562c4.265626 0 7.726563 3.460938 7.726563 7.726563s-3.460937 7.726562-7.726563 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 54.976562h-27.5625c-4.269531 0-7.726562-3.460937-7.726562-7.726562s3.457031-7.726562 7.726562-7.726562h27.5625c4.269531 0 7.726562 3.460937 7.726562 7.726562s-3.457031 7.726562-7.726562 7.726562zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m402.84375 305.460938h-27.5625c-4.269531 0-7.726562-3.460938-7.726562-7.726563 0-4.269531 3.457031-7.726563 7.726562-7.726563h27.5625c4.269531 0 7.726562 3.457032 7.726562 7.726563 0 4.265625-3.457031 7.726563-7.726562 7.726563zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m395.632812 242.839844h-20.351562c-4.269531 0-7.726562-3.460938-7.726562-7.726563s3.457031-7.726562 7.726562-7.726562h20.351562c4.265626 0 7.726563 3.460937 7.726563 7.726562s-3.460937 7.726563-7.726563 7.726563zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m297.945312 331.914062v-222.042968h-52.1875v222.042968s36.074219 16.738282 36.074219 69.976563c0 29.601563-17.308593 55.234375-42.335937 67.3125 9.792968 4.726563 20.769531 7.375 32.351562 7.375 41.183594 0 74.691406-33.503906 74.691406-74.6875 0-31.273437-19.4375-59.113281-48.59375-69.976563zm0 0"
                                                                fill="#e5394b"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Temperatura</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{cmm6LastData.outHumidity}}%</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-38 0 420 420.192" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m344.425781 175.695312c.210938 31.570313-16.507812 60.835938-43.816406 76.679688-27.304687 15.847656-61.007813 15.847656-88.3125 0-27.304687-15.84375-44.027344-45.109375-43.8125-76.679688 0-48.582031 87.972656-175.6874995 87.972656-175.6874995s87.96875 127.0976565 87.96875 175.6874995zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m256.453125 0s-5.3125 7.695312-13.3125 19.960938c24.585937 37.734374 74.660156 119.066406 74.660156 155.734374-.011719 43.441407-31.722656 80.375-74.660156 86.960938 25.355469 3.859375 51.136719-3.527344 70.597656-20.238281 19.460938-16.707031 30.664063-41.074219 30.6875-66.722657 0-48.589843-87.972656-175.695312-87.972656-175.695312zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m214.261719 66.601562c-22.570313 38.511719-45.761719 84.136719-45.761719 109.085938.003906 32.640625 18.074219 62.59375 46.949219 77.8125s63.800781 13.199219 90.726562-5.246094c-15.175781-52.742187-58.601562-128.222656-91.914062-181.652344zm0 0"
                                                                fill="#bdd9e5"></path>
                                                            <path
                                                                d="m279.007812 280.734375c0 77.019531-62.4375 139.457031-139.457031 139.457031s-139.4531248-62.4375-139.4531248-139.457031 139.4531248-278.511719 139.4531248-278.511719 139.457031 201.472656 139.457031 278.511719zm0 0"
                                                                fill="#26a3b9"></path>
                                                            <g fill="#168aa5">
                                                                <path
                                                                    d="m227.484375 187.15625 19.304687 19.308594-156.308593 156.308594-19.308594-19.308594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m126.574219 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m229.730469 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m139.550781 2.222656s-7.769531 11.230469-19.636719 29.390625c38.402344 58.796875 119.820313 190.277344 119.820313 249.121094-.007813 69.425781-51.082031 128.273437-119.8125 138.054687 40.011719 5.71875 80.535156-6.214843 111.0625-32.703124 30.527344-26.492188 48.050781-64.929688 48.023437-105.351563 0-77.039063-139.457031-278.511719-139.457031-278.511719zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m208.058594 187.15625 19.304687 19.304688-156.308593 156.3125-19.308594-19.308594zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m107.148438 196.621094c10.589843 10.589844 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757812 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                            <path
                                                                d="m210.304688 314.960938c10.589843 10.589843 10.589843 27.757812 0 38.347656-10.589844 10.589844-27.757813 10.589844-38.347657 0-10.589843-10.589844-10.589843-27.757813 0-38.347656 10.589844-10.589844 27.757813-10.589844 38.347657 0zm0 0"
                                                                fill="#d9ecf2"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Humedad</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{cmm6LastData.pressure}}hPa</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 0 512 512" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m256 512c-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.636719-74.980469-181.019531s26.628906-132.667969 74.980469-181.019531c48.351562-48.351563 112.640625-74.980469 181.019531-74.980469s132.667969 26.628906 181.019531 74.980469c48.351563 48.351562 74.980469 112.636719 74.980469 181.019531s-26.628906 132.667969-74.980469 181.019531c-48.351562 48.351563-112.640625 74.980469-181.019531 74.980469zm0-478.609375c-122.746094 0-222.609375 99.863281-222.609375 222.609375s99.863281 222.609375 222.609375 222.609375 222.609375-99.863281 222.609375-222.609375-99.863281-222.609375-222.609375-222.609375zm0 0"
                                                                fill="#736056"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-36.414063-36.414063-84.097656-54.839844-131.929687-55.308594-.613282-.070312-1.234376-.113281-1.867188-.113281s-1.253906.042969-1.867188.113281c-47.828124.46875-95.507812 18.894531-131.921874 55.300781-.003907.003907-.003907.003907-.007813.007813s-.003906.003906-.007813.007813c-36.410156 36.414062-54.832031 84.09375-55.300781 131.921874-.070312.613282-.113281 1.234376-.113281 1.867188s.042969 1.253906.113281 1.867188c.46875 47.832031 18.894531 95.515624 55.308594 131.929687 3.261719 3.261719 7.53125 4.890625 11.804687 4.890625 4.273438 0 8.546876-1.628906 11.804688-4.890625l23.613281-23.609375c6.519531-6.519531 6.519531-17.089844 0-23.613281-6.523437-6.519531-17.09375-6.519531-23.613281 0l-11.175781 11.175781c-19.242188-23.847656-30.410157-52.097656-33.503907-81.054688h15.738282c9.21875 0 16.695312-7.476562 16.695312-16.695312s-7.476562-16.695312-16.695312-16.695312h-15.726563c3.09375-28.953126 14.261719-57.199219 33.5-81.050782l11.171875 11.171875c3.257813 3.257813 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.546875-1.632812 11.804687-4.890625 6.519531-6.519531 6.519531-17.09375 0-23.613281l-11.171875-11.171875c23.851563-19.238281 52.097656-30.402344 81.050782-33.492187v15.722656c0 9.21875 7.476562 16.695312 16.695312 16.695312 9.222656 0 16.695312-7.476562 16.695312-16.695312v-15.722656c34.171876 3.648437 67.355469 18.53125 93.492188 44.664062 6.519531 6.519531 17.089844 6.519531 23.609375 0s6.519531-17.089844 0-23.609375zm0 0"
                                                                fill="#88cc2a"></path>
                                                            <path
                                                                d="m445.21875 256c0-.632812-.042969-1.253906-.113281-1.867188-.46875-47.832031-18.894531-95.515624-55.308594-131.929687-6.519531-6.519531-17.089844-6.519531-23.609375 0-6.523438 6.519531-6.523438 17.089844 0 23.609375 26.132812 26.136719 41.015625 59.324219 44.667969 93.492188h-15.726563c-9.21875 0-16.695312 7.476562-16.695312 16.695312s7.476562 16.695312 16.695312 16.695312h15.734375c-3.09375 28.957032-14.257812 57.207032-33.5 81.054688l-11.175781-11.175781c-6.519531-6.519531-17.09375-6.519531-23.613281 0s-6.519531 17.089843 0 23.613281l23.613281 23.609375c3.257812 3.261719 7.53125 4.890625 11.804688 4.890625 4.269531 0 8.542968-1.628906 11.804687-4.890625 36.414063-36.414063 54.839844-84.097656 55.308594-131.929687.070312-.613282.113281-1.234376.113281-1.867188zm0 0"
                                                                fill="#ff9f19"></path>
                                                            <path
                                                                d="m272.695312 445.21875h-33.390624c-27.617188 0-50.085938-22.46875-50.085938-50.089844 0-27.617187 22.46875-50.085937 50.085938-50.085937h33.390624c27.617188 0 50.085938 22.46875 50.085938 50.085937 0 27.621094-22.46875 50.089844-50.085938 50.089844zm-33.390624-66.785156c-9.207032 0-16.695313 7.492187-16.695313 16.695312 0 9.207032 7.488281 16.695313 16.695313 16.695313h33.390624c9.207032 0 16.695313-7.488281 16.695313-16.695313 0-9.203125-7.488281-16.695312-16.695313-16.695312zm0 0"
                                                                fill="#bddeff"></path>
                                                            <path
                                                                d="m389.796875 122.203125c-6.519531-6.519531-17.089844-6.519531-23.609375 0l-84.46875 84.46875c-7.699219-4.03125-16.445312-6.324219-25.71875-6.324219-30.6875 0-55.652344 24.964844-55.652344 55.652344s24.964844 55.652344 55.652344 55.652344 55.652344-24.964844 55.652344-55.652344c0-9.273438-2.292969-18.019531-6.324219-25.71875l84.46875-84.46875c6.519531-6.519531 6.519531-17.089844 0-23.609375zm-133.796875 156.058594c-12.273438 0-22.261719-9.988281-22.261719-22.261719s9.988281-22.261719 22.261719-22.261719 22.261719 9.988281 22.261719 22.261719-9.988281 22.261719-22.261719 22.261719zm0 0"
                                                                fill="#e6563a"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Presión</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium"><strong>{{cmm6LastData.rain}}mm</strong>
                                                        </p>
                                                        <svg height="45.541px" viewBox="0 -7 512 512" width="47.542px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m21.316406 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.0117188 1.125.03125 1.683594 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578124-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m21.316406 457.257812c-10.378906 0-20.753906-6.703124-21.2734372-20.097656-.0234376-.5625-.0429688-1.125-.0429688-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.570312 7.371094-4.292968 21.140625-4.292968 36.914062 0 .558594 0 1.121094.007812 1.683594.105469 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 438.421875c-12.742187 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574218-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m114.753906 329.023438c-12.742187 7.371093-21.316406 21.136718-21.316406 36.914062 0 .5625.011719 1.125.03125 1.679688 1.039062 26.800781 41.527344 26.800781 42.566406 0 .023438-.554688.03125-1.117188.03125-1.679688.003906-15.777344-8.574218-29.542969-21.3125-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m114.753906 387.71875c-10.378906 0-20.757812-6.707031-21.277344-20.101562-.019531-.558594-.039062-1.121094-.039062-1.683594 0-15.773438 8.578125-29.539063 21.316406-36.914063-2.570312 7.375-4.296875 21.140625-4.296875 36.914063 0 .5625 0 1.125.011719 1.683594.105469 13.394531 2.195312 20.101562 4.285156 20.101562zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m208.1875 398.5625c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527344 26.796875 42.566406 0 .023438-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m208.1875 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578125-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m301.625 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.007812 1.125.03125 1.683593 1.039062 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.03125-1.117187.03125-1.683593.003906-15.777344-8.574219-29.542969-21.3125-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m301.625 497.117188c-10.378906 0-20.757812-6.703126-21.277344-20.097657-.019531-.5625-.039062-1.125-.039062-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105468 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m489.035156 438.421875c-12.738281 7.371094-21.316406 21.136719-21.316406 36.914063 0 .566406.011719 1.125.03125 1.683593 1.042969 26.796875 41.527344 26.796875 42.566406 0 .023438-.558593.035156-1.117187.035156-1.683593 0-15.777344-8.578124-29.542969-21.316406-36.914063zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m489.035156 497.117188c-10.378906 0-20.753906-6.703126-21.273437-20.097657-.023438-.5625-.042969-1.125-.042969-1.683593 0-15.773438 8.578125-29.542969 21.316406-36.914063-2.570312 7.371094-4.296875 21.140625-4.296875 36.914063 0 .558593 0 1.121093.011719 1.683593.105469 13.394531 2.195312 20.097657 4.285156 20.097657zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m395.058594 398.5625c-12.738282 7.371094-21.316406 21.136719-21.316406 36.914062 0 .566407.011718 1.125.03125 1.683594 1.042968 26.796875 41.527343 26.796875 42.566406 0 .023437-.558594.035156-1.117187.035156-1.683594 0-15.777343-8.578125-29.542968-21.316406-36.914062zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m395.058594 457.257812c-10.378906 0-20.753906-6.703124-21.273438-20.097656-.023437-.5625-.042968-1.125-.042968-1.683594 0-15.773437 8.578124-29.542968 21.316406-36.914062-2.566406 7.371094-4.292969 21.140625-4.292969 36.914062 0 .558594 0 1.121094.007813 1.683594.105468 13.394532 2.195312 20.097656 4.285156 20.097656zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m408.25 181.570312c0 37.824219-30.664062 68.492188-68.503906 68.492188h-247.886719c-50.296875 0-91.082031-40.773438-91.082031-91.082031 0-50.296875 40.78125-91.082031 91.082031-91.082031 4.511719 0 8.945313.347656 13.292969.976562 20.570312-40.84375 62.871094-68.875 111.742187-68.875 59.128907 0 108.667969 41.054688 121.679688 96.210938 1.300781 5.503906 2.238281 11.144531 2.777343 16.894531v.011719c37.082032.851562 66.898438 31.167968 66.898438 68.453124zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m260.351562 7.769531c-13.539062-5.027343-28.183593-7.76562475-43.457031-7.76562475-48.871093 0-91.160156 28.03124975-111.742187 68.86328175v.011718c-4.347656-.628906-8.78125-.976562-13.292969-.976562-50.3125 0-91.082031 40.785156-91.082031 91.082031 0 50.3125 40.769531 91.082031 91.082031 91.082031h44.019531c-45.332031 0-82.066406-36.746094-82.066406-82.070312 0-40.214844 28.929688-73.683594 67.128906-80.703125 3.777344-.707031 7.648438-1.144531 11.597656-1.300781 1.105469-.035157 2.222657-.0625 3.339844-.0625 14.734375 0 28.570313 3.882812 40.515625 10.699218-1.660156-11.5625-13.613281-22.394531-31.238281-31.125 9.679688-32.726562 42.613281-63.078125 115.195312-57.734375zm0 0"
                                                                fill="#4d87a1"></path>
                                                            <path
                                                                d="m264.6875 245.550781c.496094.089844.789062.089844.835938.089844h-3.792969c.992187 0 1.972656-.03125 2.957031-.089844zm0 0"
                                                                fill="#84abc1"></path>
                                                            <path
                                                                d="m512 313.621094c0 30.589844-24.800781 55.390625-55.402344 55.390625h-200.476562c-40.675782 0-73.660156-32.972657-73.660156-73.660157 0-40.679687 32.984374-73.660156 73.660156-73.660156 3.648437 0 7.238281.28125 10.75.789063 16.640625-33.035157 50.847656-55.703125 90.371094-55.703125 47.824218 0 87.886718 33.203125 98.410156 77.808594 1.050781 4.453124 1.808594 9.015624 2.246094 13.664062v.011719c29.988281.683593 54.101562 25.203125 54.101562 55.359375zm0 0"
                                                                fill="#d3ddea"></path>
                                                            <path
                                                                d="m392.386719 173.0625c-10.949219-4.066406-22.792969-6.28125-35.144531-6.28125-39.523438 0-73.722657 22.667969-90.371094 55.691406v.011719c-3.515625-.511719-7.101563-.789063-10.75-.789063-40.6875 0-73.660156 32.980469-73.660156 73.660157 0 40.6875 32.972656 73.660156 73.660156 73.660156h35.601562c-36.664062 0-66.371094-29.71875-66.371094-66.371094 0-32.527343 23.394532-59.59375 54.289063-65.269531 3.058594-.574219 6.1875-.925781 9.378906-1.050781.894531-.03125 1.800781-.050781 2.703125-.050781 11.917969 0 23.105469 3.140624 32.765625 8.648437-1.339843-9.351563-11.007812-18.109375-25.261719-25.167969 7.824219-26.46875 34.460938-51.015625 93.160157-46.691406zm0 0"
                                                                fill="#84abc1"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Lluvia</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card" style="border-radius: 25px;">
                                <div class="card-body p-4">

                                    <div id="demo3" class="">
                                        <!-- Indicators -->
                                        <div class="">
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{cmm6LastData.dewpoint}}°C</strong>
                                                        </p>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                                                            id="Layer_1" x="0px" y="0px" viewBox="0 0 497 497"
                                                            style="enable-background:new 0 0 497 497;"
                                                            xml:space="preserve" width="55px" height="55px">
                                                            <g>
                                                                <path style="fill:#59C2E8;"
                                                                    d="M183.391,58.325c-6.66-6.477-17.246-6.477-23.907,0C-78.787,290.047-14.684,497,171.438,497   S421.663,290.047,183.391,58.325z">
                                                                </path>
                                                                <path style="fill:#0097D1;"
                                                                    d="M201.438,477C19.441,477-45.878,279.117,174.095,53.691c-5.15-0.806-10.593,0.727-14.611,4.634   C-78.787,290.047-14.684,497,171.438,497c48.559,0,88.805-14.094,117.55-39.032C264.453,470.302,234.92,477,201.438,477z">
                                                                </path>
                                                                <g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M99.951,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5s7.5,3.358,7.5,7.5     v12.938C107.451,283.894,104.094,287.251,99.951,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M242.925,287.251c-4.143,0-7.5-3.358-7.5-7.5v-12.938c0-4.142,3.357-7.5,7.5-7.5     s7.5,3.358,7.5,7.5v12.938C250.425,283.894,247.067,287.251,242.925,287.251z">
                                                                        </path>
                                                                    </g>
                                                                    <g>
                                                                        <path style="fill:#334151;"
                                                                            d="M171.438,294.157c-9.041,0-17.53-3.78-23.291-10.371c-2.727-3.119-2.408-7.857,0.711-10.583     c3.117-2.726,7.856-2.408,10.582,0.711c2.912,3.332,7.285,5.242,11.998,5.242c4.712,0,9.085-1.911,11.997-5.242     c2.726-3.119,7.464-3.437,10.583-0.711c3.118,2.726,3.437,7.464,0.71,10.583C188.968,290.378,180.478,294.157,171.438,294.157z">
                                                                        </path>
                                                                    </g>
                                                                </g>
                                                                <path style="fill:#D5EBF6;"
                                                                    d="M420.006,262.727V50.657C420.006,22.68,397.326,0,369.35,0h0c-25.428,0-46.475,18.737-50.1,43.157   l4.08,7.5l-4.637,7.5V79.17l3.97,7.5l-3.97,7.5v21.014l4.637,7.5l-4.637,7.5v21.014l3.637,7.5l-3.637,7.5v21.013l3.637,7.5   l-3.637,7.5v60.516c-42.433,19.279-71.942,62.03-71.942,111.674C246.751,442.111,301.64,497,369.35,497   s122.599-54.889,122.599-122.599C491.948,324.757,462.439,282.006,420.006,262.727z">
                                                                </path>
                                                                <path style="fill:#FF6E80;"
                                                                    d="M369.349,462.349c-48.494,0-87.947-39.453-87.947-87.947c0-34.427,20.264-65.879,51.625-80.127   l20.318-9.23V50.656c0-8.825,7.18-16.005,16.005-16.005c8.825,0,16.006,7.18,16.006,16.005v234.388l20.318,9.231   c31.361,14.248,51.625,45.7,51.625,80.127C457.297,422.896,417.844,462.349,369.349,462.349z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,187.211v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H318.693   L318.693,187.211z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,151.198v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,151.198z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,115.184v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,115.184z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,50.657v7.5h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5h-34.095   C318.886,45.604,318.693,48.107,318.693,50.657z">
                                                                </path>
                                                                <path style="fill:#334151;"
                                                                    d="M318.693,79.17v15h0h34.651c4.143,0,7.5-3.358,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H318.693   L318.693,79.17z">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Punto de<br> rocío</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{cmm6LastData.heatindex}}°C</strong>
                                                        </p>
                                                        <svg style="margin-right: 20px" height="70px"
                                                            viewBox="-78 0 512 512" width="75px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-13.621094-15.976562-21.824218-36.6875-21.824218-59.308593 0-50.550782 40.976562-91.527344 91.527343-91.527344 46.082031 0 84.203125 34.046875 90.585938 78.363281.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fab03c"></path>
                                                            <path
                                                                d="m242.296875 150.769531c0 50.550781-40.976563 91.527344-91.527344 91.527344-27.929687 0-52.925781-12.503906-69.703125-32.214844-.621094-4.300781-.9375-8.691406-.9375-13.160156 0-50.554687 40.984375-91.527344 91.527344-91.527344 27.925781 0 52.925781 12.503907 69.699219 32.214844.621093 4.300781.941406 8.695313.941406 13.160156zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <g fill="#f8e98e">
                                                                <path
                                                                    d="m150.769531 34.9375c-4.207031 0-7.613281-3.410156-7.613281-7.613281v-19.714844c0-4.203125 3.410156-7.609375 7.613281-7.609375s7.609375 3.40625 7.609375 7.609375v19.714844c0 4.203125-3.40625 7.613281-7.609375 7.613281zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m89.054688 51.476562c-2.632813 0-5.191407-1.367187-6.601563-3.808593l-9.855469-17.070313c-2.101562-3.640625-.855468-8.296875 2.785156-10.398437 3.640626-2.101563 8.296876-.855469 10.398438 2.785156l9.855469 17.074219c2.101562 3.636718.855469 8.292968-2.785157 10.394531-1.199218.691406-2.507812 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m43.855469 96.660156c-1.289063 0-2.597657-.332031-3.796875-1.023437l-17.074219-9.855469c-3.640625-2.101562-4.886719-6.757812-2.785156-10.398438 2.101562-3.640624 6.753906-4.886718 10.394531-2.785156l17.074219 9.855469c3.640625 2.105469 4.886719 6.757813 2.785156 10.398437-1.410156 2.445313-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m27.324219 158.378906h-19.714844c-4.203125 0-7.609375-3.40625-7.609375-7.609375s3.40625-7.613281 7.609375-7.613281h19.714844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m257.679688 96.660156c-2.628907 0-5.1875-1.367187-6.597657-3.808594-2.101562-3.640624-.855469-8.292968 2.785157-10.398437l17.074218-9.855469c3.636719-2.101562 8.292969-.855468 10.394532 2.785156 2.101562 3.640626.855468 8.296876-2.785157 10.398438l-17.074219 9.855469c-1.195312.691406-2.503906 1.023437-3.796874 1.023437zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m26.796875 229.960938c-2.628906 0-5.1875-1.367188-6.597656-3.808594-2.101563-3.640625-.855469-8.296875 2.785156-10.394532l17.074219-9.859374c3.636718-2.097657 8.292968-.851563 10.394531 2.789062s.855469 8.292969-2.785156 10.394531l-17.074219 9.855469c-1.195312.695312-2.503906 1.023438-3.796875 1.023438zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m212.484375 51.476562c-1.292969 0-2.601563-.332031-3.800781-1.023437-3.640625-2.101563-4.886719-6.757813-2.785156-10.394531l9.855468-17.074219c2.101563-3.640625 6.757813-4.886719 10.398438-2.785156 3.640625 2.101562 4.886718 6.757812 2.785156 10.398437l-9.855469 17.070313c-1.410156 2.441406-3.96875 3.808593-6.597656 3.808593zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m79.183594 282.359375c-1.292969 0-2.601563-.328125-3.800782-1.019531-3.640624-2.105469-4.886718-6.757813-2.785156-10.398438l9.855469-17.074218c2.101563-3.640626 6.757813-4.886719 10.398437-2.785157 3.640626 2.101563 4.886719 6.757813 2.785157 10.398438l-9.855469 17.070312c-1.410156 2.441407-3.96875 3.808594-6.597656 3.808594zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m329.234375 240.59375c0 10.503906-8.519531 19.019531-19.023437 19.019531-10.503907 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.519531-19.023438 19.023438-19.023438 10.503906 0 19.023437 8.515626 19.023437 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m177.15625 240.59375c0 10.503906-8.515625 19.019531-19.019531 19.019531-10.507813 0-19.023438-8.515625-19.023438-19.019531 0-10.507812 8.515625-19.023438 19.023438-19.023438 10.503906 0 19.019531 8.515626 19.019531 19.023438zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m353.683594 439.070312v62.78125c0 5.605469-4.542969 10.148438-10.148438 10.148438h-218.722656c-5.601562 0-10.148438-4.542969-10.148438-10.148438v-62.78125c0-43.292968 23.027344-81.207031 57.492188-102.175781 18.074219-10.988281 39.308594-17.332031 62.019531-17.332031 22.5 0 43.535157 6.21875 61.5 17.03125 8.390625 5.042969 16.125 11.089844 23.007813 17.972656 21.625 21.636719 35 51.511719 35 84.503906zm0 0"
                                                                fill="#f0f5f7"></path>
                                                            <path
                                                                d="m172.503906 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.609375-7.609375 4.203125 0 7.613281 3.398437 7.613281 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m311.171875 415.78125v96.21875h-15.222656v-96.21875c0-4.210938 3.410156-7.609375 7.613281-7.609375 4.199219 0 7.609375 3.398437 7.609375 7.609375zm0 0"
                                                                fill="#dde9ed"></path>
                                                            <path
                                                                d="m343.539062 511.992188h-47.589843v-67.089844h57.738281v56.945312c0 5.601563-4.542969 10.144532-10.148438 10.144532zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m295.949219 444.90625h15.222656v67.082031h-15.222656zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m172.402344 444.90625v67.082031h-47.589844c-5.601562 0-10.148438-4.542969-10.148438-10.148437v-56.933594zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m157.28125 444.90625h15.121094v67.082031h-15.121094zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m295.679688 336.59375c-13.59375 19.570312-36.234376 32.367188-61.863282 32.367188-25.496094 0-48.042968-12.671876-61.65625-32.066407 18.070313-10.992187 39.304688-17.328125 62.015625-17.328125 22.5 0 43.535157 6.210938 61.503907 17.027344zm0 0"
                                                                fill="#efa66f"></path>
                                                            <path
                                                                d="m164.230469 174.1875v85.160156c0 38.625 31.316406 69.941406 69.941406 69.941406 38.628906 0 69.941406-31.316406 69.941406-69.941406v-85.160156zm0 0"
                                                                fill="#fbdbb7"></path>
                                                            <path
                                                                d="m304.113281 174.1875v85.15625c0 19.3125-7.828125 36.808594-20.488281 49.460938-12.652344 12.652343-30.140625 20.480468-49.453125 20.480468-3.757813 0-7.445313-.292968-11.050781-.875 14.886718-2.355468 28.207031-9.421875 38.402344-19.605468 12.652343-12.652344 20.480468-30.148438 20.480468-49.460938v-85.15625zm0 0"
                                                                fill="#f4bc8e"></path>
                                                            <path
                                                                d="m234.175781 119.171875c-44.792969 0-81.101562 36.308594-81.101562 81.101563v17.472656c0 6.152344 5.417969 10.886718 11.515625 10.054687 20.5-2.789062 62.964844-10.910156 82.292968-31.78125 7.195313 11.8125 23.039063 26.890625 57.433594 29.546875 5.90625.457032 10.957032-4.199218 10.957032-10.121094v-15.171874c0-44.792969-36.308594-81.101563-81.097657-81.101563zm0 0"
                                                                fill="#82807f"></path>
                                                            <path
                                                                d="m315.273438 200.277344v15.164062c0 5.929688-5.066407 10.578125-10.980469 10.121094-4.617188-.359375-8.902344-.941406-12.878907-1.710938v-23.574218c0-40.738282-30.03125-74.460938-69.175781-80.238282 3.898438-.578124 7.875-.871093 11.9375-.871093 44.785157 0 81.097657 36.3125 81.097657 81.109375zm0 0"
                                                                fill="#6d6d6d"></path>
                                                            <path
                                                                d="m201.347656 248.203125c-4.207031 0-7.660156-3.40625-7.660156-7.609375s3.363281-7.613281 7.570312-7.613281h.089844c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.40625 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m267.089844 248.203125c-4.203125 0-7.65625-3.40625-7.65625-7.609375s3.363281-7.613281 7.566406-7.613281h.089844c4.207031 0 7.613281 3.410156 7.613281 7.613281s-3.410156 7.609375-7.613281 7.609375zm0 0"
                                                                fill="#7c3c2f"></path>
                                                            <path
                                                                d="m241.503906 292.371094h-14.65625c-4.207031 0-7.613281-3.40625-7.613281-7.609375v-44.167969c0-4.203125 3.410156-7.613281 7.613281-7.613281s7.609375 3.410156 7.609375 7.613281v36.554688h7.046875c4.203125 0 7.609375 3.410156 7.609375 7.613281s-3.410156 7.609375-7.609375 7.609375zm0 0"
                                                                fill="#fd8f3e"></path>
                                                            <path
                                                                d="m323.347656 172.90625c0 10.621094-8.609375 19.230469-19.230468 19.230469-10.621094 0-19.230469-8.609375-19.230469-19.230469 0-6.96875 8.28125-20.652344 13.972656-29.230469 2.496094-3.761719 8.015625-3.761719 10.511719 0 5.695312 8.578125 13.976562 22.261719 13.976562 29.230469zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m323.34375 172.90625c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.660156 0-5.199219-.542969-7.507812-1.519531 6.898437-2.921876 11.730469-9.753907 11.730469-17.710938 0-6.09375-6.328126-17.316406-11.722657-25.777344.773438-1.214844 1.527344-2.367187 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.519532-.011719 5.691406 8.582031 13.972656 22.261719 13.972656 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m356.394531 368.648438c0 10.621093-8.613281 19.230468-19.234375 19.230468s-19.230468-8.609375-19.230468-19.230468c0-6.972657 8.285156-20.652344 13.976562-29.234376 2.496094-3.761718 8.015625-3.761718 10.511719 0 5.691406 8.582032 13.976562 22.261719 13.976562 29.234376zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m356.390625 368.648438c0 10.617187-8.605469 19.230468-19.230469 19.230468-2.664062 0-5.199218-.542968-7.507812-1.519531 6.894531-2.921875 11.730468-9.753906 11.730468-17.710937 0-6.09375-6.328124-17.320313-11.722656-25.78125.769532-1.210938 1.523438-2.367188 2.238282-3.441407 2.496093-3.765625 8.019531-3.777343 10.515624-.011719 5.695313 8.582032 13.976563 22.261719 13.976563 29.234376zm0 0"
                                                                fill="#60daff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.621094-8.609375 19.230469-19.230469 19.230469s-19.230469-8.609375-19.230469-19.230469c0-6.96875 8.28125-20.652344 13.976563-29.234375 2.496093-3.757813 8.015625-3.757813 10.511719 0 5.691406 8.582031 13.972656 22.265625 13.972656 29.234375zm0 0"
                                                                fill="#a0ecff"></path>
                                                            <path
                                                                d="m184.121094 281.3125c0 10.617188-8.605469 19.230469-19.230469 19.230469-2.664063 0-5.199219-.542969-7.507813-1.519531 6.894532-2.921876 11.726563-9.753907 11.726563-17.710938 0-6.09375-6.328125-17.316406-11.71875-25.777344.769531-1.214844 1.523437-2.371094 2.238281-3.445312 2.496094-3.765625 8.019532-3.777344 10.515625-.011719 5.695313 8.582031 13.976563 22.261719 13.976563 29.234375zm0 0"
                                                                fill="#60daff"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Sensación<br> térmica</strong></p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{cmm6LastData.windSpeed}}Km/h</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="0 -23 511.99037 511" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m238.945312 182.152344h-213.332031c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h213.332031c20.527344.90625 39.605469-10.542969 48.460938-29.082032 9.6875-19.246094 8.308594-42.207031-3.609375-60.160156-9.566406-12.449219-24.515625-17.066406-43.175781-13.320312-14.421875 1.917968-24.910156 14.648437-24.03125 29.167968 1.066406 10.726563 9.738281 22.1875 30.890625 22.1875 2.355469 0 4.265625-1.910156 4.265625-4.269531 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535157-3.820312-8.535157-8.53125 0-4.714844 3.820313-8.535156 8.535157-8.535156 11.78125 0 21.332031 9.550781 21.332031 21.332031 0 11.785156-9.550781 21.335937-21.332031 21.335937-30.5 0-46.019531-18.875-47.871094-37.546874-1.890625-23.285157 14.539063-44.070313 37.632813-47.609376 22.140624-6.042968 45.753906 1.675782 60.054687 19.628907 15.925781 23.097656 17.992187 53.039062 5.386719 78.105469-11.75 24.332031-36.726563 39.472656-63.738282 38.628906zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m269.742188 430.933594c-5.183594-.019532-10.355469-.554688-15.433594-1.597656-23.097656-3.535157-39.527344-24.324219-37.632813-47.613282 1.851563-18.671875 17.375-37.546875 47.871094-37.546875 11.78125 0 21.332031 9.550781 21.332031 21.332031s-9.550781 21.335938-21.332031 21.335938c-4.710937 0-8.53125-3.824219-8.53125-8.535156 0-4.714844 3.820313-8.535156 8.53125-8.535156 2.355469 0 4.265625-1.910157 4.265625-4.265626 0-2.355468-1.910156-4.265624-4.265625-4.265624-21.152344 0-29.867187 11.46875-30.890625 22.1875-.878906 14.535156 9.628906 27.273437 24.0625 29.183593 15.765625 4.65625 32.785156-.597656 43.179688-13.328125 11.917968-17.953125 13.296874-40.914062 3.609374-60.160156-8.859374-18.550781-27.957031-30.003906-48.492187-29.082031h-213.335937c-4.710938 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820312-8.53125 8.53125-8.53125h213.335937c27.015625-.859374 52.003906 14.265626 63.769531 38.601563 12.605469 25.066406 10.539063 55.011719-5.386718 78.109375-10.601563 13.847656-27.21875 21.753906-44.65625 21.246094zm0 0"
                                                                fill="#bdc3c7"></path>
                                                            <path
                                                                d="m375.480469 224.820312h-213.332031c-4.714844 0-8.535157-3.820312-8.535157-8.535156 0-4.710937 3.820313-8.53125 8.535157-8.53125h213.332031c20.523437.90625 39.605469-10.542968 48.460937-29.082031 9.683594-19.246094 8.308594-42.210937-3.609375-60.160156-9.566406-12.460938-24.535156-17.066407-43.179687-13.320313-14.417969 1.914063-24.90625 14.644532-24.03125 29.167969 1.070312 10.726563 9.738281 22.183594 30.894531 22.183594 2.355469 0 4.265625-1.910157 4.265625-4.265625 0-2.355469-1.910156-4.265625-4.265625-4.265625-4.714844 0-8.535156-3.820313-8.535156-8.535157 0-4.710937 3.820312-8.53125 8.535156-8.53125 11.78125 0 21.332031 9.550782 21.332031 21.332032s-9.550781 21.332031-21.332031 21.332031c-30.5 0-46.023437-18.875-47.875-37.546875-1.890625-23.285156 14.539063-44.070312 37.632813-47.605469 22.144531-6.046875 45.757812 1.671875 60.058593 19.625 15.925781 23.097657 17.988281 53.042969 5.382813 78.105469-11.75 24.335938-36.722656 39.472656-63.734375 38.632812zm0 0"
                                                                fill="#7f8c8d"></path>
                                                            <path
                                                                d="m426.875 453.785156c-7.640625-.035156-15.261719-.824218-22.75-2.355468-33.242188-4.996094-56.972656-34.835938-54.355469-68.351563 2.457031-24.796875 22.050781-53.757813 69.1875-53.757813 15.414063.265626 27.773438 12.839844 27.773438 28.257813s-12.359375 27.988281-27.773438 28.257813c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125 6.0625-.167969 10.886719-5.128906 10.886719-11.191406s-4.824219-11.027344-10.886719-11.191406c-35.6875 0-50.347656 19.820312-52.199219 38.398437-1.625 24.75 16.152344 46.546875 40.722657 49.9375 38.664062 7.722656 60.015625-7.824218 71.097656-22.246094 19.414063-25.257812 21.898437-65.382812 6.042969-97.570312-14.375-30.1875-45.410156-48.851562-78.8125-47.394531h-397.261719c-4.710937 0-8.53125-3.820313-8.53125-8.535157 0-4.710937 3.820313-8.53125 8.53125-8.53125h397.261719c39.863281-1.292968 76.75 21.015626 94.121094 56.917969 18.628906 37.792969 15.410156 85.332031-7.816407 115.515625-15.507812 20.191406-39.785156 31.695313-65.238281 30.90625zm0 0"
                                                                fill="#fdd7ad"></path>
                                                            <path
                                                                d="m40.34375 434.203125c-3.925781-.128906.757812-21.335937 1.234375-23.628906 2.132813-12.183594 8.472656-23.234375 17.921875-31.214844 26.070312-21.597656 62.0625-20.363281 93.867188-18.484375 2.175781.128906 16.492187 1.28125 17.3125-.621094-14.652344 33.890625-31.324219 75.226563-61.933594 97.890625-8.882813 6.957031-20.480469 9.414063-31.421875 6.65625-7.925781-2.652343-14.917969-7.535156-20.136719-14.0625-4.667969-5.144531-8.765625-16.28125-16.84375-16.535156zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m8.546875 465.988281c-3.59375.003907-6.808594-2.242187-8.035156-5.621093-1.226563-3.378907-.203125-7.164063 2.558593-9.464844 2.164063-1.765625 53.382813-44.160156 97.636719-53.019532 4.585938-.847656 9.007813 2.144532 9.921875 6.71875.914063 4.574219-2.015625 9.035157-6.578125 10.015626-40.035156 8.066406-89.53125 49.023437-90.035156 49.390624-1.535156 1.28125-3.46875 1.980469-5.46875 1.980469zm0 0"
                                                                fill="#805333"></path>
                                                            <path
                                                                d="m132.648438 105.386719c.953124 3.796875-20.753907 5.121093-23.042969 5.324219-12.296875 1.316406-24.664063-1.730469-34.941407-8.609376-27.964843-19.105468-36.710937-54.03125-43.683593-85.128906-.476563-2.132812-3.308594-16.214844-5.367188-16.476562 36.625 4.734375 80.949219 9.386718 111.179688 32.535156 9.136719 6.617188 14.699219 17.085938 15.0625 28.359375-.355469 8.359375-3.113281 16.441406-7.945313 23.277344-3.675781 5.863281-13.242187 12.894531-11.261718 20.71875zm0 0"
                                                                fill="#cb8252"></path>
                                                            <path
                                                                d="m163.75 138.0625c-1.703125.003906-3.371094-.511719-4.777344-1.476562-2.328125-1.578126-57.171875-39.125-77.890625-79.242188-2.164062-4.1875-.523437-9.335938 3.664063-11.5 4.1875-2.160156 9.335937-.519531 11.496094 3.667969 18.773437 36.25 71.785156 72.601562 72.320312 72.957031 3.101562 2.109375 4.464844 5.992188 3.359375 9.574219s-4.421875 6.023437-8.171875 6.019531zm0 0"
                                                                fill="#805333"></path>
                                                            <g fill="#cb8252">
                                                                <path
                                                                    d="m366.945312 28.441406c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714843 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820313-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m486.414062 11.375c0 4.714844-3.820312 8.535156-8.535156 8.535156-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125 4.714844 0 8.535156 3.820312 8.535156 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m460.8125 207.640625c0 4.714844-3.820312 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710938 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m366.945312 310.042969c0 4.710937-3.820312 8.53125-8.53125 8.53125-4.714843 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820313-8.535157 8.535156-8.535157 4.710938 0 8.53125 3.820313 8.53125 8.535157zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m324.28125 455.109375c0 4.710937-3.820312 8.53125-8.535156 8.53125-4.710938 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820312-8.535156 8.53125-8.535156 4.714844 0 8.535156 3.820312 8.535156 8.535156zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m187.746094 412.441406c0 4.714844-3.820313 8.535156-8.53125 8.535156-4.714844 0-8.535156-3.820312-8.535156-8.535156 0-4.710937 3.820312-8.53125 8.535156-8.53125 4.710937 0 8.53125 3.820313 8.53125 8.53125zm0 0">
                                                                </path>
                                                            </g>
                                                            <path
                                                                d="m93.878906 224.707031h-85.332031c-4.710937 0-8.53125-3.820312-8.53125-8.53125 0-4.714843 3.820313-8.535156 8.53125-8.535156h85.332031c4.714844 0 8.535156 3.820313 8.535156 8.535156 0 4.710938-3.820312 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#bdc3c7"></path>
                                                        </svg>
                                                        <p class="mb-0"><strong>Velocidad del<br> viento</strong>
                                                        </p>
                                                    </div>
                                                    <div class="col-sm-3 text-center">
                                                        <p class="medium">
                                                            <strong>{{obtenerDireccionViento(cmm6LastData.windDir)}}</strong>
                                                        </p>
                                                        <svg height="55px" viewBox="-4 0 511 511.99978" width="55px"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m51.703125 51.097656h17.066406v51.203125h-17.066406zm0 0"
                                                                fill="#5d9aa4"></path>
                                                            <path
                                                                d="m43.167969 272.976562h34.136719v230.414063h-34.136719zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m145.574219 42.5625h-136.539063c-4.714844 0-8.535156-3.820312-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h136.539063c4.714843 0 8.535156 3.820312 8.535156 8.535156 0 4.710938-3.820313 8.53125-8.535156 8.53125zm0 0"
                                                                fill="#a8a8a8"></path>
                                                            <path
                                                                d="m128.507812 68.164062c-4.714843 0-8.535156-3.820312-8.53125-8.535156 0-2.261718.898438-4.429687 2.496094-6.03125l19.570313-19.566406-19.570313-19.570312c-3.273437-3.386719-3.179687-8.792969.210938-12.066407 3.308594-3.191406 8.550781-3.191406 11.855468 0l25.601563 25.601563c3.332031 3.332031 3.332031 8.734375 0 12.066406l-25.601563 25.601562c-1.597656 1.601563-3.769531 2.5-6.03125 2.5zm0 0"
                                                                fill="#b9b9b9"></path>
                                                            <path
                                                                d="m34.636719 76.699219h51.203125v213.347656h-51.203125zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m34.636719 162.039062c-4.714844 0-8.535157-3.820312-8.535157-8.535156v-42.667968c0-4.714844 3.820313-8.535157 8.535157-8.535157h128.007812c4.710938.011719 8.523438 3.839844 8.511719 8.554688-.007812 3.660156-2.347656 6.90625-5.816406 8.070312l-128.007813 42.667969c-.867187.296875-1.78125.445312-2.695312.445312zm8.53125-42.671874v22.292968l66.890625-22.292968zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m162.644531 272.976562h-128.007812c-4.714844 0-8.535157-3.820312-8.535157-8.53125v-42.671874c.003907-2.742188 1.324219-5.316407 3.550782-6.917969 2.214844-1.621094 5.082031-2.058594 7.679687-1.171875l128.007813 42.671875c4.46875 1.5 6.875 6.335937 5.375 10.804687-1.164063 3.46875-4.414063 5.808594-8.070313 5.816406zm-119.476562-17.066406h66.890625l-66.890625-22.28125zm0 0"
                                                                fill="#cdcdcd"></path>
                                                            <path
                                                                d="m85.839844 34.03125c0 14.140625-11.464844 25.601562-25.601563 25.601562-14.140625 0-25.601562-11.460937-25.601562-25.601562s11.460937-25.601562 25.601562-25.601562c14.136719 0 25.601563 11.460937 25.601563 25.601562zm0 0"
                                                                fill="#69b0bb"></path>
                                                            <path
                                                                d="m290.652344 298.578125c-14.464844.015625-27.355469 9.128906-32.191406 22.761719-1.570313 4.445312.761718 9.320312 5.207031 10.890625 4.441406 1.566406 9.316406-.761719 10.886719-5.207031 3.144531-8.886719 12.898437-13.542969 21.785156-10.398438 8.882812 3.144531 13.539062 12.894531 10.398437 21.78125-2.414062 6.816406-8.859375 11.375-16.085937 11.375h-42.671875c-4.710938 0-8.53125 3.824219-8.53125 8.535156 0 4.714844 3.820312 8.535156 8.53125 8.535156h42.671875c18.851562 0 34.132812-15.285156 34.132812-34.136718 0-18.851563-15.28125-34.136719-34.132812-34.136719zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m444.257812 400.984375h-93.871093c-4.710938 0-8.535157-3.820313-8.535157-8.53125 0-4.714844 3.824219-8.535156 8.535157-8.535156h93.871093c4.714844 0 8.535157 3.820312 8.535157 8.535156 0 4.710937-3.820313 8.53125-8.535157 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m316.253906 400.984375h-25.601562c-4.714844 0-8.535156-3.820313-8.535156-8.53125 0-4.714844 3.820312-8.535156 8.535156-8.535156h25.601562c4.710938 0 8.53125 3.820312 8.53125 8.535156 0 4.710937-3.820312 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m256.515625 400.984375h-85.339844c-4.710937 0-8.53125-3.820313-8.53125-8.53125 0-4.714844 3.820313-8.535156 8.53125-8.535156h85.339844c4.710937 0 8.53125 3.820312 8.53125 8.535156.003906 4.710937-3.820313 8.53125-8.53125 8.53125zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m205.3125 435.121094h-34.136719c-4.710937 0-8.53125-3.820313-8.53125-8.535156 0-4.710938 3.820313-8.53125 8.53125-8.53125h34.136719c4.710938 0 8.53125 3.820312 8.53125 8.53125.003906 4.714843-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m213.847656 366.851562h-42.671875c-4.710937 0-8.53125-3.820312-8.53125-8.535156 0-4.710937 3.820313-8.535156 8.53125-8.535156h42.671875c4.710938 0 8.53125 3.824219 8.53125 8.535156 0 4.714844-3.820312 8.535156-8.53125 8.535156zm0 0"
                                                                fill="#a8d6da"></path>
                                                            <path
                                                                d="m273.582031 435.121094h-34.132812c-4.714844 0-8.535157-3.820313-8.535157-8.535156 0-4.710938 3.820313-8.53125 8.535157-8.53125h34.132812c4.714844 0 8.535157 3.820312 8.535157 8.53125 0 4.714843-3.820313 8.535156-8.535157 8.535156zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m407.042969 418.113281c-.101563 0-.195313-.058593-.296875-.058593h-90.492188c-4.714844 0-8.535156 3.820312-8.535156 8.53125 0 4.714843 3.820312 8.535156 8.535156 8.535156h88.222656.167969 1.210938c16.496093.003906 29.867187 13.378906 29.863281 29.875s-13.378906 29.867187-29.875 29.863281c-15.207031-.003906-27.980469-11.429687-29.675781-26.542969-.519531-4.683594-4.738281-8.0625-9.421875-7.542968-4.683594.519531-8.0625 4.738281-7.542969 9.421874 2.835937 25.765626 26.023437 44.355469 51.789063 41.519532 25.765624-2.835938 44.355468-26.023438 41.519531-51.789063-2.570313-23.34375-21.992188-41.195312-45.46875-41.789062zm0 0"
                                                                fill="#b3e4e8"></path>
                                                            <path
                                                                d="m503.996094 230.308594-256.015625 39.828125-85.335938 2.839843v-170.675781l85.335938 2.84375 256.015625 39.824219zm0 0"
                                                                fill="#ea6b65"></path>
                                                            <g fill="#d9d9d9">
                                                                <path
                                                                    d="m418.65625 243.585938 85.339844-13.277344v-85.339844l-85.339844-13.277344zm0 0">
                                                                </path>
                                                                <path
                                                                    d="m333.320312 256.859375v-138.4375l-85.339843-13.277344v164.992188zm0 0">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                        <p class="mb-0"><strong>Dirección del<br> viento</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="row" id="cmmData"> <!--#C1CFEA;  0D0C1D  noche -->
        <div *ngIf="modal" class="container">
            <div class="p-3 bgc">

                <div class="card gradient-custom" style="border-radius: 25px;">
                    <div class="card-body p-4">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h2 class="display-2"><strong>
                                        {{cmmModal? cmmName : cmm1Modal? cmm1Name : cmm2Modal? cmm2Name : cmm3Modal?
                                        cmm3Name :
                                        cmm4Modal? cmm4Name : cmm5Modal? cmm5Name : cmm6Name}}</strong></h2>
                                <p class="text-muted mb-0"><strong>HISTORICO</strong></p>
                                <div class="d-flex justify-content-center align-items-center">
                                    <form [formGroup]="myForm">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Selecciona el rango</mat-label>
                                                <mat-date-range-input [rangePicker]="picker">
                                                    <input matStartDate placeholder="Desde" formControlName="start">
                                                    <input matEndDate placeholder="Hasta" formControlName="end">
                                                </mat-date-range-input>
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-date-range-picker #picker></mat-date-range-picker>
                                            </mat-form-field>

                                            <button class="ml-2 p-2" style="margin-bottom:1.2em;" mat-stroked-button
                                                (click)="buscarValores()">
                                                <mat-icon>search</mat-icon>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="noMostrar">
                                <div>
                                    <div class="mat-elevation-z2" style="border-radius: 20px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px"
                                            viewBox="0 0 512 512" enable-background="new 0 0 512 512"
                                            xml:space="preserve">
                                            <g id="Ebene_3" display="none">
                                                <rect x="-2020" y="-2565" display="inline" fill="#4D4D4E" width="5364"
                                                    height="3336" />
                                            </g>
                                            <g id="Grid" display="none">
                                                <g display="inline">
                                                    <g>
                                                        <path fill="#B3B3B3"
                                                            d="M511,1v510H1V1H511 M512,0H0v512h512V0L512,0z" />
                                                    </g>
                                                    <line fill="none" stroke="#B3B3B3" stroke-miterlimit="10" x1="6"
                                                        y1="6" x2="506" y2="506" />
                                                    <line fill="none" stroke="#B3B3B3" stroke-miterlimit="10" x1="506"
                                                        y1="6" x2="6" y2="506" />
                                                    <circle fill="none" stroke="#B3B3B3" stroke-miterlimit="10" cx="256"
                                                        cy="256" r="250" />
                                                </g>
                                            </g>
                                            <g id="Ebene_2">
                                                <circle fill="#84abc1" cx="353.935" cy="183.958" r="21.065" />
                                                <circle fill="#84abc1" cx="428.935" cy="183.958" r="21.065" />

                                                <line fill="none" stroke="#442402" stroke-width="17"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-miterlimit="10" x1="255.997" y1="87.37" x2="255.997"
                                                    y2="471.37" />

                                                <line fill="none" stroke="#442402" stroke-width="17"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-miterlimit="10" x1="256.003" y1="331.37" x2="316.874"
                                                    y2="236.37" />

                                                <line fill="none" stroke="#442402" stroke-width="17"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-miterlimit="10" x1="316.874" y1="236.37" x2="390.997"
                                                    y2="236.37" />

                                                <line fill="none" stroke="#442402" stroke-width="17"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-miterlimit="10" x1="390.997" y1="236.37" x2="390.997"
                                                    y2="198.037" />

                                                <line fill="none" stroke="#442402" stroke-width="17"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-miterlimit="10" x1="255.997" y1="361.371" x2="165.871"
                                                    y2="400.372" />

                                                <line fill="none" stroke="#442402" stroke-width="17"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-miterlimit="10" x1="165.871" y1="400.372" x2="165.871"
                                                    y2="354.371" />

                                                <line fill="none" stroke="#e69138" stroke-width="17"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-miterlimit="10" x1="124.871" y1="324.368" x2="206.871"
                                                    y2="324.368" />

                                                <line fill="none" stroke="#e69138" stroke-width="17"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-miterlimit="10" x1="206.871" y1="324.368" x2="206.871"
                                                    y2="298.368" />

                                                <line fill="none" stroke="#e69138" stroke-width="17"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-miterlimit="10" x1="124.871" y1="324.368" x2="124.871"
                                                    y2="298.368" />

                                                <polygon fill="none" stroke="#7fed6f" stroke-width="17"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-miterlimit="10"
                                                    points="   184.997,165.621 154.997,182.364 126.692,165.621 126.692,86.624 184.997,86.624  " />

                                                <line fill="none" stroke="#442402" stroke-width="17"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-miterlimit="10" x1="202.302" y1="118.364" x2="255.997"
                                                    y2="172.059" />
                                            </g>
                                            <g id="txt">
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <br>
                            </div>
                        </div>
                        <mat-divider></mat-divider>

                        <div *ngFor="let data of cmmDataArray" class="mt-3 example-card mat-elevation-z3">
                            <div class="d-flex justify-content-between align-items-center"><span
                                    class="text-muted mb-2 big"><strong>{{formatDateToShow(data.formattedDateTime)}}</strong></span>
                                <button mat-button (click)="OpenModal(data.formattedDateTime)">
                                    <i class="fa-solid fa-list fa-lg text-muted historial-i"
                                        style="color: #ddd;"></i></button>
                            </div>
                            <mat-divider class="mb-2"></mat-divider>
                            <div class="row" style="width: 100%;">
                                <div class="col-sm-2 text-center">
                                    <i class="fa-solid fa-temperature-arrow-up fa-2x mb-3" style="color: #ddd;"></i>
                                    <span class="medium ml-1 text-muted"><strong>{{data.outTempMax}}°C</strong></span>
                                </div>
                                <div class="col-sm-2 text-center">
                                    <i class="fa-solid fa-temperature-arrow-down fa-2x mb-3" style="color: #ddd;"></i>
                                    <span class="medium ml-1 text-muted"><strong>{{data.outTempMin}}°C</strong></span>
                                </div>
                                <div class="col-sm-2 text-center">
                                    <i class="fa-solid fa-droplet fa-2x mb-3" style="color: #ddd;"></i>
                                    <span
                                        class="medium ml-1 text-muted"><strong>{{data.avg_outHumidity}}%</strong></span>
                                </div>
                                <div class="col-sm-2 text-center">
                                    <i class="fa-solid fa-gauge-simple-high fa-2x mb-3" style="color: #ddd;"></i>
                                    <span
                                        class="medium ml-1 text-muted"><strong>{{data.avg_pressure}}hPa</strong></span>
                                </div>
                                <div class="col-sm-2 text-center">
                                    <i class="fas fa-cloud-showers-heavy fa-2x mb-3" style="color: #ddd;"></i>
                                    <span class="medium ml-1 text-muted"><strong>{{data.avg_rain}}mm</strong></span>
                                </div>
                                <div class="col-sm-2 text-center">
                                    <i class="fa-solid fa-smog fa-2x mb-3" style="color: #ddd;"></i>
                                    <span class="medium ml-1 text-muted"><strong>{{data.avg_dewpoint}}°C</strong></span>
                                </div>
                                <div class="col-sm-2 text-center">
                                    <i class="fa-solid fa-person-dress-burst fa-2x mb-3" style="color: #ddd;"></i>
                                    <span
                                        class="medium ml-1 text-muted"><strong>{{data.avg_heatindex}}°C</strong></span>
                                </div>
                                <div class="col-sm-2 text-center">
                                    <i class="fa-solid fa-wind fa-2x mb-3" style="color: #ddd;"></i>
                                    <span
                                        class="medium ml-1 text-muted"><strong>{{data.avg_windSpeed}}Km/h</strong></span>
                                </div>
                                <div class="col-sm-2 text-center">
                                    <i class="fa-solid fa-compass fa-2x mb-3" style="color: #ddd;"></i>
                                    <span
                                        class="medium ml-1 text-muted"><strong>{{obtenerDireccionViento(data.avg_windDir)}}</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </section>
</div>
<!--https://mdbootstrap.com/docs/standard/extended/weather/-->